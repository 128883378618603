import * as actionType from './actionTypes';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrTable: null,
  vrDetail: null,
  vrRegister: null,
  vrCancel: null
}

const vrTableRequest = state => ({
  ...state,
  isLoading: true
})
const vrTableSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrTable: payload
})
const vrTableError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrTableReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrTable: null
})

const vrDetailRequest = state => ({
  ...state,
  isLoading: true
})
const vrDetailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrDetail: payload
})
const vrDetailError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrDetailReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrDetail: null
})

const vrRegisterRequest = state => ({
  ...state,
  isLoading: true
})
const vrRegisterSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrRegister: payload
})
const vrRegisterError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrRegisterReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrRegister: null
})

const vrCancelRequest = state => ({
  ...state,
  isLoading: true
})
const vrCancelSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrCancel: payload
})
const vrCancelError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrCancelReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  vrCancel: null
})

export const volunteerRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.VOLUNTEER_REQUEST.TABLE_REQUEST:
      return vrTableRequest(state, action.payload);
    case actionType.VOLUNTEER_REQUEST.TABLE_SUCCESS:
      return vrTableSuccess(state, action.payload);
    case actionType.VOLUNTEER_REQUEST.TABLE_ERROR:
      return vrTableError(state, action.payload);
    case actionType.VOLUNTEER_REQUEST.TABLE_RESET:
      return vrTableReset(state, action.payload);

    case actionType.VOLUNTEER_REQUEST.DETAIL_REQUEST:
      return vrDetailRequest(state, action.payload);
    case actionType.VOLUNTEER_REQUEST.DETAIL_SUCCESS:
      return vrDetailSuccess(state, action.payload);
    case actionType.VOLUNTEER_REQUEST.DETAIL_ERROR:
      return vrDetailError(state, action.payload);
    case actionType.VOLUNTEER_REQUEST.DETAIL_RESET:
      return vrDetailReset(state, action.payload);

    case actionType.VOLUNTEER_REQUEST.REGISTER_REQUEST:
      return vrRegisterRequest(state, action.payload);
    case actionType.VOLUNTEER_REQUEST.REGISTER_SUCCESS:
      return vrRegisterSuccess(state, action.payload);
    case actionType.VOLUNTEER_REQUEST.REGISTER_ERROR:
      return vrRegisterError(state, action.payload);
    case actionType.VOLUNTEER_REQUEST.REGISTER_RESET:
      return vrRegisterReset(state, action.payload);

    case actionType.VOLUNTEER_REQUEST.CANCEL_REQUEST:
      return vrCancelRequest(state, action.payload);
    case actionType.VOLUNTEER_REQUEST.CANCEL_SUCCESS:
      return vrCancelSuccess(state, action.payload);
    case actionType.VOLUNTEER_REQUEST.CANCEL_ERROR:
      return vrCancelError(state, action.payload);
    case actionType.VOLUNTEER_REQUEST.CANCEL_RESET:
      return vrCancelReset(state, action.payload);
  
    default:
      return state;
  }
}