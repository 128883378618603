import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as timesheetAction from './timesheetAction';
import * as timesheetRepo from '../../../datasources/timesheetRepo';

function* attendanceDetailVr(data) {
  try {
    const res = yield call(timesheetRepo.apiAttendanceVrDetail, data.payload);
    yield put(timesheetAction.detailVrSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(timesheetAction.detailVrError(err.response.data));
    } else {
      yield put(timesheetAction.detailVrError(err));
    }
  }
}

function* attendanceValidateVr(data) {
  try {
    const res = yield call(timesheetRepo.apiAttendanceVrValidate, data.payload);
    yield put(timesheetAction.validateVrSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(timesheetAction.validateVrError(err.response.data));
    } else {
      yield put(timesheetAction.validateVrError(err));
    }
  }
}

function* attendanceCheckinVr(data) {
  try {
    const res = yield call(timesheetRepo.apiAttendanceVrCheckin, data.payload);
    yield put(timesheetAction.checkinVrSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(timesheetAction.checkinVrError(err.response.data));
    } else {
      yield put(timesheetAction.checkinVrError(err));
    }
  }
}

function* attendanceCheckoutVr(data) {
  try {
    const res = yield call(timesheetRepo.apiAttendanceVrCheckout, data.payload);
    yield put(timesheetAction.checkoutVrSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(timesheetAction.checkoutVrError(err.response.data));
    } else {
      yield put(timesheetAction.checkoutVrError(err));
    }
  }
}

function* attendanceDetailTraining(data) {
  try {
    const res = yield call(timesheetRepo.apiTrainingDetail, data.payload);
    yield put(timesheetAction.detailTrainingSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(timesheetAction.detailTrainingError(err.response.data));
    } else {
      yield put(timesheetAction.detailTrainingError(err));
    }
  }
}

function* attendanceValidateTraining(data) {
  try {
    const res = yield call(timesheetRepo.apiValidateTraining, data.payload);
    yield put(timesheetAction.validateTrainingSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(timesheetAction.validateTrainingError(err.response.data));
    } else {
      yield put(timesheetAction.validateTrainingError(err));
    }
  }
}

function* attendanceCheckinTraining(data) {
  try {
    const res = yield call(timesheetRepo.apiTrainingCheckin, data.payload);
    yield put(timesheetAction.checkinTrainingSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(timesheetAction.checkinTrainingError(err.response.data));
    } else {
      yield put(timesheetAction.checkinTrainingError(err));
    }
  }
}

function* attendanceCheckoutTraining(data) {
  try {
    const res = yield call(timesheetRepo.apiTrainingCheckout, data.payload);
    yield put(timesheetAction.checkoutTrainingSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(timesheetAction.checkoutTrainingError(err.response.data));
    } else {
      yield put(timesheetAction.checkoutTrainingError(err));
    }
  }
}

export default function* watchTimesheet() {
  yield all([
    takeLatest(actionType.ATTENDANCE.DETAIL_VR_REQUEST, attendanceDetailVr),
    takeLatest(actionType.ATTENDANCE.VALIDATE_VR_REQUEST, attendanceValidateVr),
    takeLatest(actionType.ATTENDANCE.CHECKIN_VR_REQUEST, attendanceCheckinVr),
    takeLatest(actionType.ATTENDANCE.CHECKOUT_VR_REQUEST, attendanceCheckoutVr),
    takeLatest(actionType.ATTENDANCE.DETAIL_TRAINING_REQUEST, attendanceDetailTraining),
    takeLatest(actionType.ATTENDANCE.VALIDATE_TRAINING_REQUEST, attendanceValidateTraining),
    takeLatest(actionType.ATTENDANCE.CHECKIN_TRAINING_REQUEST, attendanceCheckinTraining),
    takeLatest(actionType.ATTENDANCE.CHECKOUT_TRAINING_REQUEST, attendanceCheckoutTraining),
  ])
}