import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  detailVr: null,
  validateVr: null,
  checkinVr: null,
  checkoutVr: null,
  detailTraining: null,
  validateTraining: null,
  checkinTraining: null,
  checkoutTraining: null,
}

const detailVrRequest = state => ({
  ...state,
  isLoading: true
})
const detailVrSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  detailVr: payload
})
const detailVrError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const detailVrReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  detailVr: null
})

const validateVrRequest = state => ({
  ...state,
  isLoading: true
})
const validateVrSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  validateVr: payload
})
const validateVrError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const validateVrReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  validateVr: null
})

const checkinVrRequest = state => ({
  ...state,
  isLoading: true
})
const checkinVrSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  checkinVr: payload
})
const checkinVrError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const checkinVrReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  checkinVr: null
})

const checkoutVrRequest = state => ({
  ...state,
  isLoading: true
})
const checkoutVrSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  checkoutVr: payload
})
const checkoutVrError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const checkoutVrReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  checkoutVr: null
})

const detailTrainingRequest = state => ({
  ...state,
  isLoading: true
})
const detailTrainingSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  detailTraining: payload
})
const detailTrainingError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const detailTrainingReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  detailTraining: null
})

const validateTrainingRequest = state => ({
  ...state,
  isLoading: true
})
const validateTrainingSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  validateTraining: payload
})
const validateTrainingError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const validateTrainingReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  validateTraining: null
})

const checkinTrainingRequest = state => ({
  ...state,
  isLoading: true
})
const checkinTrainingSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  checkinTraining: payload
})
const checkinTrainingError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const checkinTrainingReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  checkinTraining: null
})

const checkoutTrainingRequest = state => ({
  ...state,
  isLoading: true
})
const checkoutTrainingSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  checkoutTraining: payload
})
const checkoutTrainingError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const checkoutTrainingReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  checkoutTraining: null
})

export const timesheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ATTENDANCE.DETAIL_VR_REQUEST:
      return detailVrRequest(state, action.payload);
    case actionType.ATTENDANCE.DETAIL_VR_SUCCESS:
      return detailVrSuccess(state, action.payload);
    case actionType.ATTENDANCE.DETAIL_VR_ERROR:
      return detailVrError(state, action.payload);
    case actionType.ATTENDANCE.DETAIL_VR_RESET:
      return detailVrReset(state, action.payload);

    case actionType.ATTENDANCE.VALIDATE_VR_REQUEST:
      return validateVrRequest(state, action.payload);
    case actionType.ATTENDANCE.VALIDATE_VR_SUCCESS:
      return validateVrSuccess(state, action.payload);
    case actionType.ATTENDANCE.VALIDATE_VR_ERROR:
      return validateVrError(state, action.payload);
    case actionType.ATTENDANCE.VALIDATE_VR_RESET:
      return validateVrReset(state, action.payload);

    case actionType.ATTENDANCE.CHECKIN_VR_REQUEST:
      return checkinVrRequest(state, action.payload);
    case actionType.ATTENDANCE.CHECKIN_VR_SUCCESS:
      return checkinVrSuccess(state, action.payload);
    case actionType.ATTENDANCE.CHECKIN_VR_ERROR:
      return checkinVrError(state, action.payload);
    case actionType.ATTENDANCE.CHECKIN_VR_RESET:
      return checkinVrReset(state, action.payload);

    case actionType.ATTENDANCE.CHECKOUT_VR_REQUEST:
      return checkoutVrRequest(state, action.payload);
    case actionType.ATTENDANCE.CHECKOUT_VR_SUCCESS:
      return checkoutVrSuccess(state, action.payload);
    case actionType.ATTENDANCE.CHECKOUT_VR_ERROR:
      return checkoutVrError(state, action.payload);
    case actionType.ATTENDANCE.CHECKOUT_VR_RESET:
      return checkoutVrReset(state, action.payload);
  
    case actionType.ATTENDANCE.DETAIL_TRAINING_REQUEST:
      return detailTrainingRequest(state, action.payload);
    case actionType.ATTENDANCE.DETAIL_TRAINING_SUCCESS:
      return detailTrainingSuccess(state, action.payload);
    case actionType.ATTENDANCE.DETAIL_TRAINING_ERROR:
      return detailTrainingError(state, action.payload);
    case actionType.ATTENDANCE.DETAIL_TRAINING_RESET:
      return detailTrainingReset(state, action.payload);

    case actionType.ATTENDANCE.VALIDATE_TRAINING_REQUEST:
      return validateTrainingRequest(state, action.payload);
    case actionType.ATTENDANCE.VALIDATE_TRAINING_SUCCESS:
      return validateTrainingSuccess(state, action.payload);
    case actionType.ATTENDANCE.VALIDATE_TRAINING_ERROR:
      return validateTrainingError(state, action.payload);
    case actionType.ATTENDANCE.VALIDATE_TRAINING_RESET:
      return validateTrainingReset(state, action.payload);

    case actionType.ATTENDANCE.CHECKIN_TRAINING_REQUEST:
      return checkinTrainingRequest(state, action.payload);
    case actionType.ATTENDANCE.CHECKIN_TRAINING_SUCCESS:
      return checkinTrainingSuccess(state, action.payload);
    case actionType.ATTENDANCE.CHECKIN_TRAINING_ERROR:
      return checkinTrainingError(state, action.payload);
    case actionType.ATTENDANCE.CHECKIN_TRAINING_RESET:
      return checkinTrainingReset(state, action.payload);

    case actionType.ATTENDANCE.CHECKOUT_TRAINING_REQUEST:
      return checkoutTrainingRequest(state, action.payload);
    case actionType.ATTENDANCE.CHECKOUT_TRAINING_SUCCESS:
      return checkoutTrainingSuccess(state, action.payload);
    case actionType.ATTENDANCE.CHECKOUT_TRAINING_ERROR:
      return checkoutTrainingError(state, action.payload);
    case actionType.ATTENDANCE.CHECKOUT_TRAINING_RESET:
      return checkoutTrainingReset(state, action.payload);

    default:
      return state;
  }
}