export const SIGNUP = {
  VERIFICATION_REQUEST: 'SIGNUP_VERIFICATION_REQUEST',
  VERIFICATION_SUCCESS: 'SIGNUP_VERIFICATION_SUCCESS',
  VERIFICATION_ERROR: 'SIGNUP_VERIFICATION_ERROR',
  VERIFICATION_RESET: 'SIGNIP_VERIFICATION_RESET',

  VERIFICATION_ADHOC_REQUEST: 'SIGNUP_VERIFICATION_ADHOC_REQUEST',
  VERIFICATION_ADHOC_SUCCESS: 'SIGNUP_VERIFICATION_ADHOC_SUCCESS',
  VERIFICATION_ADHOC_ERROR: 'SIGNUP_VERIFICATION_ADHOC_ERROR',
  VERIFICATION_ADHOC_RESET: 'SIGNIP_VERIFICATION_ADHOC_RESET',
  
  CONTACT_RETRIEVE_REQUEST: 'SIGNUP_CONTACT_RETRIEVE_REQUEST',
  CONTACT_RETRIEVE_SUCCESS: 'SIGNUP_CONTACT_RETRIEVE_SUCCESS',
  CONTACT_RETRIEVE_ERROR: 'SIGNUP_CONTACT_RETRIEVE_ERROR',
  CONTACT_RETRIEVE_RESET: 'SIGNIP_CONTACT_RETRIEVE_RESET',

  FORM_REQUEST: 'SIGNUP_FORM_REQUEST',
  FORM_SUCCESS: 'SIGNUP_FORM_SUCCESS',
  FORM_ERROR: 'SIGNUP_FORM_ERROR',
  FORM_RESET: 'SIGNIP_FORM_RESET',

  VERIFICATION_CODE_REQUEST: 'SIGNUP_VERIFICATION_CODE_REQUEST',
  VERIFICATION_CODE_SUCCESS: 'SIGNUP_VERIFICATION_CODE_SUCCESS',
  VERIFICATION_CODE_ERROR: 'SIGNUP_VERIFICATION_CODE_ERROR',
  VERIFICATION_CODE_RESET: 'SIGNIP_VERIFICATION_CODE_RESET',

  VERIFICATION_CODE_FORM_REQUEST: 'SIGNUP_VERIFICATION_CODE_FORM_REQUEST',
  VERIFICATION_CODE_FORM_SUCCESS: 'SIGNUP_VERIFICATION_CODE_FORM_SUCCESS',
  VERIFICATION_CODE_FORM_ERROR: 'SIGNUP_VERIFICATION_CODE_FORM_ERROR',
  VERIFICATION_CODE_FORM_RESET: 'SIGNIP_VERIFICATION_CODE_FORM_RESET',
}