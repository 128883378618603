import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as contactAction from './contactAction';
import * as contactRepo from '../../../datasources/contactRepo';

function* getSalutation(){
  try {
    const res = yield call(contactRepo.apiSalutation);
    yield put(contactAction.salutationSuccess(res));
  } catch(err){
    yield put(contactAction.salutationError(err));
  }
}

function* getIDType(){
  try {
    const res = yield call(contactRepo.apiIDType);
    yield put(contactAction.idtypeSuccess(res));
  } catch(err){
    yield put(contactAction.idtypeError(err));
  }
}

function* getRaces(){
  try {
    const res = yield call(contactRepo.apiRaces);
    yield put(contactAction.racesSuccess(res));
  } catch(err){
    yield put(contactAction.racesError(err));
  }
}

function* getNationalities(){
  try {
    const res = yield call(contactRepo.apiNationalities);
    yield put(contactAction.nationalitiesSuccess(res));
  } catch(err){
    yield put(contactAction.nationalitiesError(err));
  }
}

function* getPostalcode(data){
  try {
    const res = yield call(contactRepo.apiPostalcode, data.payload);
    yield put(contactAction.postalcodeSuccess(res));
  } catch(err){
    yield put(contactAction.postalcodeError(err));
  }
}

function* getGender(){
  try {
    const res = yield call(contactRepo.apiGender);
    yield put(contactAction.genderSuccess(res));
  } catch(err){
    yield put(contactAction.genderError(err));
  }
}

function* getReligion(){
  try {
    const res = yield call(contactRepo.apiReligion);
    yield put(contactAction.religionSuccess(res));
  } catch(err){
    yield put(contactAction.religionError(err));
  }
}

function* getOccupation(){
  try {
    const res = yield call(contactRepo.apiOccupation);
    yield put(contactAction.occupationSuccess(res));
  } catch(err){
    yield put(contactAction.occupationError(err));
  }
}

function* getIndustry(){
  try {
    const res = yield call(contactRepo.apiIndustry);
    yield put(contactAction.industrySuccess(res));
  } catch(err){
    yield put(contactAction.industryError(err));
  }
}

function* getEducation(){
  try {
    const res = yield call(contactRepo.apiEducation);
    yield put(contactAction.educationSuccess(res));
  } catch(err){
    yield put(contactAction.educationError(err));
  }
}

function* getSpokenLanguage(){
  try {
    const res = yield call(contactRepo.apiSpokenLanguage);
    yield put(contactAction.spokenLanguageSuccess(res));
  } catch(err){
    yield put(contactAction.spokenLanguageError(err));
  }
}

function* getFrequencyPreferences(){
  try {
    const res = yield call(contactRepo.apiFrequencyPreferences);
    yield put(contactAction.frequencyPreferencesSuccess(res));
  } catch(err){
    yield put(contactAction.frequencyPreferencesError(err));
  }
}

function* getRelationship(){
  try {
    const res = yield call(contactRepo.apiRelationship);
    yield put(contactAction.relationshipSuccess(res));
  } catch(err){
    yield put(contactAction.relationshipError(err));
  }
}

function* getInvolvementLetter(){
  try {
    const res = yield call(contactRepo.apiInvolvementLetter);
    yield put(contactAction.involvementLetterSuccess(res));
  } catch(err){
    yield put(contactAction.involvementLetterError(err));
  }
}

export default function* watchContact() {
  yield all([
    takeLatest(actionType.CONTACT.SALUTATION_REQUEST, getSalutation),
    takeLatest(actionType.CONTACT.IDTYPE_REQUEST, getIDType),
    takeLatest(actionType.CONTACT.RACES_REQUEST, getRaces),
    takeLatest(actionType.CONTACT.NATIONALITIES_REQUEST, getNationalities),
    takeLatest(actionType.CONTACT.POSTALCODE_REQUEST, getPostalcode),
    takeLatest(actionType.CONTACT.GENDER_REQUEST, getGender),
    takeLatest(actionType.CONTACT.RELIGION_REQUEST, getReligion),
    takeLatest(actionType.CONTACT.OCCUPATION_REQUEST, getOccupation),
    takeLatest(actionType.CONTACT.INDUSTRY_REQUEST, getIndustry),
    takeLatest(actionType.CONTACT.EDUCATION_REQUEST, getEducation),
    takeLatest(actionType.CONTACT.SPOKEN_LANGUAGE_REQUEST, getSpokenLanguage),
    takeLatest(actionType.CONTACT.FREQUENCY_PREFERENCES_REQUEST, getFrequencyPreferences),
    takeLatest(actionType.CONTACT.RELATIONSHIP_REQUEST, getRelationship),
    takeLatest(actionType.CONTACT.INVOLVEMENT_LETTER_REQUEST, getInvolvementLetter),
  ])
}