import { methodService, apiService } from './apiService';

export const URL = {
  BASE_CONTACT: '/contacts',
}

export function apiSalutation() {
  return apiService(
    URL.BASE_CONTACT+'/salutations',
    methodService.GET,
    null,
    null
  );
}

export function apiIDType() {
  return apiService(
    URL.BASE_CONTACT+'/idTypes',
    methodService.GET,
    null,
    null
  );
}

export function apiRaces() {
  return apiService(
    URL.BASE_CONTACT+'/races',
    methodService.GET,
    null,
    null
  );
}

export function apiNationalities() {
  return apiService(
    URL.BASE_CONTACT+'/nationalities',
    methodService.GET,
    null,
    null
  );
}

export function apiPostalcode(data) {
  return apiService(
    URL.BASE_CONTACT+`/postalcodes/${data.param}`,
    methodService.GET,
    null,
    null
  );
}

export function apiGender() {
  return apiService(
    URL.BASE_CONTACT+'/genders',
    methodService.GET,
    null,
    null
  );
}

export function apiReligion() {
  return apiService(
    URL.BASE_CONTACT+'/religions',
    methodService.GET,
    null,
    null
  );
}

export function apiOccupation() {
  return apiService(
    URL.BASE_CONTACT+'/occupations',
    methodService.GET,
    null,
    null
  );
}

export function apiIndustry() {
  return apiService(
    URL.BASE_CONTACT+'/industries',
    methodService.GET,
    null,
    null
  );
}

export function apiEducation() {
  return apiService(
    URL.BASE_CONTACT+'/educations',
    methodService.GET,
    null,
    null
  );
}

export function apiSpokenLanguage() {
  return apiService(
    URL.BASE_CONTACT+'/spokenLanguages',
    methodService.GET,
    null,
    null
  );
}

export function apiFrequencyPreferences() {
  return apiService(
    URL.BASE_CONTACT+'/frequencyPreferences',
    methodService.GET,
    null,
    null
  );
}

export function apiRelationship() {
  return apiService(
    URL.BASE_CONTACT+'/relationships',
    methodService.GET,
    null,
    null
  );
}

export function apiInvolvementLetter() {
  return apiService(
    URL.BASE_CONTACT+'/involvement-letter',
    methodService.GET,
    null,
    null
  );
}