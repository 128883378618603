import React, { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import { useFormContext, Controller } from "react-hook-form";
import * as contactAction from "../../Contact/redux/contactAction";

const CONTAINER = styled.div`
.error {
  border: 1px solid #FF6565;
}`;

export function SignupContactDetailForm() {
  const dispatch = useDispatch();
  const { register, errors, watch, setValue } = useFormContext();
  const postalCodeValue = watch('postalCode');
  const contactReducer = useSelector(state => state.contact);
  const [optionPostalcode, setOptionPostalcode] = useState([]);
  const [inputPostalcode, setInputPostalcode] = useState('');

  useEffect(() => {
    if(contactReducer.postalcode !== null){
      let tmpPostalcode = [...contactReducer.postalcode.data.postalcodes].map((item) => {
        let _newData = {
          value: item.id,
          label: item.name
        }
        return _newData;
      })
      setOptionPostalcode(tmpPostalcode);
    }
  }, [contactReducer.postalcode, contactReducer.isLoading])

  useEffect(() => {
    if(postalCodeValue !== null && postalCodeValue !== ''){
      setInputPostalcode(postalCodeValue);
    }
  }, [postalCodeValue])

  function setAddress(data){
    let tmpAddress;
    if(data.block){
      tmpAddress = data.block;
    }
    if(data.street){
      tmpAddress = tmpAddress + ' ' + data.street;
    }
    if(data.buildingName){
      tmpAddress = tmpAddress + ' ' + data.buildingName;
    }
    setValue('address', tmpAddress);
  }

  useEffect(() => {
    dispatch(contactAction.postalcodeRequest({param: inputPostalcode}));
  }, [inputPostalcode])

  return (
    <>
      <CONTAINER>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Email <span className="text-danger">*</span></Form.Label>
            <Form.Control 
              type="email" 
              placeholder="Email" 
              name="email"
              id="email"
              ref={register}
              className={(errors.email) && "error"}
            />
            {
              errors.email &&
              <Form.Text className={'text-danger'}>
                {
                  errors.email?.message
                }
              </Form.Text>
            }
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Home Phone</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Home Phone" 
              name="homePhone"
              id="homePhone"
              ref={register}
            />
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Mobile Phone <span className="text-danger">*</span></Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Mobile Phone" 
              name="mobilePhone"
              id="mobilePhone"
              ref={register}
              className={(errors.mobilePhone) && "error"}
            />
            {
              errors.mobilePhone &&
              <Form.Text className={'text-danger'}>
                {
                  errors.mobilePhone?.message
                }
              </Form.Text>
            }
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Postal Code</Form.Label>
            <Controller
              name="postalCode"
              render={(
                { onChange, onBlur, value, name, ref },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={optionPostalcode.length === 0}
                  onChange={(event) => {
                    onChange(event.label);
                    let filterAddress = [...contactReducer.postalcode?.data?.postalcodes].filter(ar => ar.id === event.value)[0];
                    setAddress(filterAddress)
                  }}
                  value={optionPostalcode.filter(ar => ar.label === postalCodeValue)[0]}
                  onInputChange={(input) => setInputPostalcode(input)}
                  isSearchable={true}
                  options={optionPostalcode}
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Unit Number</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Unit Number" 
              name="unitNumber"
              id="unitNumber"
              ref={register}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={'col-lg-12'}>
            <Form.Label>Address</Form.Label>
            <Form.Control 
              as="textarea"
              readOnly
              name="address"
              id="address"
              ref={register}
            />
          </Form.Group>
        </Form.Group>
      </CONTAINER>
    </>
  );
}
