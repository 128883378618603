import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import moment from "moment";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as invitationAction from "../redux/invitationAction";

const CONTAINER = styled.div`
  .error {
    border: 1px solid #ff6565;
  }
`;

const validationSchema = Yup.object().shape({
  email: Yup.string()
  .email()
  .required('Field is required')
})

export function InvitationOrientationAttendancePage(props) {
  const dispatch = useDispatch();
  const invitationReducer = useSelector(state => state.invitation);
  const [dataEvent, setDataEvent] = useState({});
  const { register, handleSubmit, setError, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    let idEvent = props.location.search.substring(4);
    dispatch(invitationAction.eventRetrieveRequest({id: idEvent}));
    dispatch(invitationAction.checkinReset());
  }, []);

  useEffect(() => {
    if(invitationReducer.isLoading === false && invitationReducer.isError === false && invitationReducer.invitationEvent !== null){
      setDataEvent(invitationReducer.invitationEvent.data.event);
    }
  }, [invitationReducer]);

  const submitData = data => {
    let payload = {
      id: props.location.search.substring(4),
      date: dataEvent.start,
      email: data.email
    }
    dispatch(invitationAction.checkinRequest(payload));
  }

  return (
    <div
      className="d-flex align-item-center justify-content-center"
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${toAbsoluteUrl("/media/nkf/volunteer-together.jpg")})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "blur(8px)",
          position: "absolute",
        }}
      />
      <Card style={{ width: "50rem", height: "30rem" }}>
        <CardHeader>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <div className="col-lg-2">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/nkf-circle-hearts-logo.png")}
                style={{
                  maxHeight: "45px",
                }}
              />
            </div>
            <div className="col-lg-8">
              <h5 className="text-center ">Orientation Event</h5>
            </div>
            <div className="col-lg-2">
              <SVG
                src={toAbsoluteUrl("/media/nkf/logo-nkf.svg")}
                className="h-50 align-self-center"
                style={{ maxHeight: "30px" }}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <CONTAINER>
            {Object.keys(dataEvent).length !== 0 ? (
              <Form>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Subject
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataEvent?.subject}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Start Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataEvent?.start).format("DD MMMM YYYY")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Volunteer Location
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      {
                        dataEvent?.locationUrl !== "" ?
                        <a href={dataEvent?.locationUrl} target="_blank">
                          <Form.Label style={{
                            color: 'blue',
                            textDecorationLine: 'underline',
                            cursor: 'pointer'
                          }}>{dataEvent?.location}</Form.Label>
                        </a> :
                        <Form.Label>{dataEvent?.location}</Form.Label>
                      }
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group 
                  as={Row}
                  className={'align-items-center justify-content-center mb-5'}
                >
                  <Col lg={8} xs={12} className={"align-items-center justify-content-center d-flex"}>
                    <Form.Control 
                      type="text" 
                      placeholder="email" 
                      name="email"
                      id="email"
                      ref={register}
                      style={{ width: "50%" }}
                      className={(errors.email) && "error"}
                      disabled={invitationReducer?.invitationCheckin?.data?.event[0].success}
                    />
                  </Col>
                  <Col lg={8} xs={12} className={"align-items-center justify-content-center d-flex"}>
                    <Form.Text className={`${errors.email ? 'text-danger' : 'text-muted'} mt-0 mb-0`}>
                      {
                        errors.email?.message
                      }
                    </Form.Text>
                  </Col>
                </Form.Group>
                <Form.Text 
                  as={Row}
                  className={`${invitationReducer.isError ? 'text-danger' : 'text-muted'} mt-0 mb-0 align-items-center justify-content-center d-flex`}>
                  { invitationReducer.errorMsg?.message }
                </Form.Text>
                <Form.Group
                  as={Row}
                  className={"mb-1 align-items-center justify-content-center"}
                >
                  <Col lg={8} xs={12} className={"align-items-center justify-content-center d-flex"}>
                    {
                      invitationReducer?.invitationCheckin?.data?.event[0].success ?
                      <Form.Label className="text-success">Welcome to our volunteer orientation</Form.Label> :
                      <Button
                        variant={"success"}
                        style={{ width: "50%" }}
                        onClick={handleSubmit(submitData)}
                        disabled={invitationReducer.isLoading}
                      >
                        Check-in
                        {
                          invitationReducer.isLoading &&
                          <span className="ml-3 spinner spinner-white"></span>
                        }
                      </Button>
                    }
                  </Col>
                </Form.Group>
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </CONTAINER>
        </CardBody>
      </Card>
    </div>
  );
}
