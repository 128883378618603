import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { SignupVolunteerAgreementForm } from "../../form/SignupVolunteerAgreementForm";

export function VolunteerAgreementFormStep(){
  return(
    <>
      <h5 className={'font-weight-bolder text-center'}>AGREEMENT</h5>
      <>
        <Form.Group as={Row} className={'mb-0'}>
          <Form.Group className={'col-lg-10 mx-auto'}>
            <p className={'text-justify'}>In participation to The National Kidney Foundation (NKF) Volunteer Programme, I hereby accept and acknowledge the terms and conditions stated below of being an NKF Volunteer:</p>
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'col-lg-12 mx-auto mb-0'}>
          <ol type={"1"}>
            <li>
              <p className={'text-justify'}>I acknowledge and agree that my participation in NKF programmes is on a voluntary basis and that I have ascertained that risks may be inherent during my service and I hereby confirm that I voluntarily assume those risks;</p>
            </li>
            <li>
              <p className={'text-justify'}>I agree and accept that NKF shall not be required to compensate me in monetary or alternatives, should I suffer any harm or loss whether directly/indirectly during my course of service. These shall include physical harm such as illness, injury or death, harm to my reputation, or loss of or damage to personal property owned or used by me in the provision of my services;</p>
            </li>
            <li>
              <p className={'text-justify'}>I will not seek legal action to claim against NKF, its officers, agents, volunteers, staff, and/or third parties who organised project/event for any harm or loss suffered directly/indirectly by me during my term as a NKF Volunteer. I shall indemnify and hold NKF harmless against all claims/losses (including but not limited to medical expenses etc), mishaps, accidents or injuries (including death) demands/actions/suits/proceedings/costs/expenses whatsoever in connection with loss of life, personal injury and/or damage to property, arising whether directly or indirectly, from my service as a NKF Volunteer; </p>
            </li>
            <li>
              <p className={'text-justify'}>I shall strictly adhere to the confidentiality of all information of NKF, its subsidiaries, its patients etc;</p>
            </li>
            <li>
              <p className={'text-justify'}>I acknowledge that phototaking is prohibited unless with the prior approval from the appointed Programme Coordinator. I also agree that any images/pictures taken in relation (directly/indirectly) during my course of service, shall remain as the official property and in ownership of NKF. I will not reproduce, re-transmit, disseminate, sell, distribute, publish, broadcast, circulate or commercially exploit them in any manner without the prior consent of NKF;</p>
            </li>
            <li>
              <p className={'text-justify'}>I agree that images, prints and information of programmes with NKF may be used for NKF’s promotional, publicity, fundraising or other purposes as NKF may deem fit. Unless otherwise specified, it is understood that any and all rights to proofs, final or sample prints, thereof shall remain the property of NKF. NKF shall not be liable for any claims on the misuse, misinterpretation or factual errors of the information used in part or whole;</p>
            </li>
            <li>
              <p className={'text-justify'}>I will notify the appointed Programme Coordinator immediately when situations or challenges occur in the course of my service;</p>
            </li>
            <li>
              <p className={'text-justify'}>I agree to wear the required identification (when applicable) when on official duty as a Volunteer;</p>
            </li>
            <li>
              <p className={'text-justify'}>I will serve the minimum commitment level as per required by the volunteer programme requirements;</p>
            </li>
            <li>
              <p className={'text-justify'}>Any reference to NKF in this document shall include the NKF and Dialysis Centres employees;</p>
            </li>
            <li>
              <p className={'text-justify'}>I accept and acknowledge that clear boundaries are drawn in the relationship between an NKF Volunteer and patient:</p>
              <ol type={'a'}>
                <li>
                  <p className={'text-justify'}>There shall be no in-kind or monetary trading during the course of service.</p>
                </li>
                <li>
                  <p className={'text-justify'}>I shall be sensitive and respectful to patients’ cultures, beliefs and religion.</p>
                </li>
                <li>
                  <p className={'text-justify'}>I shall refrain from propagandising my own culture, beliefs and religion to patients during dialysis and/or home visits.</p>
                </li>
                <li>
                  <p className={'text-justify'}>No prescription or medical advice shall be administered, which include health products/supplements by me.</p>
                </li>
                <li>
                  <p className={'text-justify'}>I shall refrain from going alone on home visits.</p>
                </li>
                <li>
                  <p className={'text-justify'}>I shall not make any engagements with patients/family members without prior approval from NKF.</p>
                </li>
              </ol>
            </li>
            <li>
              <p className={'text-justify'}>I understand that NKF reserves the right to terminate my service as an NKF Volunteer prematurely if:</p>
              <ol type={'a'}>
                <li>
                  <p className={'text-justify'}>I am found to breach rules and regulations set forth in this Agreement;</p>
                </li>
                <li>
                  <p className={'text-justify'}>I am found abusing or harassing any parties I come in contact with throughout my service with NKF;</p>
                </li>
                <li>
                  <p className={'text-justify'}>I am charged for any criminal offence;</p>
                </li>
                <li>
                  <p className={'text-justify'}>I am diagnosed with critical or chronic illness/disability that may deter my service and/or;</p>
                </li>
                <li>
                  <p className={'text-justify'}>Any other grounds deemed fit by the Programme Coordinator.</p>
                </li>
              </ol>
            </li>
            <li>
              <p className={'text-justify'}>With my personal information given in the Volunteer Application Form:</p>
              <ol type={'a'}>
                <li>
                  <p className={'text-justify'}>I understand that the provision of my personal particulars that include Name and NRIC/FIN/Passport No. is for the purpose of insurance coverage against bodily harm. I understand that I have the option of not providing the above-mentioned information should I choose not to be insured by NKF while performing my volunteering duties.</p>
                </li>
                <li>
                  <p className={'text-justify'}>I agree for NKF to contact me via letters, emails, SMSes and telephone calls using the contact information I have provided. I will notify the appointed Volunteer Coordinator when there is a change to my contact information or if I wish not to be contacted.</p>
                </li>
                <li>
                  <p className={'text-justify'}>I agree that the information will be used to improve NKF services, facilitate NKF volunteering programmes, respond to my requests and communicate any other purpose relating to the services that NKF is providing.</p>
                </li>
                <li>
                  <p className={'text-justify'}>I agree for NKF to share only necessary information with other Government agencies or non-Government organisations authorised by NKF to carry out specific functions in order to render an efficient and effective service to me, unless the sharing of the information is prohibited by legislation.</p>
                </li>
                <li>
                  <p className={'text-justify'}>I note that I may withdraw my consent to the release/disclosure/use of my personal information at any time by informing NKF in writing of my withdrawal of consent and further note that it may take seven working days for my withdrawal of consent to be reflected in NKF’s system.</p>
                </li>
              </ol>
            </li>
            <li>
              <p className={'text-justify'}>I agree that nothing in this Agreement is intended to grant to any third party any right to enforce any term of this Agreement or to confer on any third party any benefits under this Agreement for the purposes of the Contract (Rights of Third Parties) Act (Cap 53B).</p>
            </li>
          </ol>
          <SignupVolunteerAgreementForm/>
        </Form.Group>
      </>
    </>
  )
}