export const ATTENDANCE = {
  DETAIL_VR_REQUEST: 'ATTENDANCE_DETAIL_VR_REQUEST',
  DETAIL_VR_SUCCESS: 'ATTENDANCE_DETAIL_VR_SUCCESS',
  DETAIL_VR_ERROR: 'ATTENDANCE_DETAIL_VR_ERROR',
  DETAIL_VR_RESET: 'ATTENDANCE_DETAIL_VR_RESET',

  VALIDATE_VR_REQUEST: 'ATTENDANCE_VALIDATE_VR_REQUEST',
  VALIDATE_VR_SUCCESS: 'ATTENDANCE_VALIDATE_VR_SUCCESS',
  VALIDATE_VR_ERROR: 'ATTENDANCE_VALIDATE_VR_ERROR',
  VALIDATE_VR_RESET: 'ATTENDANCE_VALIDATE_VR_RESET',

  CHECKIN_VR_REQUEST: 'ATTENDANCE_CHECKIN_VR_REQUEST',
  CHECKIN_VR_SUCCESS: 'ATTENDANCE_CHECKIN_VR_SUCCESS',
  CHECKIN_VR_ERROR: 'ATTENDANCE_CHECKIN_VR_ERROR',
  CHECKIN_VR_RESET: 'ATTENDANCE_CHECKIN_VR_RESET',

  CHECKOUT_VR_REQUEST: 'ATTENDANCE_CHECKOUT_VR_REQUEST',
  CHECKOUT_VR_SUCCESS: 'ATTENDANCE_CHECKOUT_VR_SUCCESS',
  CHECKOUT_VR_ERROR: 'ATTENDANCE_CHECKOUT_VR_ERROR',
  CHECKOUT_VR_RESET: 'ATTENDANCE_CHECKOUT_VR_RESET',

  DETAIL_TRAINING_REQUEST: 'ATTENDANCE_DETAIL_TRAINING_REQUEST',
  DETAIL_TRAINING_SUCCESS: 'ATTENDANCE_DETAIL_TRAINING_SUCCESS',
  DETAIL_TRAINING_ERROR: 'ATTENDANCE_DETAIL_TRAINING_ERROR',
  DETAIL_TRAINING_RESET: 'ATTENDANCE_DETAIL_TRAINING_RESET',

  VALIDATE_TRAINING_REQUEST: 'ATTENDANCE_VALIDATE_TRAINING_REQUEST',
  VALIDATE_TRAINING_SUCCESS: 'ATTENDANCE_VALIDATE_TRAINING_SUCCESS',
  VALIDATE_TRAINING_ERROR: 'ATTENDANCE_VALIDATE_TRAINING_ERROR',
  VALIDATE_TRAINING_RESET: 'ATTENDANCE_VALIDATE_TRAINING_RESET',

  CHECKIN_TRAINING_REQUEST: 'ATTENDANCE_CHECKIN_TRAINING_REQUEST',
  CHECKIN_TRAINING_SUCCESS: 'ATTENDANCE_CHECKIN_TRAINING_SUCCESS',
  CHECKIN_TRAINING_ERROR: 'ATTENDANCE_CHECKIN_TRAINING_ERROR',
  CHECKIN_TRAINING_RESET: 'ATTENDANCE_CHECKIN_TRAINING_RESET',

  CHECKOUT_TRAINING_REQUEST: 'ATTENDANCE_CHECKOUT_TRAINING_REQUEST',
  CHECKOUT_TRAINING_SUCCESS: 'ATTENDANCE_CHECKOUT_TRAINING_SUCCESS',
  CHECKOUT_TRAINING_ERROR: 'ATTENDANCE_CHECKOUT_TRAINING_ERROR',
  CHECKOUT_TRAINING_RESET: 'ATTENDANCE_CHECKOUT_TRAINING_RESET',
}