import React from 'react';
import { CircularProgress } from "@material-ui/core"

export function LoadingCard(){
  return(
    <>
      <div style={{
        width: '100%',
        height: '80vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <CircularProgress />
      </div>
    </>
  )
}