import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import moment from "moment";
import * as invitationAction from "../redux/invitationAction";
import { ModalInfoPE } from "../../ProgrammeEvents/modal/ModalInfoPE";

const CONTAINER = styled.div`
  .error {
    border: 1px solid #ff6565;
  }
`;

export function InvitationProgrammePage(props) {
  const dispatch = useDispatch();
  const invitationReducer = useSelector(state => state.invitation);
  const [idParticipant, setIdParticipant] = useState(null);
  const [dataProgramme, setDataProgramme] = useState({});
  const [dataParticipant, setDataParticipant] = useState({});
  const [modalInfo, setModalInfo] = useState({
    type: "",
    message: "",
    show: false,
    onConfirm: ()=>null
  })

  useEffect(() => {
    let _idParticipant = props.location.search.substring(4);
    setIdParticipant(_idParticipant);
    dispatch(invitationAction.participantInfoRequest({id: _idParticipant}));
  }, []);

  useEffect(() => {
    if(invitationReducer.isLoading === false && invitationReducer.isError === false && invitationReducer.participantInfo !== null){
      setDataProgramme(invitationReducer.participantInfo?.data?.programmeEvent);
      setDataParticipant(invitationReducer.participantInfo?.data?.participant);
    }
    if(invitationReducer.isLoading === false && (invitationReducer.registerProgrammeEmail !== null || invitationReducer.cancelProgrammeEmail !== null || invitationReducer.isError === true)){
      if(invitationReducer.registerProgrammeEmail !== null){
        setModalInfo({
          ...modalInfo,
          type: "info",
          message: "You are successfully registered! We will contact you soon.",
          show: true,
          onConfirm: ()=>null
        })
      }
      dispatch(invitationAction.registerProgrammeEmailReset());
      dispatch(invitationAction.cancelProgrammeEmailReset());
      dispatch(invitationAction.participantInfoRequest({id: idParticipant}));
    }
  }, [invitationReducer]);

  function responseAction(){
    if(dataParticipant?.Status__c === "Registered" || dataParticipant?.Status__c === "Accepted"){
      setModalInfo({
        ...modalInfo,
        type: "confirm",
        message: "We are sorry that you are not able to join us for the activity. We hope to see you again soon.",
        show: true,
        onConfirm: ()=>{
          setModalInfo({
            ...modalInfo,
            show: false
          })
          dispatch(invitationAction.cancelProgrammeEmailRequest({id: idParticipant}));
        }
      })
    } else {
      dispatch(invitationAction.registerProgrammeEmailRequest({id: idParticipant}));
    }
  }

  return (
    <div
      className="d-flex align-item-center justify-content-center"
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${toAbsoluteUrl("/media/nkf/volunteer-together.jpg")})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "blur(8px)",
          position: "absolute",
        }}
      />
      <Card style={{ width: "50rem" }}>
        <CardHeader>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <div className="col-lg-2">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/nkf-circle-hearts-logo.png")}
                style={{
                  maxHeight: "45px",
                }}
              />
            </div>
            <div className="col-lg-8">
              <h5 className="text-center ">Invitation Programme</h5>
            </div>
            <div className="col-lg-2">
              <SVG
                src={toAbsoluteUrl("/media/nkf/logo-nkf.svg")}
                className="h-50 align-self-center"
                style={{ maxHeight: "30px" }}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <CONTAINER>
            {Object.keys(dataProgramme).length !== 0 ? (
              <Form>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Programme Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataProgramme?.Name}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Start Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataProgramme?.Start_Date_Time__c).format("DD MMMM YYYY HH:mm:ss")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        End Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataProgramme?.End_Date_Time__c).format("DD MMMM YYYY HH:mm:ss")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Venue
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {dataProgramme?.Venue_of_Event_or_Mobilisation__c}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Program Description
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {dataProgramme?.Programme_Description__c}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Status
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {dataProgramme?.Programme_Stage__c}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Participant Status
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {dataParticipant?.Status__c}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                {
                  dataParticipant?.Status__c === "Selected" || 
                  dataParticipant?.Status__c === "Invitation Sent" || 
                  dataParticipant?.Status__c === "Registered" ||
                  dataParticipant?.Status__c === "Accepted" ||
                  dataParticipant?.Status__c === "Cancel" ?
                  <Form.Group
                    as={Row}
                    className={"mb-1 align-items-center justify-content-center"}
                  >
                    <Button
                      variant={
                        dataParticipant?.Status__c === "Selected" || 
                        dataParticipant?.Status__c === "Invitation Sent" || 
                        dataParticipant?.Status__c === "Cancel" ? 
                        "success" : "secondary"
                      }
                      style={{ width: "30%", marginRight: 10 }}
                      onClick={() => responseAction()}
                      disabled={invitationReducer.isLoading}
                    >
                      {
                        dataParticipant?.Status__c === "Selected" || 
                        dataParticipant?.Status__c === "Invitation Sent" || 
                        dataParticipant?.Status__c === "Cancel" ? "Register" : 
                        dataProgramme?.Programme_Stage__c === "Started" ?
                        "Withdraw" : "Cancel"
                      }
                      {
                        invitationReducer.isLoading &&
                        <span className="ml-3 spinner spinner-white"></span>
                      }
                    </Button>
                  </Form.Group> :
                  null
                }
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </CONTAINER>
        </CardBody>
        <ModalInfoPE 
          show={modalInfo.show}
          message={modalInfo.message}
          type={modalInfo.type}
          onHide={()=>setModalInfo({...modalInfo, show: false})}
          onConfirm={modalInfo.onConfirm}
        />
      </Card>
    </div>
  );
}
