export const DASHBOARD = {
  CHATTER_REQUEST: 'DASHBOARD_CHATTER_REQUEST',
  CHATTER_SUCCESS: 'DASHBOARD_CHATTER_SUCCESS',
  CHATTER_ERROR: 'DASHBOARD_CHATTER_ERROR',
  CHATTER_RESET: 'DASHBOARD_CHATTER_RESET',

  MY_ACTIVITY_REQUEST: 'DASHBOARD_MY_ACTIVITY_REQUEST',
  MY_ACTIVITY_SUCCESS: 'DASHBOARD_MY_ACTIVITY_SUCCESS',
  MY_ACTIVITY_ERROR: 'DASHBOARD_MY_ACTIVITY_ERROR',
  MY_ACTIVITY_RESET: 'DASHBOARD_MY_ACTIVITY_RESET',

  CONTACT_US_REQUEST: 'DASHBOARD_CONTACT_US_REQUEST',
  CONTACT_US_SUCCESS: 'DASHBOARD_CONTACT_US_SUCCESS',
  CONTACT_US_ERROR: 'DASHBOARD_CONTACT_US_ERROR',
  CONTACT_US_RESET: 'DASHBOARD_CONTACT_US_RESET',
}