export const AUTH = {
  SET_TOKEN_SETUP_PASSWORD: 'AUTH_SET_TOKEN_SETUP_PASSWORD',
  SET_CREDENTIAL: 'AUTH_SET_CREDENTIAL',
  SET_CHANGE_PROFILE: 'SET_CHANGE_PROFILE',

  SETUP_PASSWORD_REQUEST: 'AUTH_SETUP_PASSWORD_REQUEST',
  SETUP_PASSWORD_SUCCESS: 'AUTH_SETUP_PASSWORD_SUCCESS',
  SETUP_PASSWORD_ERROR: 'AUTH_SETUP_PASSWORD_ERROR',
  SETUP_PASSWORD_RESET: 'AUTH_SETUP_PASSWORD_RESET',

  LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  LOGIN_ERROR: 'AUTH_LOGIN_ERROR',
  LOGIN_RESET: 'AUTH_LOGIN_RESET',

  FA_REQUEST: 'AUTH_FA_REQUEST',
  FA_SUCCESS: 'AUTH_FA_SUCCESS',
  FA_ERROR: 'AUTH_FA_ERROR',
  FA_RESET: 'AUTH_FA_RESET',

  FORGOT_REQUEST: 'AUTH_FORGOT_REQUEST',
  FORGOT_SUCCESS: 'AUTH_FORGOT_SUCCESS',
  FORGOT_ERROR: 'AUTH_FORGOT_ERROR',
  FORGOT_RESET: 'AUTH_FORGOT_RESET',
}