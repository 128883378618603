import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, BlobProvider } from '@react-pdf/renderer';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import { useFormContext } from "react-hook-form";
import ApplicationFormContext from "../context/ApplicationFormContext";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
const { FormContext } = ApplicationFormContext;

const CONTAINER = styled.div`
.error {
  border: 1px solid #FF6565;
}`;

export function SignupIndemnityForm() {
  const formContext = useContext(FormContext);
  const { register, errors, watch } = useFormContext();
  const [isDisableDownload, setisDisableDownload] = useState(true);

  useEffect(() => {
    if(watch('parentName') !== '' && watch('parentContact') !== ''){
      setTimeout(() => {
        setisDisableDownload(false);
      }, 1000);
    }
    if(watch('step') > 2){
      formContext.handleChangeField('agreement', 'isAgreeIndemnity', true);
      formContext.handleChangeField('indemnity', 'isDownload', true);
      setisDisableDownload(false);
    }
  }, [watch('parentName'), watch('parentContact'), watch('step')])

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      borderWidth: 1,
      borderColor: 'black'
    },
    title: {
      fontSize: 18,
      color: 'black',
      fontWeight: 'bold',
      textAlign: 'center'
    },
    boxFirst: {
      marginHorizontal: 50,
      borderColor: 'black',
      borderWidth: 1
    },
    textContent: {
      fontSize: 10,
      color: 'black',
      marginBottom: 10,
      textAlign: 'justify',
      fontFamily: 'Open Sans'
    }
  });

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{flexDirection:'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 40, marginTop: 20}}>
          <Image 
            src={toAbsoluteUrl("/media/logos/nkf-circle-hearts-logo.png")}
            style={{width: 100, height: 35.34}}
          />
          <Image 
            src={toAbsoluteUrl("/media/nkf/logo-nkf.png")}
            style={{width: 100, height: 37}}
          />
        </View>
        <Text style={[styles.title, {marginTop: 15, fontWeight: 'bold', fontFamily: 'Open Sans'}]}>Indemnity Form (participants below 21 years old)</Text>
        <View style={[styles.boxFirst, {padding: 15, marginTop: 5}]}>
          <Text style={{fontSize: 11, color: 'black', fontFamily: 'Open Sans' }}>
            <Text style={{fontWeight: 'bold', fontFamily: 'Open Sans'}}>{`Name (as in NRIC/Birth Certificate): `}</Text>
            <Text style={{textDecoration: 'underline'}}>{`${watch('firstName')+' '+watch('lastName')}`}</Text>
          </Text>
          <Text style={{fontSize: 11, color: 'black', fontFamily: 'Open Sans' }}>
            <Text style={{fontWeight: 'bold', fontFamily: 'Open Sans'}}>{`NRIC: `}</Text>
            <Text style={{textDecoration: 'underline'}}>{`${watch('idNumber')}`}</Text>
          </Text>
          <Text style={{fontSize: 11, color: 'black', fontFamily: 'Open Sans' }}>
            <Text style={{fontWeight: 'bold', fontFamily: 'Open Sans'}}>{`Contact No: `}</Text>
            <Text style={{textDecoration: 'underline'}}>{`${watch('mobilePhone')}`}</Text>
          </Text>
          <Text style={{fontSize: 10, color: 'black', textAlign: 'justify', marginTop: 10, fontFamily: 'Open Sans' }}>I agree and abide to uphold The National Kidney Foundation’s (“NKF”) name in an appropriate manner in accordance with NKF’s Agreement and Volunteer Guidelines, a copy each of which has been made available to me, by displaying appropriate behaviour and conduct at all times during my volunteer service with NKF. In the event of any unfortunate incidents, injury, accident or mishap whether fatal or otherwise, my family and I shall not hold NKF, its staff and/or other volunteers and/or other persons responsible and shall defend and hold the same harmless from all claims and damages (personal injuries/death, mishap, etc) arising from my participation before, during and after the activity.</Text>
        </View>
        <Text style={[styles.title, {marginTop: 10, fontSize: 16, fontWeight: 'bold', fontFamily: 'Open Sans'}]}>Parent / Guardian’s Consent Form</Text>
        <View style={[styles.boxFirst, { padding: 15, marginTop: 5 }]}>
          <Text style={styles.textContent}>I understand that as a volunteer with NKF Circle of Hearts, my child/ward will be volunteering his/her services solely for his/her personal purpose or benefit without promise or expectation of compensation or benefits.</Text>
          <Text style={styles.textContent}>I understand that NKF Circle of Hearts and its representatives will take all reasonable steps to provide individual care and safety for each child, but I am aware that NKF and/or its staff and/or its volunteers cannot assume responsibility for any injury/death, loss, damage or harm to any child or to his/her property during the course of the activity, including travelling to and from the activity site.</Text>
          <Text style={styles.textContent}>I will not take any legal actions and/or claims (including but not limited to medical expenses etc) against NKF and/or its staff, volunteers and all persons and/or agencies connected with the activity and shall defend and hold the same harmless from all claims and damages (personal injuries/death, mishap, etc) arising from my child/ward’s participation before, during and after the activity.</Text>
          <Text style={styles.textContent}>In the event of an accident or illness requiring professional medical care (casts, stitches, X-ray, emergency surgery, hospital confinement, etc), I hereby authorise NKF, its staff & appointed volunteers to act on my behalf in giving my permission to obtain professional medical care, if it is deemed necessary, from a licensed physician, hospital or other medical facility.</Text>
          <Text style={styles.textContent}>I fully understand the above agreement, and will not hold NKF, its staff & appointed volunteers, medics, physician, hospital etc responsible for any action taken for the professional emergency services performed.</Text>
          <Text style={[styles.textContent, {marginBottom: 0}]}>I also declare that my child/ward is medically and mentally fit to participate in this activity. I am aware that I can seek legal advice or have already sought legal advice, in respect of this waiver of liability before signing this document.</Text>
        </View>
        <View style={{flexDirection: 'row', marginHorizontal: 50, borderLeftWidth: 1, borderRightWidth: 1, borderBottomWidth: 1}}>
          <View style={{width: '60%', borderRightWidth: 1}}>
            <View style={{borderColor: 'black', padding: 10, borderBottomWidth: 1}}>
              <Text style={{fontSize: 10, color: 'black', marginBottom: 10, fontFamily: 'Open Sans'}}>
                <Text style={{fontWeight: 'bold'}}>{`Parent/Guardian's Name: `}</Text>
                <Text>{`${watch('parentName')}`}</Text>
              </Text>
              <Text style={{fontSize: 10, color: 'black', fontFamily: 'Open Sans'}}>
                <Text style={{fontWeight: 'bold', fontSize: 8}}>{`Partial NRIC No (e.g., XXXXX567A from S1234567A): `}</Text>
                <Text>{`XXXXX${watch('parentNric') === '' ? '___' : watch('parentNric')}`}</Text>
              </Text>
            </View>
            <View style={{borderColor: 'black', padding: 10}}>
              <Text style={{fontSize: 10, color: 'black', fontFamily: 'Open Sans'}}>
                <Text style={{fontWeight: 'bold'}}>{`Parent/Guardian’s Contact No.: `}</Text>
                <Text>{`${watch('parentContact')}`}</Text>
              </Text>
            </View>
          </View>
          <View style={{width: '40%', padding: 10}}>
            <Text style={{fontSize: 10, fontWeight: 'bold', color: 'black', fontFamily: 'Open Sans'}}>Signature of Parent/Guardian:</Text>
          </View>
        </View>
        <View style={{borderColor: 'black', borderLeftWidth: 1, borderRightWidth: 1, borderBottomWidth: 1, marginHorizontal: 50, padding: 10}}>
          <Text style={{fontSize: 10, fontWeight: 'bold', color: 'black', fontFamily: 'Open Sans'}}>For Official use:</Text>
        </View>
      </Page>
    </Document>
  );

  function handleDownload(){
    formContext.handleChangeField('indemnity', 'isDownload', true);
  }

  const DownloadPdf = () => {
    return useMemo(
      () => (
        <BlobProvider document={<MyDocument />} fileName="Indemnity-Form.pdf">
          {({ url, loading }) => (
            loading ? 
            <a href={url} target="_blank">
              <Button onClick={handleDownload} disabled={true}>
                <i className="flaticon2-download"/>Download file to continue
              </Button>
            </a>
            : 
            <a href={url} target="_blank">
              <Button onClick={handleDownload} disabled={isDisableDownload}>
                <i className="flaticon2-download"/>Download file to continue
              </Button>
            </a>
          )}
        </BlobProvider>
      ),
      [],
    )
  }
  
  return (
    <>
      <CONTAINER>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Parent/Guardian's Name</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Parent/Guardian's Name" 
              name="parentName"
              id="parentName"
              ref={register}
              className={(errors.parentName) && "error"}
            />
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Partial NRIC</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Partial NRIC" 
              name="parentNric"
              id="parentNric"
              ref={register}
              className={(errors.parentNric) && "error"}
            />
            <Form.Text className="text-muted">
              (e.g., XXXXX567A from S1234567A)
            </Form.Text>
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Contact No.</Form.Label>
            <Form.Control 
              type="number" 
              placeholder="Contact No" 
              name="parentContact"
              id="parentContact"
              ref={register}
              className={(errors.parentContact) && "error"}
            />
            {
              errors.parentContact &&
              <Form.Text className={'text-danger'}>
                {
                  errors.parentContact?.message
                }
              </Form.Text>
            }
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'col-lg-6 mx-auto mb-1'} controlId="formBasicChecbox" style={{marginTop: 10}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formContext.form.agreement.isAgreeIndemnity} 
                onChange={(event)=>formContext.handleChangeField('agreement', 'isAgreeIndemnity', event.target.checked)}
                style={{
                  transform: "scale(1.2)"
                }}
              />
            }
            label="I have read and fully understood, accepted and will adhere to the various measures and policies" 
          />
        </Form.Group>
        <Form.Group as={Row} className={'col-lg-6 mx-auto mb-1 justify-content-center'} controlId="formBasicChecbox" style={{marginTop: 10}}>
          <DownloadPdf />
        </Form.Group>
      </CONTAINER>
    </>
  );
}
