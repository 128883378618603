import React, { useContext, useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useFormContext } from "react-hook-form";
import ApplicationFormContext from "../context/ApplicationFormContext";
const { FormContext } = ApplicationFormContext;

export function SignupVolunteerGuidelinesForm() {
  const formContext = useContext(FormContext);
  const { watch } = useFormContext();

  useEffect(() => {
    if(watch('step') > 5){
      formContext.handleChangeField('agreement', 'isAgreeGuidelines', true);
    }
  }, [watch('step')])

  return (
    <>
      <Form.Group as={Row} className={'col-lg-8 mx-auto mb-0'} controlId="formBasicChecbox" style={{marginTop: 10}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formContext.form.agreement.isAgreeGuidelines} 
              onChange={(event)=>formContext.handleChangeField('agreement', 'isAgreeGuidelines', event.target.checked)}
              style={{
                transform: "scale(1.2)"
              }}
            />
          }
          label="I have read and fully understood, accepted and will adhere to the various measures and policies, including the Volunteer Agreement and Volunteer Guidelines set by The National Kidney Foundation." 
        />
      </Form.Group>
    </>
  );
}
