import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isLoadingForm: false,
  isLoadingVerificationCode: false,
  isError: false,
  isErrorForm: false,
  errorMsg: null,
  signupVerification: null,
  signupVerificationAdhoc: null,
  signupContactRetrieve: null,
  signupForm: null,
  signupVerificationCode: null,
  signupVerificationCodeForm: null
}

const verificationRequest = state => ({
  ...state,
  isLoading: true
})
const verificationSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  signupVerification: payload
})
const verificationError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const verificationReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  signupVerification: null
})

const verificationAdhocRequest = state => ({
  ...state,
  isLoading: true
})
const verificationAdhocSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  signupVerificationAdhoc: payload
})
const verificationAdhocError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const verificationAdhocReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  signupVerificationAdhoc: null
})

const contactRetrieveRequest = state => ({
  ...state,
  isLoading: true
})
const contactRetrieveSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  signupContactRetrieve: payload
})
const contactRetrieveError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const contactRetrieveReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  signupContactRetrieve: null
})

const formRequest = state => ({
  ...state,
  isLoadingForm: true
})
const formSuccess = (state, payload) => ({
  ...state,
  isLoadingForm: false,
  isErrorForm: false,
  errorMsg: null,
  signupForm: payload
})
const formError = (state, payload) => ({
  ...state,
  isLoadingForm: false,
  isErrorForm: true,
  errorMsg: payload
})
const formReset = state => ({
  ...state,
  isLoadingForm: false,
  isErrorForm: false,
  errorMsg: null,
  signupForm: null
})

const verificationCodeRequest = state => ({
  ...state,
  isLoadingVerificationCode: true,
})
const verificationCodeSuccess = (state, payload) => ({
  ...state,
  isLoadingVerificationCode: false,
  isError: false,
  errorMsg: null,
  signupVerificationCode: payload
})
const verificationCodeError = (state, payload) => ({
  ...state,
  isLoadingVerificationCode: false,
  isError: true,
  errorMsg: payload
})
const verificationCodeReset = state => ({
  ...state,
  isLoadingVerificationCode: false,
  isError: false,
  errorMsg: null,
  signupVerificationCode: null
})

const verificationCodeFormRequest = state => ({
  ...state,
  isLoading: true
})
const verificationCodeFormSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isErrorForm: false,
  errorMsg: null,
  signupVerificationCodeForm: payload
})
const verificationCodeFormError = (state, payload) => ({
  ...state,
  isLoading: false,
  isErrorForm: true,
  errorMsg: payload
})
const verificationCodeFormReset = state => ({
  ...state,
  isLoading: false,
  isErrorForm: false,
  errorMsg: null,
  signupVerificationCodeForm: null
})


export const signupReducer = persistReducer(
  { storage, key: "nkf-signup", whitelist: ["signupVerification"] },
  (state = initialState, action) => {
  switch (action.type ) {
    case actionType.SIGNUP.VERIFICATION_REQUEST:
      return verificationRequest(state, action.payload);
    case actionType.SIGNUP.VERIFICATION_SUCCESS:
      return verificationSuccess(state, action.payload);
    case actionType.SIGNUP.VERIFICATION_ERROR:
      return verificationError(state, action.payload);
    case actionType.SIGNUP.VERIFICATION_RESET:
      return verificationReset(state, action.payload);

    case actionType.SIGNUP.VERIFICATION_ADHOC_REQUEST:
      return verificationAdhocRequest(state, action.payload);
    case actionType.SIGNUP.VERIFICATION_ADHOC_SUCCESS:
      return verificationAdhocSuccess(state, action.payload);
    case actionType.SIGNUP.VERIFICATION_ADHOC_ERROR:
      return verificationAdhocError(state, action.payload);
    case actionType.SIGNUP.VERIFICATION_ADHOC_RESET:
      return verificationAdhocReset(state, action.payload);

    case actionType.SIGNUP.CONTACT_RETRIEVE_REQUEST:
      return contactRetrieveRequest(state, action.payload);
    case actionType.SIGNUP.CONTACT_RETRIEVE_SUCCESS:
      return contactRetrieveSuccess(state, action.payload);
    case actionType.SIGNUP.CONTACT_RETRIEVE_ERROR:
      return contactRetrieveError(state, action.payload);
    case actionType.SIGNUP.CONTACT_RETRIEVE_RESET:
      return contactRetrieveReset(state, action.payload);

    case actionType.SIGNUP.FORM_REQUEST:
      return formRequest(state, action.payload);
    case actionType.SIGNUP.FORM_SUCCESS:
      return formSuccess(state, action.payload);
    case actionType.SIGNUP.FORM_ERROR:
      return formError(state, action.payload);
    case actionType.SIGNUP.FORM_RESET:
      return formReset(state, action.payload);

    case actionType.SIGNUP.VERIFICATION_CODE_REQUEST:
      return verificationCodeRequest(state, action.payload);
    case actionType.SIGNUP.VERIFICATION_CODE_SUCCESS:
      return verificationCodeSuccess(state, action.payload);
    case actionType.SIGNUP.VERIFICATION_CODE_ERROR:
      return verificationCodeError(state, action.payload);
    case actionType.SIGNUP.VERIFICATION_CODE_RESET:
      return verificationCodeReset(state, action.payload);

    case actionType.SIGNUP.VERIFICATION_CODE_FORM_REQUEST:
      return verificationCodeFormRequest(state, action.payload);
    case actionType.SIGNUP.VERIFICATION_CODE_FORM_SUCCESS:
      return verificationCodeFormSuccess(state, action.payload);
    case actionType.SIGNUP.VERIFICATION_CODE_FORM_ERROR:
      return verificationCodeFormError(state, action.payload);
    case actionType.SIGNUP.VERIFICATION_CODE_FORM_RESET:
      return verificationCodeFormReset(state, action.payload);
  
    default:
      return state;
  }
});