import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import moment from "moment-timezone";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as timesheetAction from "../redux/timesheetAction";

const CONTAINER = styled.div`
  .error {
    border: 1px solid #ff6565;
  }
`;

const validationSchema = Yup.object().shape({
  email: Yup.string()
  .required('Field is required')
})

export function TimesheetAttendanceVr(props) {
  const dispatch = useDispatch();
  const timesheetReducer = useSelector(state => state.timesheet);
  const [idAttendanceVr, setIdAttendanceVr] = useState(null);
  const [dataVR, setDataVR] = useState({});
  const [isCheckIn, setIsCheckIn] = useState(null);
  const { register, handleSubmit, setError, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: ''
    }
  });

  useEffect(() => {
    let _idAttendanceVr = props.location.search.substring(4);
    setIdAttendanceVr(_idAttendanceVr);
    dispatch(timesheetAction.detailVrRequest({id: _idAttendanceVr}));
  }, []);

  useEffect(() => {
    if(timesheetReducer.isLoading === false && timesheetReducer.isError === false && timesheetReducer.detailVr !== null){
      setDataVR(timesheetReducer.detailVr.data.volunteerRequest);
    }
    if(timesheetReducer.isLoading === false && timesheetReducer.isError === true){
      setError("email", {
        type: "manual",
        message: timesheetReducer.errorMsg.message
      });
      setIsCheckIn(null);
    }
    if(timesheetReducer.isLoading === false && timesheetReducer.isError === false && timesheetReducer.validateVr !== null){
      setIsCheckIn(timesheetReducer.validateVr?.data?.checkIn);
    }
    if(timesheetReducer.isLoading === false && timesheetReducer.isError === false && timesheetReducer.checkinVr !== null){
      setIsCheckIn(false);
    }
  }, [timesheetReducer]);

  function submitAttendance(values){
    if(isCheckIn === null){
      dispatch(timesheetAction.validateVrRequest({id: idAttendanceVr, identity: values.email}));
    } else {
      dispatch(timesheetAction.checkinVrReset());
      dispatch(timesheetAction.checkoutVrReset());
      if(isCheckIn === true){
        dispatch(timesheetAction.checkinVrRequest({id: idAttendanceVr, identity: values.email}));
      } else if(isCheckIn === false){
        dispatch(timesheetAction.checkoutVrRequest({id: idAttendanceVr, identity: values.email}));
      }
    }
  }

  return (
    <div
      className="d-flex align-item-center justify-content-center"
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${toAbsoluteUrl("/media/nkf/volunteer-together.jpg")})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "blur(8px)",
          position: "absolute",
        }}
      />
      <Card style={{ width: "50rem" }}>
        {/* <CardHeader>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <div className="col-lg-2">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/nkf-circle-hearts-logo.png")}
                style={{
                  maxHeight: "45px",
                }}
              />
            </div>
            <div className="col-lg-8">
              <h5 className="text-center ">Timesheet Attendance</h5>
            </div>
            <div className="col-lg-2">
              <SVG
                src={toAbsoluteUrl("/media/nkf/logo-nkf.svg")}
                className="h-50 align-self-center"
                style={{ maxHeight: "30px" }}
              />
            </div>
          </div>
        </CardHeader> */}
        <CardBody>
          <CONTAINER>
            {Object.keys(dataVR).length !== 0 ? (
              <Form onSubmit={handleSubmit(submitAttendance)}>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Volunteer Request Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataVR?.Name}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Start Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        { moment(dataVR?.Start_Date__c).tz("Asia/Singapore").format("DD MMMM YYYY")+" "+ (dataVR?.Start_Time__c ? dataVR?.Start_Time__c?.substring(0,5) : "")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        End Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                      {moment(dataVR?.End_Date__c).tz("Asia/Singapore").format("DD MMMM YYYY")+" "+ (dataVR?.End_Time__c ? dataVR?.End_Time__c?.substring(0,5) : "")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Frequency Type
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {dataVR?.Frequency_Type__c}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Status
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {dataVR?.Request_status__c}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                {
                  dataVR?.Request_status__c === "Started" ?
                  <>
                    {
                      timesheetReducer.checkinVr !== null &&
                      <Form.Group className={'col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column mb-0'}>
                        {
                          timesheetReducer.checkinVr?.data?.message + ' '+ moment(timesheetReducer.checkinVr?.data?.checkinTime).tz("Asia/Singapore").format("DD MMMM YYYY HH:mm")
                        }
                      </Form.Group>
                    }
                    {
                      timesheetReducer.checkoutVr !== null &&
                      <Form.Group className={'col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column mb-0'}>
                        {
                          timesheetReducer.checkoutVr?.data?.message + ' '+ moment(timesheetReducer.checkoutVr?.data?.checkOutTime).tz("Asia/Singapore").format("DD MMMM YYYY HH:mm")
                        }
                      </Form.Group>
                    }
                    <Form.Group className={'col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column mb-0'}>
                      <Form.Control 
                        type="text" 
                        placeholder="email" 
                        name="email"
                        id="email"
                        ref={register}
                        className={(errors.email) && "error"}
                      />
                      <Form.Text className={`${errors.email ? 'text-danger' : 'text-muted'} mt-0 mb-0`}>
                        {
                          errors.email?.message
                        }
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className={`col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column 
                      ${errors.email ? 'mt-0' : 'mt-5'}`}>
                        {
                          timesheetReducer.checkoutVr !== null ? null : 
                          <Button 
                            style={{ width: "100%"}} 
                            variant={`${isCheckIn === null ? 'primary' : isCheckIn === true ? 'success' : 'warning'}`} 
                            disabled={timesheetReducer.isLoading} 
                            type="submit"
                          >
                            {
                              isCheckIn === null ? 'SUBMIT' :
                              isCheckIn === true ? 'CHECK IN' : 'CHECK OUT'
                            }
                            {
                              timesheetReducer.isLoading &&
                              <span className="ml-3 spinner spinner-white"></span>
                            }
                          </Button>
                        }
                    </Form.Group>
                  </>
                  :
                  null
                }
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </CONTAINER>
        </CardBody>
      </Card>
    </div>
  );
}
