import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import moment from "moment";
import * as invitationAction from "../redux/invitationAction";

const CONTAINER = styled.div`
  .error {
    border: 1px solid #ff6565;
  }
`;

export function InvitationEventPage(props) {
  const dispatch = useDispatch();
  const invitationReducer = useSelector(state => state.invitation);
  const [responseInvitation, setResponseInvitation] = useState(null);
  const [dataEvent, setDataEvent] = useState({});

  useEffect(() => {
    let idEvent = props.location.search.substring(4);
    dispatch(invitationAction.eventRetrieveRequest({id: idEvent}));
  }, []);

  useEffect(() => {
    if(invitationReducer.isLoading === false && invitationReducer.isError === false && invitationReducer.invitationEvent !== null){
      setDataEvent(invitationReducer.invitationEvent.data.event);
    }
    if(invitationReducer.isLoading === false && invitationReducer.isError === false && invitationReducer.invitationSetResponse !== null){
      dispatch(invitationAction.setResponseReset());
      let idEvent = props.location.search.substring(4);
      dispatch(invitationAction.eventRetrieveRequest({id: idEvent}));
    }
  }, [invitationReducer]);

  function setResponse(param){
    setResponseInvitation(param);
    let payload = {
      id: props.location.search.substring(4),
      status: param
    }
    dispatch(invitationAction.setResponseRequest(payload));
  }

  return (
    <div
      className="d-flex align-item-center justify-content-center"
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${toAbsoluteUrl("/media/nkf/volunteer-together.jpg")})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "blur(8px)",
          position: "absolute",
        }}
      />
      <Card style={{ width: "50rem", height: "40rem" }}>
        <CardHeader>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <div className="col-lg-2">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/nkf-circle-hearts-logo.png")}
                style={{
                  maxHeight: "45px",
                }}
              />
            </div>
            <div className="col-lg-8">
              <h5 className="text-center ">Invitation Event</h5>
            </div>
            <div className="col-lg-2">
              <SVG
                src={toAbsoluteUrl("/media/nkf/logo-nkf.svg")}
                className="h-50 align-self-center"
                style={{ maxHeight: "30px" }}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <CONTAINER>
            {Object.keys(dataEvent).length !== 0 ? (
              <Form>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Volunteer Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataEvent?.volunteerName}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Subject
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>{dataEvent?.subject}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Start Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataEvent?.start).format("DD MMMM YYYY")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Start Time
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataEvent?.start).format("HH:mm")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        End Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataEvent?.end).format("DD MMMM YYYY")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        End Time
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataEvent?.end).format("HH:mm")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Volunteer Location
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      {
                        dataEvent?.locationUrl !== "" ?
                        <a href={dataEvent?.locationUrl} target="_blank">
                          <Form.Label style={{
                            color: 'blue',
                            textDecorationLine: 'underline',
                            cursor: 'pointer'
                          }}>{dataEvent?.location}</Form.Label>
                        </a> :
                        <Form.Label>{dataEvent?.location}</Form.Label>
                      }
                    </Form.Group>
                  </Col>
                </Form.Group>
                {
                  dataEvent?.status === "Invitation Sent" ?
                  <Form.Group
                    as={Row}
                    className={"mb-1 align-items-center justify-content-center"}
                  >
                    <Button
                      variant={"success"}
                      style={{ width: "30%", marginRight: 10 }}
                      onClick={() => setResponse("Accepted")}
                      disabled={invitationReducer.isLoading}
                    >
                      Accept
                      {
                        invitationReducer.isLoading && responseInvitation === "Accepted" &&
                        <span className="ml-3 spinner spinner-white"></span>
                      }
                    </Button>
                    <Button
                      variant={"danger"}
                      style={{ width: "30%", marginRight: 10 }}
                      onClick={() => setResponse("Declined")}
                      disabled={invitationReducer.isLoading}
                    >
                      Decline
                      {
                        invitationReducer.isLoading && responseInvitation === "Declined" &&
                        <span className="ml-3 spinner spinner-white"></span>
                      }
                    </Button>
                  </Form.Group> :
                  <Form.Group as={Row} className={"mb-1"}>
                    <Col lg={4} xs={6}>
                      <Form.Group>
                        <Form.Label className={"font-weight-bold"}>
                          Status
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg={6} xs={6}>
                      <Form.Group>
                        <Form.Label className={`text-${dataEvent?.status === "Accepted" ? 'success' : 'danger'}`}>{dataEvent?.status}</Form.Label>
                      </Form.Group>
                    </Col>
                  </Form.Group>
                }
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </CONTAINER>
        </CardBody>
      </Card>
    </div>
  );
}
