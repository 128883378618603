import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  chatter: null,
  myActivity: null,
  contactUs: null
}

const chatterRequest = state => ({
  ...state,
  isLoading: true
})
const chatterSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  chatter: payload
})
const chatterError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const chatterReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  chatter: null
})

const myActivityRequest = state => ({
  ...state,
  isLoading: true
})
const myActivitySuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  myActivity: payload
})
const myActivityError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const myActivityReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  myActivity: null
})

const contactUsRequest = state => ({
  ...state,
  isLoading: true
})
const contactUsSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  contactUs: payload
})
const contactUsError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const contactUsReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  contactUs: null
})

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type ) {
    case actionType.DASHBOARD.CHATTER_REQUEST:
      return chatterRequest(state, action.payload);
    case actionType.DASHBOARD.CHATTER_SUCCESS:
      return chatterSuccess(state, action.payload);
    case actionType.DASHBOARD.CHATTER_ERROR:
      return chatterError(state, action.payload);
    case actionType.DASHBOARD.CHATTER_RESET:
      return chatterReset(state, action.payload);

    case actionType.DASHBOARD.MY_ACTIVITY_REQUEST:
      return myActivityRequest(state, action.payload);
    case actionType.DASHBOARD.MY_ACTIVITY_SUCCESS:
      return myActivitySuccess(state, action.payload);
    case actionType.DASHBOARD.MY_ACTIVITY_ERROR:
      return myActivityError(state, action.payload);
    case actionType.DASHBOARD.MY_ACTIVITY_RESET:
      return myActivityReset(state, action.payload);

    case actionType.DASHBOARD.CONTACT_US_REQUEST:
      return contactUsRequest(state, action.payload);
    case actionType.DASHBOARD.CONTACT_US_SUCCESS:
      return contactUsSuccess(state, action.payload);
    case actionType.DASHBOARD.CONTACT_US_ERROR:
      return contactUsError(state, action.payload);
    case actionType.DASHBOARD.CONTACT_US_RESET:
      return contactUsReset(state, action.payload);
  
    default:
      return state;
  }
}