export const ATTENDANCE = {
  TABLE_REQUEST: "ATTENDANCE_TABLE_REQUEST",
  TABLE_SUCCESS: "ATTENDANCE_TABLE_SUCCESS",
  TABLE_ERROR: "ATTENDANCE_TABLE_ERROR",
  TABLE_RESET: "ATTENDANCE_TABLE_RESET",

  DETAIL_REQUEST: "ATTENDANCE_DETAIL_REQUEST",
  DETAIL_SUCCESS: "ATTENDANCE_DETAIL_SUCCESS",
  DETAIL_ERROR: "ATTENDANCE_DETAIL_ERROR",
  DETAIL_RESET: "ATTENDANCE_DETAIL_RESET",
}