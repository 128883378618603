import { methodService, apiService } from './apiService';

export const URL = {
  BASE_CALENDAR: '/calendars',
}

export function apiPeRegistered() {
  return apiService(
    URL.BASE_CALENDAR+'/programmeEvents',
    methodService.GET,
    null,
    null
  );
}


export function apiEventRegistered() {
  return apiService(
    URL.BASE_CALENDAR+'/volunteerRequests',
    methodService.GET,
    null,
    null
  );
}