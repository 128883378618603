import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

export function ModalInfoVr({show, onHide, type}){
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Body>
        <Row className={'col-lg-12 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center align-self-center mx-auto'}>
          {
            type === "register" ?
            <Form.Label style={{textAlign: "center"}}>You are successfully registered! We will contact you soon.</Form.Label>
            :
            <>
              <Form.Label style={{textAlign: "center"}}>Your request for withdrawal is received.</Form.Label>
              <Form.Label style={{textAlign: "center"}}>We hope to see you again soon.</Form.Label>
            </>
          }
        </Row>
        {
          (type === "register" || type === "cancel") &&
          <Row className={'col-lg-9 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center align-self-center mx-auto mt-5'}>
            <Col md='auto' sm={5} xs={5} className={'mx-auto'}>
              <Button 
                style={{minWidth: '10rem'}} 
                variant="primary" 
                onClick={onHide}
              >
                OK
              </Button>
            </Col>
          </Row>
        }
      </Modal.Body>
    </Modal>
  );
}