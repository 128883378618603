import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionTypes';
import * as attendanceAction from './attendanceActions';
import * as attendanceRepo from '../../../datasources/attendanceRepo';

function* getTable(data){
  try {
    const res = yield call(attendanceRepo.apiAttendanceTable, data.payload);
    yield put(attendanceAction.attendanceTableSuccess(res));
  } catch(err) {
    yield put(attendanceAction.attendanceTableError(err));
  }
}

function* getDetail(data){
  try {
    const res = yield call(attendanceRepo.apiAttendanceDetail, data.payload);
    yield put(attendanceAction.attendanceDetailSuccess(res));
  } catch(err) {
    yield put(attendanceAction.attendanceDetailError(err))
  }
}

export default function* watchAttendance() {
  yield all([
    takeLatest(actionType.ATTENDANCE.TABLE_REQUEST, getTable),
    takeLatest(actionType.ATTENDANCE.DETAIL_REQUEST, getDetail)
  ])
}