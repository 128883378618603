import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

export function ConfirmModalVr(props){
  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
      backdrop="static"
    >
      <Modal.Header 
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Modal.Title id="contained-modal-title-vcenter text-center">
          <label style={{textAlign: 'center', display: 'flex'}}>Confirmation</label>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.message.split("\n").map(str => <p className={'text-center'}>{str}</p>)}
      </Modal.Body>
      <Modal.Footer>
        <Row className={'col-lg-12 d-flex justify-content-center'}>
          <Col md='auto'>
            <Button onClick={props.onConfirm} style={{minWidth: '10rem'}} variant="primary">Confirm</Button>
          </Col>
          <Col md='auto'>
            <Button onClick={props.onHide} style={{minWidth: '10rem'}} variant="danger">Cancel</Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}