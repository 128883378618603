import * as actionType from './actionType';

export const eventRetrieveRequest = data => ({
  type: actionType.INVITATION.EVENT_RETRIEVE_REQUEST,
  payload: data
})
export const eventRetrieveSuccess = data => ({
  type: actionType.INVITATION.EVENT_RETRIEVE_SUCCESS,
  payload: data
})
export const eventRetrieveError = data => ({
  type: actionType.INVITATION.EVENT_RETRIEVE_ERROR,
  payload: data
})
export const eventRetrieveReset = data => ({
  type: actionType.INVITATION.EVENT_RETRIEVE_RESET,
  payload: data
})

export const setResponseRequest = data => ({
  type: actionType.INVITATION.SET_RESPONSE_REQUEST,
  payload: data
})
export const setResponseSuccess = data => ({
  type: actionType.INVITATION.SET_RESPONSE_SUCCESS,
  payload: data
})
export const setResponseError = data => ({
  type: actionType.INVITATION.SET_RESPONSE_ERROR,
  payload: data
})
export const setResponseReset = data => ({
  type: actionType.INVITATION.SET_RESPONSE_RESET,
  payload: data
})

export const checkinRequest = data => ({
  type: actionType.INVITATION.CHECKIN_REQUEST,
  payload: data
})
export const checkinSuccess = data => ({
  type: actionType.INVITATION.CHECKIN_SUCCESS,
  payload: data
})
export const checkinError = data => ({
  type: actionType.INVITATION.CHECKIN_ERROR,
  payload: data
})
export const checkinReset = data => ({
  type: actionType.INVITATION.CHECKIN_RESET,
  payload: data
})

export const participantInfoRequest = data => ({
  type: actionType.INVITATION.PARTICIPANT_INFO_REQUEST,
  payload: data
})
export const participantInfoSuccess = data => ({
  type: actionType.INVITATION.PARTICIPANT_INFO_SUCCESS,
  payload: data
})
export const participantInfoError = data => ({
  type: actionType.INVITATION.PARTICIPANT_INFO_ERROR,
  payload: data
})
export const participantInfoReset = data => ({
  type: actionType.INVITATION.PARTICIPANT_INFO_RESET,
  payload: data
})

export const registerProgrammeEmailRequest = data => ({
  type: actionType.INVITATION.REGISTER_PROGRAMME_EMAIL_REQUEST,
  payload: data
})
export const registerProgrammeEmailSuccess = data => ({
  type: actionType.INVITATION.REGISTER_PROGRAMME_EMAIL_SUCCESS,
  payload: data
})
export const registerProgrammeEmailError = data => ({
  type: actionType.INVITATION.REGISTER_PROGRAMME_EMAIL_ERROR,
  payload: data
})
export const registerProgrammeEmailReset = data => ({
  type: actionType.INVITATION.REGISTER_PROGRAMME_EMAIL_RESET,
  payload: data
})

export const cancelProgrammeEmailRequest = data => ({
  type: actionType.INVITATION.CANCEL_PROGRAMME_EMAIL_REQUEST,
  payload: data
})
export const cancelProgrammeEmailSuccess = data => ({
  type: actionType.INVITATION.CANCEL_PROGRAMME_EMAIL_SUCCESS,
  payload: data
})
export const cancelProgrammeEmailError = data => ({
  type: actionType.INVITATION.CANCEL_PROGRAMME_EMAIL_ERROR,
  payload: data
})
export const cancelProgrammeEmailReset = data => ({
  type: actionType.INVITATION.CANCEL_PROGRAMME_EMAIL_RESET,
  payload: data
})

export const assignedVrInfoRequest = data => ({
  type: actionType.INVITATION.ASSIGNEDVR_INFO_REQUEST,
  payload: data
})
export const assignedVrInfoSuccess = data => ({
  type: actionType.INVITATION.ASSIGNEDVR_INFO_SUCCESS,
  payload: data
})
export const assignedVrInfoError = data => ({
  type: actionType.INVITATION.ASSIGNEDVR_INFO_ERROR,
  payload: data
})
export const assignedVrInfoReset = data => ({
  type: actionType.INVITATION.ASSIGNEDVR_INFO_RESET,
  payload: data
})

export const registerVrEmailRequest = data => ({
  type: actionType.INVITATION.REGISTER_VR_EMAIL_REQUEST,
  payload: data
})
export const registerVrEmailSuccess = data => ({
  type: actionType.INVITATION.REGISTER_VR_EMAIL_SUCCESS,
  payload: data
})
export const registerVrEmailError = data => ({
  type: actionType.INVITATION.REGISTER_VR_EMAIL_ERROR,
  payload: data
})
export const registerVrEmailReset = data => ({
  type: actionType.INVITATION.REGISTER_VR_EMAIL_RESET,
  payload: data
})

export const cancelVrEmailRequest = data => ({
  type: actionType.INVITATION.CANCEL_VR_EMAIL_REQUEST,
  payload: data
})
export const cancelVrEmailSuccess = data => ({
  type: actionType.INVITATION.CANCEL_VR_EMAIL_SUCCESS,
  payload: data
})
export const cancelVrEmailError = data => ({
  type: actionType.INVITATION.CANCEL_VR_EMAIL_ERROR,
  payload: data
})
export const cancelVrEmailReset = data => ({
  type: actionType.INVITATION.CANCEL_VR_EMAIL_RESET,
  payload: data
})