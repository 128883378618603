import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  salutation: null,
  idtype: null,
  race: null,
  nationalities: null,
  postalcode: null,
  gender: null,
  religion: null,
  occupation: null,
  industry: null,
  education: null,
  spokenLanguage: null,
  frequencyPreferences: null,
  relationship: null,
  involvementLetter: null
}

const salutationRequest = state => ({
  ...state,
  isLoading: true
})
const salutationSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  salutation: payload
})
const salutationError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const salutationReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  salutation: null
})

const idtypeRequest = state => ({
  ...state,
  isLoading: true
})
const idtypeSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  idtype: payload
})
const idtypeError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const idtypeReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  idtype: null
})

const raceRequest = state => ({
  ...state,
  isLoading: true
})
const raceSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  race: payload
})
const raceError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const raceReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  race: null
})


const nationalitiesRequest = state => ({
  ...state,
  isLoading: true
})
const nationalitiesSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  nationalities: payload
})
const nationalitiesError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const nationalitiesReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  nationalities: null
})


const postalcodeRequest = state => ({
  ...state,
  isLoading: true
})
const postalcodeSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  postalcode: payload
})
const postalcodeError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const postalcodeReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  postalcode: null
})


const genderRequest = state => ({
  ...state,
  isLoading: true
})
const genderSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  gender: payload
})
const genderError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const genderReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  gender: null
})


const religionRequest = state => ({
  ...state,
  isLoading: true
})
const religionSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  religion: payload
})
const religionError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const religionReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  religion: null
})


const occupationRequest = state => ({
  ...state,
  isLoading: true
})
const occupationSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  occupation: payload
})
const occupationError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const occupationReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  occupation: null
})


const industryRequest = state => ({
  ...state,
  isLoading: true
})
const industrySuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  industry: payload
})
const industryError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const industryReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  industry: null
})


const educationRequest = state => ({
  ...state,
  isLoading: true
})
const educationSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  education: payload
})
const educationError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const educationReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  education: null
})


const spokenLanguageRequest = state => ({
  ...state,
  isLoading: true
})
const spokenLanguageSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  spokenLanguage: payload
})
const spokenLanguageError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const spokenLanguageReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  spokenLanguage: null
})


const frequencyPreferencesRequest = state => ({
  ...state,
  isLoading: true
})
const frequencyPreferencesSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  frequencyPreferences: payload
})
const frequencyPreferencesError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const frequencyPreferencesReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  frequencyPreferences: null
})


const relationshipRequest = state => ({
  ...state,
  isLoading: true
})
const relationshipSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  relationship: payload
})
const relationshipError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const relationshipReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  relationship: null
})


const involvementLetterRequest = state => ({
  ...state,
  isLoading: true
})
const involvementLetterSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  involvementLetter: payload
})
const involvementLetterError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const involvementLetterReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  involvementLetter: null
})


export const contactReducer = (state = initialState, action) => {
  switch (action.type ) {
    case actionType.CONTACT.SALUTATION_REQUEST:
      return salutationRequest(state, action.payload);
    case actionType.CONTACT.SALUTATION_SUCCESS:
      return salutationSuccess(state, action.payload);
    case actionType.CONTACT.SALUTATION_ERROR:
      return salutationError(state, action.payload);
    case actionType.CONTACT.SALUTATION_RESET:
      return salutationReset(state, action.payload);

    case actionType.CONTACT.IDTYPE_REQUEST:
      return idtypeRequest(state, action.payload);
    case actionType.CONTACT.IDTYPE_SUCCESS:
      return idtypeSuccess(state, action.payload);
    case actionType.CONTACT.IDTYPE_ERROR:
      return idtypeError(state, action.payload);
    case actionType.CONTACT.IDTYPE_RESET:
      return idtypeReset(state, action.payload);

    case actionType.CONTACT.RACES_REQUEST:
      return raceRequest(state, action.payload);
    case actionType.CONTACT.RACES_SUCCESS:
      return raceSuccess(state, action.payload);
    case actionType.CONTACT.RACES_ERROR:
      return raceError(state, action.payload);
    case actionType.CONTACT.RACES_RESET:
      return raceReset(state, action.payload);

    case actionType.CONTACT.NATIONALITIES_REQUEST:
      return nationalitiesRequest(state, action.payload);
    case actionType.CONTACT.NATIONALITIES_SUCCESS:
      return nationalitiesSuccess(state, action.payload);
    case actionType.CONTACT.NATIONALITIES_ERROR:
      return nationalitiesError(state, action.payload);
    case actionType.CONTACT.NATIONALITIES_RESET:
      return nationalitiesReset(state, action.payload);
    
    case actionType.CONTACT.POSTALCODE_REQUEST:
      return postalcodeRequest(state, action.payload);
    case actionType.CONTACT.POSTALCODE_SUCCESS:
      return postalcodeSuccess(state, action.payload);
    case actionType.CONTACT.POSTALCODE_ERROR:
      return postalcodeError(state, action.payload);
    case actionType.CONTACT.POSTALCODE_RESET:
      return postalcodeReset(state, action.payload);

    case actionType.CONTACT.GENDER_REQUEST:
      return genderRequest(state, action.payload);
    case actionType.CONTACT.GENDER_SUCCESS:
      return genderSuccess(state, action.payload);
    case actionType.CONTACT.GENDER_ERROR:
      return genderError(state, action.payload);
    case actionType.CONTACT.GENDER_RESET:
      return genderReset(state, action.payload);

    case actionType.CONTACT.RELIGION_REQUEST:
      return religionRequest(state, action.payload);
    case actionType.CONTACT.RELIGION_SUCCESS:
      return religionSuccess(state, action.payload);
    case actionType.CONTACT.RELIGION_ERROR:
      return religionError(state, action.payload);
    case actionType.CONTACT.RELIGION_RESET:
      return religionReset(state, action.payload);

    case actionType.CONTACT.OCCUPATION_REQUEST:
      return occupationRequest(state, action.payload);
    case actionType.CONTACT.OCCUPATION_SUCCESS:
      return occupationSuccess(state, action.payload);
    case actionType.CONTACT.OCCUPATION_ERROR:
      return occupationError(state, action.payload);
    case actionType.CONTACT.OCCUPATION_RESET:
      return occupationReset(state, action.payload);

    case actionType.CONTACT.INDUSTRY_REQUEST:
      return industryRequest(state, action.payload);
    case actionType.CONTACT.INDUSTRY_SUCCESS:
      return industrySuccess(state, action.payload);
    case actionType.CONTACT.INDUSTRY_ERROR:
      return industryError(state, action.payload);
    case actionType.CONTACT.INDUSTRY_RESET:
      return industryReset(state, action.payload);

    case actionType.CONTACT.EDUCATION_REQUEST:
      return educationRequest(state, action.payload);
    case actionType.CONTACT.EDUCATION_SUCCESS:
      return educationSuccess(state, action.payload);
    case actionType.CONTACT.EDUCATION_ERROR:
      return educationError(state, action.payload);
    case actionType.CONTACT.EDUCATION_RESET:
      return educationReset(state, action.payload);

    case actionType.CONTACT.SPOKEN_LANGUAGE_REQUEST:
      return spokenLanguageRequest(state, action.payload);
    case actionType.CONTACT.SPOKEN_LANGUAGE_SUCCESS:
      return spokenLanguageSuccess(state, action.payload);
    case actionType.CONTACT.SPOKEN_LANGUAGE_ERROR:
      return spokenLanguageError(state, action.payload);
    case actionType.CONTACT.SPOKEN_LANGUAGE_RESET:
      return spokenLanguageReset(state, action.payload);

    case actionType.CONTACT.FREQUENCY_PREFERENCES_REQUEST:
      return frequencyPreferencesRequest(state, action.payload);
    case actionType.CONTACT.FREQUENCY_PREFERENCES_SUCCESS:
      return frequencyPreferencesSuccess(state, action.payload);
    case actionType.CONTACT.FREQUENCY_PREFERENCES_ERROR:
      return frequencyPreferencesError(state, action.payload);
    case actionType.CONTACT.FREQUENCY_PREFERENCES_RESET:
      return frequencyPreferencesReset(state, action.payload);

    case actionType.CONTACT.RELATIONSHIP_REQUEST:
      return relationshipRequest(state, action.payload);
    case actionType.CONTACT.RELATIONSHIP_SUCCESS:
      return relationshipSuccess(state, action.payload);
    case actionType.CONTACT.RELATIONSHIP_ERROR:
      return relationshipError(state, action.payload);
    case actionType.CONTACT.RELATIONSHIP_RESET:
      return relationshipReset(state, action.payload);

    case actionType.CONTACT.INVOLVEMENT_LETTER_REQUEST:
      return involvementLetterRequest(state, action.payload);
    case actionType.CONTACT.INVOLVEMENT_LETTER_SUCCESS:
      return involvementLetterSuccess(state, action.payload);
    case actionType.CONTACT.INVOLVEMENT_LETTER_ERROR:
      return involvementLetterError(state, action.payload);
    case actionType.CONTACT.INVOLVEMENT_LETTER_RESET:
      return involvementLetterReset(state, action.payload);
  
    default:
      return state;
  }
}