import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as userAction from './userAction';
import * as userRepo from '../../../datasources/userRepo';

function* uploadPicture(data) {
  try {
    const res = yield call(userRepo.apiUploadPicture, data.payload);
    yield put(userAction.uploadPictureSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(userAction.uploadPictureError(err.response.data));
    } else {
      yield put(userAction.uploadPictureError(err));
    }
  }
}

function* changeProfile(data) {
  try {
    const res = yield call(userRepo.apiChangeProfile, data.payload);
    yield put(userAction.changeProfileSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(userAction.changeProfileError(err.response.data));
    } else {
      yield put(userAction.changeProfileError(err));
    }
  }
}

function* changePassword(data) {
  try {
    const res = yield call(userRepo.apiChangePassword, data.payload);
    yield put(userAction.changePasswordSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(userAction.changePasswordError(err.response.data));
    } else {
      yield put(userAction.changePasswordError(err));
    }
  }
}

export default function* watchUser() {
  yield all([
    takeLatest(actionType.USER.UPLOAD_PICTURE_REQUEST, uploadPicture),
    takeLatest(actionType.USER.CHANGE_PROFILE_REQUEST, changeProfile),
    takeLatest(actionType.USER.CHANGE_PASSWORD_REQUEST, changePassword)
  ])
}