/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import SVG from "react-inlinesvg";
import {Dropdown} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_helpers";
import {DropdownCustomToggler, DropdownMenu4} from "../../dropdowns";

export function ListsFacebookFeed({ className }) {

  useEffect(() => {
    if(window.FB?.XFBML !== undefined){
      window.FB.XFBML.parse();
    }
  }, [])
  
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Facebook Page
          </h3>
        </div>

        {/* Body */}
        <div className="card-body pt-2">
          <div className="d-flex align-items-center justify-content-center" style={{maxWidth: 500}}>
            <div
              className="fb-page"
              data-href="https://www.facebook.com/NKFsg"
              data-tabs="timeline"
              data-width="500"
              data-height=""
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
              style={{
                width: "100%"
              }}
            >
              <blockquote
                cite="https://www.facebook.com/NKFsg"
                className="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/NKFsg">NKF Singapore</a>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
