import React from "react";
import { Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

export function ImportanInfoForm() {
  const data = useSelector(
    (state) => state.vr.vrDetail?.data?.volunteerRequest
  );
  return (
    <>
      <Form>
        <Form.Group as={Row} className={"mb-0 mt-0"}>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>Important Information/Patient Background</Form.Label>
            <Form.Control
              as="textarea"
              disabled
              value={data?.Important_Information_Patient_Background__c}
            />
          </Form.Group>
        </Form.Group>
      </Form>
    </>
  );
}
