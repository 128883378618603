import * as actionType from './actionType';

export const uploadPictureRequest = data => ({
  type: actionType.USER.UPLOAD_PICTURE_REQUEST,
  payload: data
})
export const uploadPictureSuccess = data => ({
  type: actionType.USER.UPLOAD_PICTURE_SUCCESS,
  payload: data
})
export const uploadPictureError = data => ({
  type: actionType.USER.UPLOAD_PICTURE_ERROR,
  payload: data
})
export const uploadPictureReset = data => ({
  type: actionType.USER.UPLOAD_PICTURE_RESET,
  payload: data
})

export const changeProfileRequest = data => ({
  type: actionType.USER.CHANGE_PROFILE_REQUEST,
  payload: data
})
export const changeProfileSuccess = data => ({
  type: actionType.USER.CHANGE_PROFILE_SUCCESS,
  payload: data
})
export const changeProfileError = data => ({
  type: actionType.USER.CHANGE_PROFILE_ERROR,
  payload: data
})
export const changeProfileReset = data => ({
  type: actionType.USER.CHANGE_PROFILE_RESET,
  payload: data
})

export const changePasswordRequest = data => ({
  type: actionType.USER.CHANGE_PASSWORD_REQUEST,
  payload: data
})
export const changePasswordSuccess = data => ({
  type: actionType.USER.CHANGE_PASSWORD_SUCCESS,
  payload: data
})
export const changePasswordError = data => ({
  type: actionType.USER.CHANGE_PASSWORD_ERROR,
  payload: data
})
export const changePasswordReset = data => ({
  type: actionType.USER.CHANGE_PASSWORD_RESET,
  payload: data
})