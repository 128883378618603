/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import { SignupPage } from "./modules/Signup/pages/SignupPage"
import { SignupValidationPage } from "./modules/Signup/pages/SignupValidationPage"
import { SignupCompleteFormPage } from "./modules/Signup/pages/SignupCompleteFormPage";
import { SignupResultFormPage } from "./modules/Signup/pages/SignupResultFormPage"
import { InvitationEventPage } from "./modules/Invitation/pages/InvitationEventPage";
import { InvitationOrientationAttendancePage } from "./modules/Invitation/pages/InvitationOrientationAttendancePage";
import { InvitationVrPage } from "./modules/Invitation/pages/InvitationVrPage";
import { InvitationProgrammePage } from "./modules/Invitation/pages/InvitationProgrammePage";
import { SetupPasswordPage } from "./modules/Auth/pages/SetupPasswordPage";
import { TimesheetAttendanceVr } from "./modules/Timesheet/pages/AttendanceVr";
import { TimesheetAttendanceTraining } from "./modules/Timesheet/pages/AttendanceTraining";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Switch>
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      <Route path="/signup" component={SignupPage} />
      <Route path="/signup-access" component={SignupValidationPage} />
      <Route path="/signup-complete-form" component={SignupCompleteFormPage} />
      <Route path="/signup-result-form" component={SignupResultFormPage} />
      <Route path="/invitation-event" component={InvitationEventPage} />
      <Route path="/invitation-vr" component={InvitationVrPage} />
      <Route path="/invitation-programme" component={InvitationProgrammePage} />
      <Route path="/or-attendance-link" component={InvitationOrientationAttendancePage} />
      <Route path="/setup-password" component={SetupPasswordPage} />
      <Route path="/vems/timesheet" component={TimesheetAttendanceVr} />
      <Route path="/vems/timesheet-training" component={TimesheetAttendanceTraining} />
      <Route path="/reset-password" component={SetupPasswordPage} />
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}
      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
