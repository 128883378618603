import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

const OPTIONS_FREQUENCY = [
  { value: 1, label: "One Time"},
  { value: 2, label: "Weekly"},
  { value: 3, label: "Fornightly"},
  { value: 4, label: "Monthly"}
]

const OPTIONS_ENGAGEMENT = [
  { value: 1, label: "Direct"},
  { value: 2, label: "Indirect"},
]

const OPTIONS_STATUS = [
  { value: 1, label: "Approved"},
  { value: 2, label: "Rejected"}
]

export function ProgrammeEventsFilter({searchName, setSearchName}){
  return(
    <>
      <div>
        <Form>
          <Form.Group as={Row} className={'px-5 mb-0 mt-5'}>
            <Form.Group className={'col-lg-4 mx-0'}>
              <Form.Control 
                type="text" 
                placeholder="Search by Name" 
                onChange={(e)=>setSearchName(e.target.value)}
                value={searchName}
              />
            </Form.Group>
            {/* <Form.Group className={'col-lg-2 mx-0'}>
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Frequency"
                options={OPTIONS_FREQUENCY}
                isClearable={true}
              />
            </Form.Group>
            <Form.Group className={'col-lg-2 mx-0'}>
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Engagement"
                options={OPTIONS_ENGAGEMENT}
                isClearable={true}
              />
            </Form.Group>
            <Form.Group className={'col-lg-2 mx-0'}>
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Status"
                options={OPTIONS_STATUS}
                isClearable={true}
              />
            </Form.Group> */}
          </Form.Group>
        </Form>
      </div>
    </>
  )
}