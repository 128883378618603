import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter
} from "../../../../_metronic/_partials/controls";
import { Button, Form } from 'react-bootstrap';
import { VolunteerRequestTable } from './VolunteerRequestTable';
import { VolunteerRequestFilter } from './VolunteerRequestFilter';

export function VolunteerRequestCard({UIEvent}){
  const [searchName, setSearchName] = useState('');
  return(
    <>
      <Card>
        <CardHeader>
          <div className="card-title">
            <h3 className="card-label" style={{minWidth: '9vw'}}>List Data</h3>
          </div>
        </CardHeader>
        <VolunteerRequestFilter searchName={searchName} setSearchName={(val)=>setSearchName(val)}/>
        <CardBody>
          <VolunteerRequestTable UIEvent={UIEvent} searchName={searchName}/>
        </CardBody>
      </Card>
    </>
  )
}