import React, { useContext, useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useFormContext } from "react-hook-form";
import ApplicationFormContext from "../context/ApplicationFormContext";
const { FormContext } = ApplicationFormContext;

export function SignupConfidentialityForm() {
  const formContext = useContext(FormContext);
  const { watch } = useFormContext();

  useEffect(() => {
    if(watch('step') > 3){
      formContext.handleChangeField('agreement', 'isAgreeConfidentiality', true);
    }
  }, [watch('step')])
  
  return (
    <>
      <>
        <Form.Group as={Row} className={'col-lg-6 mx-auto mb-1'} controlId="formBasicChecbox" style={{marginTop: 10}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formContext.form.agreement.isAgreeConfidentiality} 
                onChange={(event)=>formContext.handleChangeField('agreement', 'isAgreeConfidentiality', event.target.checked)}
                style={{
                  transform: "scale(1.2)"
                }}
              />
            }
            label="I have read and fully understood, accepted and will adhere to the various measures and policies" 
          />
        </Form.Group>
      </>
    </>
  );
}
