import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { useSelector } from "react-redux";

export function AdditionalInfoForm(){
  const data = useSelector(state => state.vr.vrDetail?.data?.volunteerRequest);
  function createQR(){
    return {
      __html: data?.QR_Code__c
    }
  }
  return(
    <>
      <Form.Group as={Row} className={'mb-0 mt-0'}>
        <Form.Group className={'col-lg-6'}>
          <Form.Label>QR Code</Form.Label>
          <br/>
          {
            <div dangerouslySetInnerHTML={createQR()}/>
          }
        </Form.Group>
      </Form.Group>
    </>
  )
}