import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { useSelector } from "react-redux";

export function ClientResidentialAreaForm(){
  const data = useSelector(state => state.vr.vrDetail?.data?.volunteerRequest);
  return(
    <>
      <Form>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Street Name</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Mailing_Street__c} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Postal Code</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Mailing_Zip_Postal_Code__c} 
            />
          </Form.Group>
        </Form.Group>
      </Form>
    </>
  )
}