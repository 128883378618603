import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isLoadingVerificationCode: false,
  isError: false,
  isErrorVerificationCode: false,
  errorMsg: null,
  setupPassword: null,
  setupPasswordToken: null,
  loginInfo: null,
  faInfo: null,
  user: undefined,
  authToken: undefined,
  forgotPassword: null
}

const setTokenSetupPassword = (state, payload) => ({
  ...state,
  setupPasswordToken: payload
})
const setCredential = (state, payload) => ({
  ...state,
  authToken: payload?.data?.accessCode,
  user: payload?.data?.contact
})
const setChangeProfile = (state, payload) => ({
  ...state,
  user: payload.data
})

const setupPasswordRequest = state => ({
  ...state,
  isLoading: true
})
const setupPasswordSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  setupPassword: payload
})
const setupPasswordError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const setupPasswordReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  setupPassword: null
})

const loginRequest = state => ({
  ...state,
  isLoading: true
})
const loginSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  loginInfo: payload
})
const loginError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const loginReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  loginInfo: null,
  user: null,
  authToken: null
})

const faRequest = state => ({
  ...state,
  isLoadingVerificationCode: true
})
const faSuccess = (state, payload) => ({
  ...state,
  isLoadingVerificationCode: false,
  isErrorVerificationCode: false,
  errorMsg: null,
  faInfo: payload
})
const faError = (state, payload) => ({
  ...state,
  isLoadingVerificationCode: false,
  isErrorVerificationCode: true,
  errorMsg: payload
})
const faReset = state => ({
  ...state,
  isLoadingVerificationCode: false,
  isErrorVerificationCode: false,
  errorMsg: null,
  faInfo: null
})

const forgotRequest = state => ({
  ...state,
  isLoading: true
})
const forgotSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  forgotPassword: payload
})
const forgotError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const forgotReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  forgotPassword: null
})


export const authReducer = persistReducer(
  { storage, key: "nkf-auth", whitelist: ["user", "authToken"] },
  (state = initialState, action) => {
  switch (action.type ) {
    case actionType.AUTH.SET_TOKEN_SETUP_PASSWORD:
      return setTokenSetupPassword(state, action.payload);
    case actionType.AUTH.SET_CREDENTIAL:
      return setCredential(state, action.payload);
    case actionType.AUTH.SET_CHANGE_PROFILE:
      return setChangeProfile(state, action.payload);

    case actionType.AUTH.SETUP_PASSWORD_REQUEST:
      return setupPasswordRequest(state, action.payload);
    case actionType.AUTH.SETUP_PASSWORD_SUCCESS:
      return setupPasswordSuccess(state, action.payload);
    case actionType.AUTH.SETUP_PASSWORD_ERROR:
      return setupPasswordError(state, action.payload);
    case actionType.AUTH.SETUP_PASSWORD_RESET:
      return setupPasswordReset(state, action.payload);

    case actionType.AUTH.LOGIN_REQUEST:
      return loginRequest(state, action.payload);
    case actionType.AUTH.LOGIN_SUCCESS:
      return loginSuccess(state, action.payload);
    case actionType.AUTH.LOGIN_ERROR:
      return loginError(state, action.payload);
    case actionType.AUTH.LOGIN_RESET:
      return loginReset(state, action.payload);

    case actionType.AUTH.FA_REQUEST:
      return faRequest(state, action.payload);
    case actionType.AUTH.FA_SUCCESS:
      return faSuccess(state, action.payload);
    case actionType.AUTH.FA_ERROR:
      return faError(state, action.payload);
    case actionType.AUTH.FA_RESET:
      return faReset(state, action.payload);

    case actionType.AUTH.FORGOT_REQUEST:
      return forgotRequest(state, action.payload);
    case actionType.AUTH.FORGOT_SUCCESS:
      return forgotSuccess(state, action.payload);
    case actionType.AUTH.FORGOT_ERROR:
      return forgotError(state, action.payload);
    case actionType.AUTH.FORGOT_RESET:
      return forgotReset(state, action.payload);
  
    default:
      return state;
  }
});