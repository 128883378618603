export const VOLUNTEER_REQUEST = {
  TABLE_REQUEST: "VOLUNTEER_REQUEST_TABLE_REQUEST",
  TABLE_SUCCESS: "VOLUNTEER_REQUEST_TABLE_SUCCESS",
  TABLE_ERROR: "VOLUNTEER_REQUEST_TABLE_ERROR",
  TABLE_RESET: "VOLUNTEER_REQUEST_TABLE_RESET",

  DETAIL_REQUEST: "VOLUNTEER_REQUEST_DETAIL_REQUEST",
  DETAIL_SUCCESS: "VOLUNTEER_REQUEST_DETAIL_SUCCESS",
  DETAIL_ERROR: "VOLUNTEER_REQUEST_DETAIL_ERROR",
  DETAIL_RESET: "VOLUNTEER_REQUEST_DETAIL_RESET",

  REGISTER_REQUEST: "VOLUNTEER_REQUEST_REGISTER_REQUEST",
  REGISTER_SUCCESS: "VOLUNTEER_REQUEST_REGISTER_SUCCESS",
  REGISTER_ERROR: "VOLUNTEER_REQUEST_REGISTER_ERROR",
  REGISTER_RESET: "VOLUNTEER_REQUEST_REGISTER_RESET",

  CANCEL_REQUEST: "VOLUNTEER_REQUEST_CANCEL_REQUEST",
  CANCEL_SUCCESS: "VOLUNTEER_REQUEST_CANCEL_SUCCESS",
  CANCEL_ERROR: "VOLUNTEER_REQUEST_CANCEL_ERROR",
  CANCEL_RESET: "VOLUNTEER_REQUEST_CANCEL_RESET",
}