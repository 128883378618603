import { methodService, apiService } from './apiService';

export const URL = {
  BASE_CONTACT: '/contacts',
}

export function apiUploadPicture(data) {
  return apiService(
    URL.BASE_CONTACT+'/profilePicture',
    methodService.PATCH,
    data,
    null,
    true
  );
}

export function apiChangeProfile(data) {
  return apiService(
    URL.BASE_CONTACT+'/updateProfile',
    methodService.PATCH,
    data,
    null
  );
}

export function apiChangePassword(data) {
  return apiService(
    URL.BASE_CONTACT+'/changePassword',
    methodService.PATCH,
    data,
    null
  );
}