import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CalculateAge } from '../../../../helper/Service';
import ApplicationFormContext from "../../context/ApplicationFormContext";
import { useFormContext } from "react-hook-form";
const { FormContext: AppFormC } = ApplicationFormContext;

export function SignupFooterStep({handleBack, handleNext, step, setStepLabel}){
  const appFormContext = useContext(AppFormC);
  const signupReducer = useSelector(state => state.signup);
  const { handleSubmit, watch } = useFormContext();

  useEffect(() => {
    if(step === 1){
      if(isIndemnity()){
        setStepLabel(['Application Form', 'Volunteer Preferences', 'Indemnity Form', 'Confidentiality', 'Volunteer Agreement', 'Volunteer Guidelines', 'Summary'])
      } else {
        setStepLabel(['Application Form', 'Volunteer Preferences', 'Indemnity Form (N/A)', 'Confidentiality', 'Volunteer Agreement', 'Volunteer Guidelines', 'Summary'])
      }
    }
  }, [step])

  function isIndemnity(){
    if(CalculateAge(new Date(watch('dob'))) >= 16 && 
      CalculateAge(new Date(watch('dob'))) <= 21){
        return true
    } else {
      return false;
    }
  }

  return(
    <>
        {
          step > 0 ?
          <button
            type="button"
            onClick={handleBack}
            className="btn btn-light btn-sm"
            style={{width: '13rem'}}
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          :
          <div style={{width: '13rem'}}/>
        }
        {`  `}
        {
          step < 6 ?
          <button 
            className="btn btn-primary btn-lg float-right ml-5" 
            style={{width: '13rem'}}
            onClick={handleSubmit(handleNext)}
            disabled={
              signupReducer.isLoadingForm ? true :
              step === 0 ? !appFormContext.form.agreement.isAgreeApplication : 
              step === 1 ? false : 
              step === 2 ? 
                isIndemnity() ? !appFormContext.form.agreement.isAgreeIndemnity || !appFormContext.form.indemnity.isDownload :
                false :
              step === 3 ? !appFormContext.form.agreement.isAgreeConfidentiality :
              step === 4 ? !appFormContext.form.agreement.isAgreeAgreement :
              step === 5 ? !appFormContext.form.agreement.isAgreeGuidelines :
              true
            }
          >
            <i className="fa fa-arrow-right"></i>
            Next
            {
              signupReducer.isLoadingForm &&
              <span className="ml-3 spinner spinner-white"></span>
            }
          </button>
          :
          <div style={{width: '13rem'}}/>
        }
    </>
  )
}