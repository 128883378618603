import { all } from "redux-saga/effects";
import watchAuth from "../app/modules/Auth/redux/authSaga";
import watchAttendance from "../app/modules/Attendance/redux/attendanceSaga";
import watchCalendar from "../app/modules/Calendar/redux/calendarSaga";
import watchContact from "../app/modules/Contact/redux/contactSaga";
import watchDashboard from "../app/modules/Dashboard/redux/dashboardSaga";
import watchInvitation from "../app/modules/Invitation/redux/invitationSaga";
import watchPE from "../app/modules/ProgrammeEvents/redux/programmeEventsSaga";
import watchSignup from "../app/modules/Signup/redux/signupSaga";
import watchTimesheet from "../app/modules/Timesheet/redux/timesheetSaga";
import watchUser from "../app/modules/UserProfile/redux/userSaga";
import watchVR from "../app/modules/VolunteerRequest/redux/volunteerRequestSaga";

export function* rootSaga(){
  yield all([
    watchAuth(),
    watchAttendance(),
    watchCalendar(),
    watchContact(),
    watchDashboard(),
    watchInvitation(),
    watchPE(),
    watchSignup(),
    watchTimesheet(),
    watchUser(),
    watchVR()
  ]);
}