import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as signupAction from './signupAction';
import * as signupRepo from '../../../datasources/signupRepo';

function* signupVerification(data) {
  try {
    const res = yield call(signupRepo.apiSignupVerification, data.payload);
    yield put(signupAction.verificationSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(signupAction.verificationError(err.response.data));
    } else {
      yield put(signupAction.verificationError(err));
    }
  }
}

function* signupVerificationAdhoc(data) {
  try {
    const res = yield call(signupRepo.apiSignupVerificationAdhoc, data.payload);
    yield put(signupAction.verificationAdhocSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(signupAction.verificationAdhocError(err.response.data));
    } else {
      yield put(signupAction.verificationAdhocError(err));
    }
  }
}

function* signupContactRetrieve() {
  try {
    const res = yield call(signupRepo.apiSignupContactRetrieve);
    yield put(signupAction.contactRetrieveSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(signupAction.contactRetrieveError(err.response.data));
    } else {
      yield put(signupAction.contactRetrieveError(err));
    }
  }
}

function* signupCompleteForm(data) {
  try {
    const res = yield call(signupRepo.apiSignupForm, data.payload);
    yield put(signupAction.formSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(signupAction.formError(err.response.data));
    } else {
      yield put(signupAction.formError(err));
    }
  }
}

function* signupVerificationCode() {
  try {
    const res = yield call(signupRepo.apiSignupVerificationCode);
    yield put(signupAction.verificationCodeSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(signupAction.verificationCodeError(err.response.data));
    } else {
      yield put(signupAction.verificationCodeError(err));
    }
  }
}

function* signupVerificationCodeForm(data) {
  try {
    const res = yield call(signupRepo.apiSignupVerificationCodeForm, data.payload);
    yield put(signupAction.verificationCodeFormSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(signupAction.verificationCodeFormError(err.response.data));
    } else {
      yield put(signupAction.verificationCodeFormError(err));
    }
  }
}

export default function* watchSignup() {
  yield all([
    takeLatest(actionType.SIGNUP.VERIFICATION_REQUEST, signupVerification),
    takeLatest(actionType.SIGNUP.VERIFICATION_ADHOC_REQUEST, signupVerificationAdhoc),
    takeLatest(actionType.SIGNUP.CONTACT_RETRIEVE_REQUEST, signupContactRetrieve),
    takeLatest(actionType.SIGNUP.FORM_REQUEST, signupCompleteForm),
    takeLatest(actionType.SIGNUP.VERIFICATION_CODE_REQUEST, signupVerificationCode),
    takeLatest(actionType.SIGNUP.VERIFICATION_CODE_FORM_REQUEST, signupVerificationCodeForm)
  ])
}