import React, { useState, createContext } from 'react';

function ApplicationFormContext(){
  const FormContext = createContext();

  const ApplicationFormProvider = (props) => {
    const [form, setForm] = useState({
      application: {
        salutation: '',
        fullname: '',
        id_type: '',
        id_number: '',
        dob: '',
        gender: '',
        nationality: '',
        nationality_other: '',
        race: '',
        education: '',
        occupation: '',
        otherOccupation: '',
        industry: '',
        spokenLanguage: null,
        email: '',
        home_phone: '',
        mobile_phone: '',
        postal_code: '',
        unit_number: '',
        address: '',
        isDisablity: null,
        isLongTermIllness: null,
        isCriminalOffence: null,
        specifyDisability: '',
        specifyIllness: '',
        specifyCriminal: '',
        emergency_name: '',
        emergency_number: '',
        emergency_relationship: '',
      },
      preferences: {
        preferred: {
          isAdministrative: false,
          isBfriending: false,
          isEnrichment: false,
          isEvent: false,
          isHope: false,
          isHomeAid: false,
          isGuide: false,
          isOfficer: false,
          isEscort: false,
          isTranslation: false,
          isProfessional: false
        },
        frequency: '',
        sunday: {
          morning: false,
          afternoon: false,
          evening: false
        },
        monday: {
          morning: false,
          afternoon: false,
          evening: false
        },
        tuesday: {
          morning: false,
          afternoon: false,
          evening: false
        },
        wednesday: {
          morning: false,
          afternoon: false,
          evening: false
        },
        thursday: {
          morning: false,
          afternoon: false,
          evening: false
        },
        friday: {
          morning: false,
          afternoon: false,
          evening: false
        },
        saturday: {
          morning: false,
          afternoon: false,
          evening: false
        },
        remarks: ''
      },
      indemnity: {
        parent_name: '',
        parent_nric: '',
        parent_contact: '',
        isDownload: false
      },
      agreement: {
        isAgreeApplication: false,
        isAgreeIndemnity: false,
        isAgreeConfidentiality: false,
        isAgreeAgreement: false,
        isAgreeGuidelines: false
      }
    });

    function handleChangeField(tab, field, value){
      setForm((prevState)=>{
        if(field.includes('[') && field.includes(']')){
          let newField = field.substr(0, field.indexOf('['))
          let nestedField = field.substring(newField.length).replace('[','').replace(']','');
          prevState[tab][newField][nestedField] = value;
        } else {
          prevState[tab][field] = value;
        }
        return({
          ...prevState
        })
      })
    }

    function handleChangeSelect(field, event){
      setForm({...form, [field]: event.value});
    }

    return(
      <FormContext.Provider value={{form, setForm, handleChangeField, handleChangeSelect}}>
        {props.children}
      </FormContext.Provider>
    )
  }

  return {
    ApplicationFormProvider,
    FormContext
  }
}

export default ApplicationFormContext();