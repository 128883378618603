import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as authAction from "../redux/authAction";

export default function Logout(){
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.auth);
  const hasAuthToken = Boolean(authReducer.authToken);
  
  useEffect(() => {
    dispatch(authAction.loginReset());
  }, [])

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
}
