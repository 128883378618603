import React from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

export function ModalInfoHelp({ show, onHide }) {
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Body>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span className="text-center">
            If you encounter any difficulty accessing the self-help portal,
            please do not hesitate to contact us at:
          </span>
          <a href="mailto:volunteer@nkfs.org">
            <span>volunteer@nkfs.org</span>
          </a>
          <span>Monday to Friday - 9.00am to 5.00pm</span>
        </div>
        <Form.Group as={Row} className="mt-5 mb-0">
          <Col md="auto" sm={12} xs={12} className={"mx-auto"}>
            <Button
              style={{ minWidth: "10rem" }}
              variant="primary"
              onClick={onHide}
            >
              OK
            </Button>
          </Col>
        </Form.Group>
      </Modal.Body>
    </Modal>
  );
}
