/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/calendar-events", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/calendar-events">
            <span className="svg-icon menu-icon">
              <i className="menu-icon flaticon-event-calendar-symbol"></i>
            </span>
            <span className="menu-text">Calendar</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/programme-events", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/programme-events">
            <span className="svg-icon menu-icon">
              <i className="menu-icon flaticon-customer"></i>
            </span>
            <span className="menu-text">Upcoming Programme/Event</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/volunteer-request", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/volunteer-request">
            <span className="svg-icon menu-icon">
              <i className="menu-icon flaticon-presentation"></i>
            </span>
            <span className="menu-text">Volunteer Opportunities</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/attendance", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/attendance">
            <span className="svg-icon menu-icon">
              <i className="menu-icon flaticon-time"></i>
            </span>
            <span className="menu-text">Attendance</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Applications</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">User Profile</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/community-letter", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/community-letter">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Devices/Printer.svg"
                )}
              />
            </span>
            <span className="menu-text">Print Community Involvement Letter</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
