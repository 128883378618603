import React, { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { SignupModalVerificationCode } from '../../modal/SignupModalVerificationCode';

export function SummaryFormStep(){
  const [showModal, setShowModal] = useState(false);
  return(
    <>
      <Container style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Row>
          <label >You have reached the last step of the sign up process and agreed to the Terms and Conditions listed in the documents below:</label>
        </Row>
        <Row>
          <ol type={'1'}>
            <li>
              Application Form
            </li>
            <li>
              Indemnity Form (Applicable for individuals under 21 years old)
            </li>
            <li>
              Confidentiality 
            </li>
            <li>
              Volunteer Agreement
            </li>
            <li>
              Volunteer Guidelines
            </li>
          </ol>
        </Row>
        <label>Please click “Send Verification Code” below to receive the code for final agreement.</label>
        <Row>
          <button 
            type="button" 
            className="btn btn-primary btn-sm ml-5" 
            style={{width: '10rem'}}
            onClick={()=>setShowModal(true)}
          >
            Send Verification Code
          </button>
        </Row>
        <SignupModalVerificationCode 
          show={showModal}
          onHide={()=>setShowModal(false)}
        />
      </Container>
    </>
  )
}