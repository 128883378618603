import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as calendarAction from './calendarAction';
import * as calendarRepo from '../../../datasources/calendarRepo';

function* getEventRegistered(){
  try {
    const res = yield call(calendarRepo.apiEventRegistered);
    yield put(calendarAction.eventRegisteredSuccess(res));
  } catch(err){
    yield put(calendarAction.eventRegisteredError(err));
  }
}

function* getPeRegistered(){
  try {
    const res = yield call(calendarRepo.apiPeRegistered);
    yield put(calendarAction.peRegisteredSuccess(res));
  } catch(err){
    yield put(calendarAction.peRegisteredError(err));
  }
}
export default function* watchCalendar() {
  yield all([
    takeLatest(actionType.CALENDAR.EVENT_REGISTERED_REQUEST, getEventRegistered),
    takeLatest(actionType.CALENDAR.PE_REGISTERED_REQUEST, getPeRegistered)
  ])
}