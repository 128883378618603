import * as actionType from './actionType';

export const detailVrRequest = data => ({
  type: actionType.ATTENDANCE.DETAIL_VR_REQUEST,
  payload: data
})
export const detailVrSuccess = data => ({
  type: actionType.ATTENDANCE.DETAIL_VR_SUCCESS,
  payload: data
})
export const detailVrError = data => ({
  type: actionType.ATTENDANCE.DETAIL_VR_ERROR,
  payload: data
})
export const detailVrReset = data => ({
  type: actionType.ATTENDANCE.DETAIL_VR_RESET,
  payload: data
})

export const validateVrRequest = data => ({
  type: actionType.ATTENDANCE.VALIDATE_VR_REQUEST,
  payload: data
})
export const validateVrSuccess = data => ({
  type: actionType.ATTENDANCE.VALIDATE_VR_SUCCESS,
  payload: data
})
export const validateVrError = data => ({
  type: actionType.ATTENDANCE.VALIDATE_VR_ERROR,
  payload: data
})
export const validateVrReset = data => ({
  type: actionType.ATTENDANCE.VALIDATE_VR_RESET,
  payload: data
})

export const checkinVrRequest = data => ({
  type: actionType.ATTENDANCE.CHECKIN_VR_REQUEST,
  payload: data
})
export const checkinVrSuccess = data => ({
  type: actionType.ATTENDANCE.CHECKIN_VR_SUCCESS,
  payload: data
})
export const checkinVrError = data => ({
  type: actionType.ATTENDANCE.CHECKIN_VR_ERROR,
  payload: data
})
export const checkinVrReset = data => ({
  type: actionType.ATTENDANCE.CHECKIN_VR_RESET,
  payload: data
})

export const checkoutVrRequest = data => ({
  type: actionType.ATTENDANCE.CHECKOUT_VR_REQUEST,
  payload: data
})
export const checkoutVrSuccess = data => ({
  type: actionType.ATTENDANCE.CHECKOUT_VR_SUCCESS,
  payload: data
})
export const checkoutVrError = data => ({
  type: actionType.ATTENDANCE.CHECKOUT_VR_ERROR,
  payload: data
})
export const checkoutVrReset = data => ({
  type: actionType.ATTENDANCE.CHECKOUT_VR_RESET,
  payload: data
})

export const detailTrainingRequest = data => ({
  type: actionType.ATTENDANCE.DETAIL_TRAINING_REQUEST,
  payload: data
})
export const detailTrainingSuccess = data => ({
  type: actionType.ATTENDANCE.DETAIL_TRAINING_SUCCESS,
  payload: data
})
export const detailTrainingError = data => ({
  type: actionType.ATTENDANCE.DETAIL_TRAINING_ERROR,
  payload: data
})
export const detailTrainingReset = data => ({
  type: actionType.ATTENDANCE.DETAIL_TRAINING_RESET,
  payload: data
})

export const validateTrainingRequest = data => ({
  type: actionType.ATTENDANCE.VALIDATE_TRAINING_REQUEST,
  payload: data
})
export const validateTrainingSuccess = data => ({
  type: actionType.ATTENDANCE.VALIDATE_TRAINING_SUCCESS,
  payload: data
})
export const validateTrainingError = data => ({
  type: actionType.ATTENDANCE.VALIDATE_TRAINING_ERROR,
  payload: data
})
export const validateTrainingReset = data => ({
  type: actionType.ATTENDANCE.VALIDATE_TRAINING_RESET,
  payload: data
})

export const checkinTrainingRequest = data => ({
  type: actionType.ATTENDANCE.CHECKIN_TRAINING_REQUEST,
  payload: data
})
export const checkinTrainingSuccess = data => ({
  type: actionType.ATTENDANCE.CHECKIN_TRAINING_SUCCESS,
  payload: data
})
export const checkinTrainingError = data => ({
  type: actionType.ATTENDANCE.CHECKIN_TRAINING_ERROR,
  payload: data
})
export const checkinTrainingReset = data => ({
  type: actionType.ATTENDANCE.CHECKIN_TRAINING_RESET,
  payload: data
})

export const checkoutTrainingRequest = data => ({
  type: actionType.ATTENDANCE.CHECKOUT_TRAINING_REQUEST,
  payload: data
})
export const checkoutTrainingSuccess = data => ({
  type: actionType.ATTENDANCE.CHECKOUT_TRAINING_SUCCESS,
  payload: data
})
export const checkoutTrainingError = data => ({
  type: actionType.ATTENDANCE.CHECKOUT_TRAINING_ERROR,
  payload: data
})
export const checkoutTrainingReset = data => ({
  type: actionType.ATTENDANCE.CHECKOUT_TRAINING_RESET,
  payload: data
})