export const PROGRAMME_EVENTS = {
  TABLE_REQUEST: "PROGRAMME_EVENTS_TABLE_REQUEST",
  TABLE_SUCCESS: "PROGRAMME_EVENTS_TABLE_SUCCESS",
  TABLE_ERROR: "PROGRAMME_EVENTS_TABLE_ERROR",
  TABLE_RESET: "PROGRAMME_EVENTS_TABLE_RESET",

  DETAIL_REQUEST: "PROGRAMME_EVENTS_DETAIL_REQUEST",
  DETAIL_SUCCESS: "PROGRAMME_EVENTS_DETAIL_SUCCESS",
  DETAIL_ERROR: "PROGRAMME_EVENTS_DETAIL_ERROR",
  DETAIL_RESET: "PROGRAMME_EVENTS_DETAIL_RESET",

  REGISTER_REQUEST: "PROGRAMME_EVENTS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "PROGRAMME_EVENTS_REGISTER_SUCCESS",
  REGISTER_ERROR: "PROGRAMME_EVENTS_REGISTER_ERROR",
  REGISTER_RESET: "PROGRAMME_EVENTS_REGISTER_RESET",

  CANCEL_REQUEST: "PROGRAMME_EVENTS_CANCEL_REQUEST",
  CANCEL_SUCCESS: "PROGRAMME_EVENTS_CANCEL_SUCCESS",
  CANCEL_ERROR: "PROGRAMME_EVENTS_CANCEL_ERROR",
  CANCEL_RESET: "PROGRAMME_EVENTS_CANCEL_RESET",
}