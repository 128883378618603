import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

export function SignupModalNRIC(props){
  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
      backdrop="static"
    >
      <Modal.Header 
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Modal.Title id="contained-modal-title-vcenter text-center">
          <label style={{textAlign: 'center', display: 'flex'}}>Alert</label>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={'text-center'}>
          {/* We need your ID number in order for us to provide you with insurance coverage */}
          We noticed that you did not fill up your NRIC/FIN no. You are strongly encouraged to provide us with your NRIC/FIN no. should you wish to be covered by NKF’s general insurance. Otherwise, you may proceed to submit this form. 
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'col-lg-12 d-flex justify-content-center'}>
          <Col md='auto'>
            <Button onClick={props.onConfirm} style={{minWidth: '10rem'}} variant="primary">OK</Button>
          </Col>
          <Col md='auto'>
            <Button onClick={props.onHide} style={{minWidth: '10rem'}} variant="danger">Cancel</Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}