import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as invitationAction from './invitationAction';
import * as invitationRepo from '../../../datasources/invitationRepo';

function* eventRetrieve(data){
  try {
    const res = yield call(invitationRepo.apiInvitationEventRetrieve, data.payload);
    yield put(invitationAction.eventRetrieveSuccess(res));
  } catch(err) {
    yield put(invitationAction.eventRetrieveError(err));
  }
}

function* setResponse(data){
  try {
    const res = yield call(invitationRepo.apiInvitationSetResponse, data.payload);
    yield put(invitationAction.setResponseSuccess(res));
  } catch(err) {
    yield put(invitationAction.setResponseError(err));
  }
}

function* orientationCheckin(data){
  try {
    const res = yield call(invitationRepo.apiInvitationCheckin, data.payload);
    yield put(invitationAction.checkinSuccess(res));
  } catch(err) {
    if(err.response?.data){
      yield put(invitationAction.checkinError(err.response.data));
    } else {
      yield put(invitationAction.checkinError(err));
    }
  }
}

function* participantInfo(data){
  try {
    const res = yield call(invitationRepo.apiParticipantInfo, data.payload);
    yield put(invitationAction.participantInfoSuccess(res));
  } catch(err) {
    if(err.response?.data){
      yield put(invitationAction.participantInfoError(err.response.data));
    } else {
      yield put(invitationAction.participantInfoError(err));
    }
  }
}

function* registerProgrammeEmail(data){
  try {
    const res = yield call(invitationRepo.apiRegisterProgrammeEmail, data.payload);
    yield put(invitationAction.registerProgrammeEmailSuccess(res));
  } catch(err) {
    if(err.response?.data){
      yield put(invitationAction.registerProgrammeEmailError(err.response.data));
    } else {
      yield put(invitationAction.registerProgrammeEmailError(err));
    }
  }
}

function* cancelProgrammeEmail(data){
  try {
    const res = yield call(invitationRepo.apiCancelProgrammeEmail, data.payload);
    yield put(invitationAction.cancelProgrammeEmailSuccess(res));
  } catch(err) {
    if(err.response?.data){
      yield put(invitationAction.cancelProgrammeEmailError(err.response.data));
    } else {
      yield put(invitationAction.cancelProgrammeEmailError(err));
    }
  }
}

function* assignedVrInfo(data){
  try {
    const res = yield call(invitationRepo.apiAssignedVrInfo, data.payload);
    yield put(invitationAction.assignedVrInfoSuccess(res));
  } catch(err) {
    if(err.response?.data){
      yield put(invitationAction.assignedVrInfoError(err.response.data));
    } else {
      yield put(invitationAction.assignedVrInfoError(err));
    }
  }
}

function* registerVrEmail(data){
  try {
    const res = yield call(invitationRepo.apiRegisterVrEmail, data.payload);
    yield put(invitationAction.registerVrEmailSuccess(res));
  } catch(err) {
    if(err.response?.data){
      yield put(invitationAction.registerVrEmailError(err.response.data));
    } else {
      yield put(invitationAction.registerVrEmailError(err));
    }
  }
}

function* cancelVrEmail(data){
  try {
    const res = yield call(invitationRepo.apiCancelVrEmail, data.payload);
    yield put(invitationAction.cancelVrEmailSuccess(res));
  } catch(err) {
    if(err.response?.data){
      yield put(invitationAction.cancelVrEmailError(err.response.data));
    } else {
      yield put(invitationAction.cancelVrEmailError(err));
    }
  }
}

export default function* watchInvitation() {
  yield all([
    takeLatest(actionType.INVITATION.EVENT_RETRIEVE_REQUEST, eventRetrieve),
    takeLatest(actionType.INVITATION.SET_RESPONSE_REQUEST, setResponse),
    takeLatest(actionType.INVITATION.CHECKIN_REQUEST, orientationCheckin),
    takeLatest(actionType.INVITATION.PARTICIPANT_INFO_REQUEST, participantInfo),
    takeLatest(actionType.INVITATION.REGISTER_PROGRAMME_EMAIL_REQUEST, registerProgrammeEmail),
    takeLatest(actionType.INVITATION.CANCEL_PROGRAMME_EMAIL_REQUEST, cancelProgrammeEmail),
    takeLatest(actionType.INVITATION.ASSIGNEDVR_INFO_REQUEST, assignedVrInfo),
    takeLatest(actionType.INVITATION.REGISTER_VR_EMAIL_REQUEST, registerVrEmail),
    takeLatest(actionType.INVITATION.CANCEL_VR_EMAIL_REQUEST, cancelVrEmail)
  ])
}