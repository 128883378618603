import React from "react";
import { Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

export function BasicInfoForm() {
  const dataVr = useSelector(
    (state) => state.vr.vrDetail?.data?.volunteerRequest
  );
  const dataAssignee = useSelector(
    (state) => state.vr.vrDetail?.data?.assignedVolunteer
  );
  return (
    <>
      <Form>
        <Form.Group as={Row} className={"mb-0 mt-0"}>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>Assignee Status</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={
                Object.keys(dataAssignee).length === 0
                  ? "Waiting for Response"
                  : dataAssignee?.Assignee_Status__c
              }
            />
          </Form.Group>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>VR No</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={dataVr?.Volunteer_Request_No__c}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={"mb-0 mt-0"}>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>Name of Activity</Form.Label>
            <Form.Control type="text" disabled value={dataVr?.Name} />
          </Form.Group>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>Request Date</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={moment(dataVr?.Request_Date__c).format("DD-MM-YYYY")}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={"mb-0 mt-0"}>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={moment(dataVr?.Start_Date__c).format("DD-MM-YYYY")}
            />
          </Form.Group>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={moment(dataVr?.End_Date__c).format("DD-MM-YYYY")}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={"mb-0 mt-0"}>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>Start Time</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={dataVr?.Start_Time__c?.substring(0, 5)}
            />
          </Form.Group>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>End Time</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={dataVr?.End_Time__c?.substring(0, 5)}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={"mb-0 mt-0"}>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>Deadline for Registration</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={moment(dataVr?.Recruitment_Deadline__c).format(
                "DD-MM-YYYY"
              )}
            />
          </Form.Group>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>Frequency Type</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={dataVr?.Frequency_Type__c}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={"mb-0 mt-0"}>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>Meal Provision</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={dataVr?.Meal_Provision__c}
            />
          </Form.Group>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>Types of Meal Availability</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={dataVr?.Types_of_Meal_Availability__c}
            />
          </Form.Group>
        </Form.Group>
        {dataAssignee?.Assignee_Status__c === "Accepted" &&
          dataVr?.Engagement_Type_F__c === "Direct" && (
            <Form.Group as={Row} className={"mb-0 mt-0"}>
              <Form.Group className={"col-lg-6"}>
                <Form.Label>Patient's Name</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={dataVr?.Client_Name__c}
                />
              </Form.Group>
              <Form.Group className={"col-lg-6"}>
                <Form.Label>Mobile</Form.Label>
                <Form.Control type="text" disabled value={dataVr?.Mobile__c} />
              </Form.Group>
            </Form.Group>
          )}
        <Form.Group as={Row} className={"mb-0 mt-0"}>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>Engagement Type</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={dataVr?.Engagement_Type_F__c}
            />
          </Form.Group>
          <Form.Group className={"col-lg-6"}>
            <Form.Label>Others</Form.Label>
            <Form.Control as="textarea" disabled value={dataVr?.Others__c} />
          </Form.Group>
        </Form.Group>
      </Form>
    </>
  );
}
