import React, { useState, useRef, useEffect } from 'react';
import { Form, Row, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import { useFormContext, Controller } from "react-hook-form";
import styled from 'styled-components';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import * as contactAction from "../../Contact/redux/contactAction";
import { SignupModalNRIC } from '../modal/SignupModalNRIC';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

const CONTAINER = styled.div`
.error {
  border: 1px solid #FF6565;
}`;

const styleSelect = {
  control: base => ({
    ...base,
    borderColor: 'red'
  })
};

export function SignupParticularForm() {
  const dispatch = useDispatch();
  const { register, errors, watch, setValue } = useFormContext();
  const contactReducer = useSelector(state => state.contact);
  const [showModal, setShowModal] = useState(false);
  const [counterModal, setCounterModal] = useState(0);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);
  const [optionSalutation, setOptionSalutation] = useState([]);
  const [optionIDType, setOptionIDType] = useState([]);
  const [optionRace, setOptionRace] = useState([]);
  const [optionGender, setOptionGender] = useState([]);
  const [optionNationality, setOptionNationality] = useState([]);
  const [optionEducation, setOptionEducation] = useState([]);
  const [optionOccupation, setOptionOccupation] = useState([]);
  const [optionIndustry, setOptionIndustry] = useState([]);
  const [optionSpokenLanguage, setOptionSpokenLanguage] = useState([]);
  const [optionSpokenDialect, setOptionSpokenDialec] = useState([]); 
  const fieldNRIC = useRef();

  function setFocusNRIC(){
    fieldNRIC.current.focus();
  }

  useEffect(() => {
    dispatch(contactAction.genderRequest());
    dispatch(contactAction.salutationRequest());
    dispatch(contactAction.racesRequest());
    dispatch(contactAction.nationalitiesRequest());
    dispatch(contactAction.educationRequest());
    dispatch(contactAction.idtypeRequest());
    dispatch(contactAction.spokenLanguageRequest());
    dispatch(contactAction.occupationRequest());
    dispatch(contactAction.industryRequest());
  }, [])

  useEffect(() => {
    if(contactReducer.gender !== null){
      let tmpGender = [...contactReducer.gender.data.genders].map((item) => {
        let _newData = {
          value: item,
          label: item
        }
        return _newData;
      })
      setOptionGender(tmpGender);
    }

    if(contactReducer.salutation !== null){
      let tmpSalutation = [...contactReducer.salutation.data.salutations].map((item) => {
        let _newData = {
          value: item,
          label: item
        }
        return _newData;
      })
      setOptionSalutation(tmpSalutation);
    }

    if(contactReducer.race !== null){
      let tmpRace = [...contactReducer.race.data.races].map((item) => {
        let _newData = {
          value: item,
          label: item
        }
        return _newData;
      })
      setOptionRace(tmpRace);
    }

    if(contactReducer.nationalities !== null){
      let tmpNationalities = [...contactReducer.nationalities.data.nationalities].map((item) => {
        let _newData = {
          value: item,
          label: item
        }
        return _newData;
      })
      setOptionNationality(tmpNationalities);
    }

    if(contactReducer.education !== null){
      let tmpEducation = [...contactReducer.education.data.educations].map((item) => {
        let _newData = {
          value: item,
          label: item
        }
        return _newData;
      })
      setOptionEducation(tmpEducation);
    }

    if(contactReducer.idtype !== null){
      let tmpIDTypes = [...contactReducer.idtype.data.idTypes].map((item) => {
        let _newData = {
          value: item,
          label: item
        }
        return _newData;
      })
      setOptionIDType(tmpIDTypes);
    }

    if(contactReducer.spokenLanguage !== null){
      let tmpSpokenRoot = [...contactReducer.spokenLanguage.data.spokenLanguages];
      //check if there are cantonese language
      if(tmpSpokenRoot.filter(ar => ar === "Cantonese").length > 0){
        let idDialect = tmpSpokenRoot.indexOf("Cantonese");
        let _tmpObject = {};
        let tmpSpokenOnly = tmpSpokenRoot.slice(0,idDialect).map((item) => {
          let _object = {..._tmpObject};
          _object[item] = false;
          _tmpObject = _object;
          let _newData = {
            value: item,
            label: item
          }
          return _newData;
        });
        setOptionSpokenLanguage(tmpSpokenOnly);
        let tmpDialectOnly = tmpSpokenRoot.slice(idDialect).map((item) => {
          let _object = {..._tmpObject};
          _object[item] = false;
          _tmpObject = _object;
          let _newData = {
            value: item,
            label: item
          }
          return _newData;
        });
        setOptionSpokenDialec(tmpDialectOnly);
      } else {
        let _tmpObject = {};
        let tmpSpokenLanguage = [...contactReducer.spokenLanguage.data.spokenLanguages].map((item) => {
          let _object = {..._tmpObject};
          _object[item] = false;
          _tmpObject = _object;
          let _newData = {
            value: item,
            label: item
          }
          return _newData
        });
        setOptionSpokenLanguage(tmpSpokenLanguage);
      }
    }

    if(contactReducer.occupation !== null){
      let tmpOccupation = [...contactReducer.occupation.data.occupations].map((item) => {
        let _newData = {
          value: item,
          label: item
        }
        return _newData;
      })
      setOptionOccupation(tmpOccupation);
    }

    if(contactReducer.industry !== null){
      let tmpIndustry = [...contactReducer.industry.data.industries].map((item) => {
        let _newData = {
          value: item,
          label: item
        }
        return _newData;
      })
      setOptionIndustry(tmpIndustry);
    }
  }, [contactReducer])

  return (
    <>
      <CONTAINER>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Salutation <span className="text-danger">*</span></Form.Label>
            <Controller
              name="salutation"
              render={(
                { onChange },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={contactReducer.salutation === null}
                  onChange={(event) => {
                    onChange(event.value);
                    if(event.value === "Mr."){
                      setValue('gender', 'Male');
                    } else if(event.value === "Ms." || event.value === "Mrs."){
                      setValue('gender', 'Female');
                    } else {
                      setValue('gender', '');
                    }
                  }}
                  value={optionSalutation.filter(ar => ar.value === watch('salutation'))[0]}
                  isSearchable={true}
                  options={optionSalutation}
                  styles={errors.salutation && styleSelect}
                />
              )}
            />
            {
              errors.salutation &&
              <Form.Text className={'text-danger'}>
                {
                  errors.salutation?.message
                }
              </Form.Text>
            }
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>First Name</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="First name" 
              name="firstName"
              id="firstName"
              ref={register}
              className={(errors.firstName) && "error"}
            />
            {
              errors.firstName &&
              <Form.Text className={'text-danger'}>
                {
                  errors.firstName?.message
                }
              </Form.Text>
            }
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Last name" 
              name="lastName"
              id="lastName"
              ref={register}
              className={(errors.lastName) && "error"}
            />
            {
              errors.lastName &&
              <Form.Text className={'text-danger'}>
                {
                  errors.lastName?.message
                }
              </Form.Text>
            }
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Date of Birth <span className="text-danger">*</span></Form.Label>
            <Form.Control 
              type="date" 
              name="dob"
              id="dob"
              ref={register}
              className={(errors.dob) && "error"}
            />
            {
              errors.dob &&
              <Form.Text className={'text-danger'}>
                {
                  errors.dob?.message
                }
              </Form.Text>
            }
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>ID Type</Form.Label>
            <Controller
              name="idType"
              render={(
                { onChange },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={optionIDType.length === 0}
                  value={optionIDType.filter(ar => ar.value === watch('idType'))[0]}
                  onChange={(event) => onChange(event.value)}
                  isSearchable={true}
                  options={optionIDType}
                  styles={errors.idType && styleSelect}
                />
              )}
            />
            {
              errors.idType &&
              <Form.Text className={'text-danger'}>
                {
                  errors.idType?.message
                }
              </Form.Text>
            }
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>
              ID Number e.g. NRIC/FIN/Passport
              <CustomTooltip 
                title="We need your ID number in order for us to provide you with insurance coverage"
                placement="top-end"
                open={isOpenTooltip}
                onClose={()=>setIsOpenTooltip(false)}
                enterDelay={500}
                leaveDelay={200}
              >
                <Icon fontSize="small" className={'ml-1 my-auto'} style={{color: '#038cfc'}} onClick={()=>setIsOpenTooltip(!isOpenTooltip)}>info</Icon>
              </CustomTooltip>
            </Form.Label>
            <Controller
              name="idNumber"
              render={(
                { onChange },
              ) => (
                <Form.Control 
                  type="text" 
                  placeholder="SXXXXXX" 
                  value={watch('idNumber')}
                  onChange={(event)=>onChange(event.target.value)} 
                  className={(errors.idNumber) && "error"}
                  onBlur={
                    ()=>{
                      if(watch('step') === '0'){
                        if(watch('idNumber') === '' || watch('idNumber') === undefined){
                          setCounterModal(counterModal+1);
                          setShowModal(true);
                        }
                      }
                    }
                  } 
                  autoFocus
                  ref={fieldNRIC}
                />
              )}
            />
            {
              errors.idNumber &&
              <Form.Text className={'text-danger'}>
                {
                  errors.idNumber?.message
                }
              </Form.Text>
            }
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Gender <span className="text-danger">*</span></Form.Label>
            <Controller
              name="gender"
              render={(
                { onChange },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={contactReducer.gender === null}
                  onChange={(event) => onChange(event.value)}
                  value={optionGender.filter(ar => ar.value === watch('gender'))[0]}
                  isSearchable={false}
                  options={optionGender}
                  styles={errors.gender && styleSelect}
                />
              )}
            />
            {
              errors.gender &&
              <Form.Text className={'text-danger'}>
                {
                  errors.gender?.message
                }
              </Form.Text>
            }
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Nationality</Form.Label>
            <Controller
              name="nationality"
              render={(
                { onChange },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={optionNationality.filter(ar => ar.value === watch('nationality'))[0]}
                  isLoading={contactReducer.nationalities === null}
                  onChange={(event) => onChange(event.value)}
                  isSearchable={true}
                  options={optionNationality}
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Race</Form.Label>
            <Controller
              name="race"
              render={(
                { onChange },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={contactReducer.race === null}
                  onChange={(event) => onChange(event.value)}
                  value={optionRace.filter(ar => ar.value === watch('race'))[0]}
                  isSearchable={true}
                  options={optionRace}
                />
              )}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Education Level</Form.Label>
            <Controller
              name="education"
              render={(
                { onChange },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={contactReducer.education === null}
                  value={optionEducation.filter(ar => ar.value === watch('education'))[0]}
                  onChange={(event) => onChange(event.value)}
                  isSearchable={true}
                  options={optionEducation}
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Occupation</Form.Label>
            <Controller
              name="occupation"
              render={(
                { onChange },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={contactReducer.occupation === null}
                  onChange={(event) => onChange(event.value)}
                  value={optionOccupation.filter(ar => ar.value === watch('occupation'))[0]}
                  isSearchable={true}
                  options={optionOccupation}
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-4'} style={{display: watch("occupation") === 'Others' ? 'block' : 'none'}}>
            <Form.Label>Other Occupation</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Other Occupation" 
              id="otherOccupation"
              name="otherOccupation"
              ref={register}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Industry</Form.Label>
            <Controller
              name="industry"
              render={(
                { onChange },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isLoading={contactReducer.industry === null}
                  onChange={(event) => onChange(event.value)}
                  value={optionIndustry.filter(ar => ar.value === watch('industry'))[0]}
                  isSearchable={true}
                  options={optionIndustry}
                />
              )}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={`col-lg-${optionSpokenDialect.length > 0 ? 4 : 12} mb-1`}>
            <Form.Label>Spoken Language <span className="text-danger">*</span></Form.Label>
            {
              errors.spokenLanguage &&
              <Form.Text className={'text-danger'}>
                {
                  errors.spokenLanguage?.message
                }
              </Form.Text>
            }
          </Form.Group>
          {
            optionSpokenDialect.length > 0 &&
            <Form.Group className={`col-lg-8 mb-1`}>
              <Form.Label>Dialects</Form.Label>
            </Form.Group>
          }
          <Form.Group className={`col-lg-${optionSpokenDialect.length > 0 ? 4 : 12} mb-1`}>
            {
              optionSpokenLanguage.length === 0 ?
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner> 
              :
              optionSpokenLanguage.map((item, index) => {
                return(
                  <Controller
                    name={`spokenLanguage[${[item.value]}]`}
                    key={index}
                    render={(
                      { onChange },
                    ) => (
                      <FormControlLabel
                        control={
                          <Checkbox 
                            onChange={(event) => onChange(event.target.checked)}
                            checked={watch(`spokenLanguage[${[item.value]}]`)}
                          />
                        }
                        label={item.label}
                      />
                    )}
                  />
                )
              })
            }
          </Form.Group>
          {
            optionSpokenDialect.length > 0 &&
            <Form.Group className={`col-lg-${optionSpokenDialect.length > 0 ? 8 : 12} mb-1`}>
              {
                optionSpokenDialect.map((item, index) => {
                  return(
                    <Controller
                      name={`spokenLanguage[${[item.value]}]`}
                      key={index}
                      render={(
                        { onChange },
                      ) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox 
                              onChange={(event) => onChange(event.target.checked)}
                              checked={watch(`spokenLanguage[${[item.value]}]`)}
                            />
                          }
                          label={item.label}
                        />
                      )}
                    />
                  )
                })
              }
            </Form.Group>
          }
        </Form.Group>
      </CONTAINER>
      <SignupModalNRIC 
        show={showModal}
        onHide={()=>setShowModal(false)}
        onConfirm={()=>{
          setShowModal(false);
          setTimeout(() => {
            setFocusNRIC();
            setCounterModal(counterModal+1);
          }, 300);
        }}
      />
    </>
  );
}
