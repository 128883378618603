export const CONTACT = {
  SALUTATION_REQUEST: 'CONTACT_SALUTATION_REQUEST',
  SALUTATION_SUCCESS: 'CONTACT_SALUTATION_SUCCESS',
  SALUTATION_ERROR: 'CONTACT_SALUTATION_ERROR',
  SALUTATION_RESET: 'CONTACT_SALUTATION_RESET',

  IDTYPE_REQUEST: 'CONTACT_IDTYPE_REQUEST',
  IDTYPE_SUCCESS: 'CONTACT_IDTYPE_SUCCESS',
  IDTYPE_ERROR: 'CONTACT_IDTYPE_ERROR',
  IDTYPE_RESET: 'CONTACT_IDTYPE_RESET',

  RACES_REQUEST: 'CONTACT_RACES_REQUEST',
  RACES_SUCCESS: 'CONTACT_RACES_SUCCESS',
  RACES_ERROR: 'CONTACT_RACES_ERROR',
  RACES_RESET: 'CONTACT_RACES_RESET',

  NATIONALITIES_REQUEST: 'CONTACT_NATIONALITIES_REQUEST',
  NATIONALITIES_SUCCESS: 'CONTACT_NATIONALITIES_SUCCESS',
  NATIONALITIES_ERROR: 'CONTACT_NATIONALITIES_ERROR',
  NATIONALITIES_RESET: 'CONTACT_NATIONALITIES_RESET',

  POSTALCODE_REQUEST: 'CONTACT_POSTALCODE_REQUEST',
  POSTALCODE_SUCCESS: 'CONTACT_POSTALCODE_SUCCESS',
  POSTALCODE_ERROR: 'CONTACT_POSTALCODE_ERROR',
  POSTALCODE_RESET: 'CONTACT_POSTALCODE_RESET',

  GENDER_REQUEST: 'CONTACT_GENDER_REQUEST',
  GENDER_SUCCESS: 'CONTACT_GENDER_SUCCESS',
  GENDER_ERROR: 'CONTACT_GENDER_ERROR',
  GENDER_RESET: 'CONTACT_GENDER_RESET',

  RELIGION_REQUEST: 'CONTACT_RELIGION_REQUEST',
  RELIGION_SUCCESS: 'CONTACT_RELIGION_SUCCESS',
  RELIGION_ERROR: 'CONTACT_RELIGION_ERROR',
  RELIGION_RESET: 'CONTACT_RELIGION_RESET',

  OCCUPATION_REQUEST: 'CONTACT_OCCUPATION_REQUEST',
  OCCUPATION_SUCCESS: 'CONTACT_OCCUPATION_SUCCESS',
  OCCUPATION_ERROR: 'CONTACT_OCCUPATION_ERROR',
  OCCUPATION_RESET: 'CONTACT_OCCUPATION_RESET',

  EDUCATION_REQUEST: 'CONTACT_EDUCATION_REQUEST',
  EDUCATION_SUCCESS: 'CONTACT_EDUCATION_SUCCESS',
  EDUCATION_ERROR: 'CONTACT_EDUCATION_ERROR',
  EDUCATION_RESET: 'CONTACT_EDUCATION_RESET',

  INDUSTRY_REQUEST: 'CONTACT_INDUSTRY_REQUEST',
  INDUSTRY_SUCCESS: 'CONTACT_INDUSTRY_SUCCESS',
  INDUSTRY_ERROR: 'CONTACT_INDUSTRY_ERROR',
  INDUSTRY_RESET: 'CONTACT_INDUSTRY_RESET',

  SPOKEN_LANGUAGE_REQUEST: 'CONTACT_SPOKEN_LANGUAGE_REQUEST',
  SPOKEN_LANGUAGE_SUCCESS: 'CONTACT_SPOKEN_LANGUAGE_SUCCESS',
  SPOKEN_LANGUAGE_ERROR: 'CONTACT_SPOKEN_LANGUAGE_ERROR',
  SPOKEN_LANGUAGE_RESET: 'CONTACT_SPOKEN_LANGUAGE_RESET',

  FREQUENCY_PREFERENCES_REQUEST: 'CONTACT_FREQUENCY_PREFERENCES_REQUEST',
  FREQUENCY_PREFERENCES_SUCCESS: 'CONTACT_FREQUENCY_PREFERENCES_SUCCESS',
  FREQUENCY_PREFERENCES_ERROR: 'CONTACT_FREQUENCY_PREFERENCES_ERROR',
  FREQUENCY_PREFERENCES_RESET: 'CONTACT_FREQUENCY_PREFERENCES_RESET',

  RELATIONSHIP_REQUEST: 'CONTACT_RELATIONSHIP_REQUEST',
  RELATIONSHIP_SUCCESS: 'CONTACT_RELATIONSHIP_SUCCESS',
  RELATIONSHIP_ERROR: 'CONTACT_RELATIONSHIP_ERROR',
  RELATIONSHIP_RESET: 'CONTACT_RELATIONSHIP_RESET',

  INVOLVEMENT_LETTER_REQUEST: 'CONTACT_INVOLVEMENT_LETTER_REQUEST',
  INVOLVEMENT_LETTER_SUCCESS: 'CONTACT_INVOLVEMENT_LETTER_SUCCESS',
  INVOLVEMENT_LETTER_ERROR: 'CONTACT_INVOLVEMENT_LETTER_ERROR',
  INVOLVEMENT_LETTER_RESET: 'CONTACT_INVOLVEMENT_LETTER_RESET',
}