import { methodService, apiService } from './apiService';

export const URL = {
  BASE_ATTENDANCE: '/attendances',
}

export function apiAttendanceTable(data) {
  return apiService(
    URL.BASE_ATTENDANCE,
    methodService.GET,
    null,
    data
  );
}

export function apiAttendanceDetail(data) {
  return apiService(
    URL.BASE_ATTENDANCE+`/${data.Id}`,
    methodService.GET,
    null,
    null
  )
}