import { methodService, apiService } from './apiService';

export const URL = {
  BASE_CONTACT: '/contacts',
}

export function apiSignupVerification(data) {
  return apiService(
    URL.BASE_CONTACT+'/verify',
    methodService.POST,
    data,
    null
  );
}

export function apiSignupVerificationAdhoc(data) {
  return apiService(
    URL.BASE_CONTACT+'/verifyAdhoc',
    methodService.POST,
    data,
    null
  );
}

export function apiSignupContactRetrieve() {
  return apiService(
    URL.BASE_CONTACT+'/formData',
    methodService.GET,
    null,
    null
  )
}

export function apiSignupForm(data) {
  return apiService(
    URL.BASE_CONTACT+'/updateData',
    methodService.PATCH,
    data,
    null
  )
}

export function apiSignupVerificationCode() {
  return apiService(
    URL.BASE_CONTACT+'/verificationCode',
    methodService.PATCH,
    null,
    null
  );
}

export function apiSignupVerificationCodeForm(data) {
  return apiService(
    URL.BASE_CONTACT+'/verifyFormCode',
    methodService.PATCH,
    data,
    null
  );
}