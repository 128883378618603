import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  eventRegistered: null,
  peRegistered: null
}

const eventRegisteredRequest = state => ({
  ...state,
  isLoading: true
})
const eventRegisteredSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  eventRegistered: payload
})
const eventRegisteredError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const eventRegisteredReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  eventRegistered: null
})

const peRegisteredRequest = state => ({
  ...state,
  isLoading: true
})
const peRegisteredSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peRegistered: payload
})
const peRegisteredError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const peRegisteredReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peRegistered: null
})

export const calendarReducer = (state = initialState, action) => {
  switch (action.type ) {
    case actionType.CALENDAR.EVENT_REGISTERED_REQUEST:
      return eventRegisteredRequest(state, action.payload);
    case actionType.CALENDAR.EVENT_REGISTERED_SUCCESS:
      return eventRegisteredSuccess(state, action.payload);
    case actionType.CALENDAR.EVENT_REGISTERED_ERROR:
      return eventRegisteredError(state, action.payload);
    case actionType.CALENDAR.EVENT_REGISTERED_RESET:
      return eventRegisteredReset(state, action.payload);

    case actionType.CALENDAR.PE_REGISTERED_REQUEST:
      return peRegisteredRequest(state, action.payload);
    case actionType.CALENDAR.PE_REGISTERED_SUCCESS:
      return peRegisteredSuccess(state, action.payload);
    case actionType.CALENDAR.PE_REGISTERED_ERROR:
      return peRegisteredError(state, action.payload);
    case actionType.CALENDAR.PE_REGISTERED_RESET:
      return peRegisteredReset(state, action.payload);
  
    default:
      return state;
  }
}