import React from 'react';
import { SignupPrefferedProgrammeForm } from '../../form/SignupPreferredProgrammeForm';
import { SignupAvailabilityForm } from '../../form/SignupAvailabilityForm';

export function VolunteerPreferenceFormStep(){
  return(
    <>
      <ul className="nav nav-tabs nav-tabs-line " role="tablist">
        <li className="nav-item" onClick={()=>null}>
          <a
            className={`nav-link basic active`}
            data-toggle="tab"
            role="tab"
            href="#preferred"
          >
            Preferred Programme(s)
          </a>
        </li>
      </ul>
      <div className="mt-5">
        <SignupPrefferedProgrammeForm />
      </div>
      <ul className="nav nav-tabs nav-tabs-line " role="tablist">
        <li className="nav-item">
          <a
            className={`nav-link basic active`}
            data-toggle="tab"
            role="tab"
            href="#availability"
          >
            Volunteering Availability
          </a>
        </li>
      </ul>
      <div className="mt-5">
        <SignupAvailabilityForm />
      </div>
    </>
  )
}