import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { useSelector } from "react-redux";

export function RequirementVolunteerForm(){
  const data = useSelector(state => state.vr.vrDetail?.data?.volunteerRequest);
  return(
    <>
      <Form>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Number of Volunteers Required</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Number_of_Volunteers_Required__c} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Gender</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Gender__c} 
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Age</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Age__c} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Language (s) Spoken</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Language_s_Spoken__c} 
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Volunteer Reporting Venue</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Volunteer_Reporting_Venue__c} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Volunteer Attire</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Volunteer_Attire__c} 
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Physical Strength</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Physical_Strenghth__c} 
            />
          </Form.Group>
        </Form.Group>
      </Form>
    </>
  )
}