import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  uploadPicture: null,
  changeProfile: null,
  changePassword: null
}

const uploadPictureRequest = state => ({
  ...state,
  isLoading: true
})
const uploadPictureSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  uploadPicture: payload
})
const uploadPictureError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const uploadPictureReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  uploadPicture: null
})

const changeProfileRequest = state => ({
  ...state,
  isLoading: true
})
const changeProfileSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  changeProfile: payload
})
const changeProfileError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const changeProfileReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  changeProfile: null
})

const changePasswordRequest = state => ({
  ...state,
  isLoading: true
})
const changePasswordSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  changePassword: payload
})
const changePasswordError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const changePasswordReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  changePassword: null
})

export const userReducer = persistReducer(
  { storage, key: "nkf-user", whitelist: [""] },
  (state = initialState, action) => {
  switch (action.type ) {
    case actionType.USER.UPLOAD_PICTURE_REQUEST:
      return uploadPictureRequest(state, action.payload);
    case actionType.USER.UPLOAD_PICTURE_SUCCESS:
      return uploadPictureSuccess(state, action.payload);
    case actionType.USER.UPLOAD_PICTURE_ERROR:
      return uploadPictureError(state, action.payload);
    case actionType.USER.UPLOAD_PICTURE_RESET:
      return uploadPictureReset(state, action.payload);

    case actionType.USER.CHANGE_PROFILE_REQUEST:
      return changeProfileRequest(state, action.payload);
    case actionType.USER.CHANGE_PROFILE_SUCCESS:
      return changeProfileSuccess(state, action.payload);
    case actionType.USER.CHANGE_PROFILE_ERROR:
      return changeProfileError(state, action.payload);
    case actionType.USER.CHANGE_PROFILE_RESET:
      return changeProfileReset(state, action.payload);

    case actionType.USER.CHANGE_PASSWORD_REQUEST:
      return changePasswordRequest(state, action.payload);
    case actionType.USER.CHANGE_PASSWORD_SUCCESS:
      return changePasswordSuccess(state, action.payload);
    case actionType.USER.CHANGE_PASSWORD_ERROR:
      return changePasswordError(state, action.payload);
    case actionType.USER.CHANGE_PASSWORD_RESET:
      return changePasswordReset(state, action.payload);
  
    default:
      return state;
  }
});