import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet'
import { ModalAlert } from '../modal/SignupModalAlert';
import { ModalTnC } from '../modal/SignupModalTnc';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import moment from "moment";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import '../styles/SignupPage.css';
import * as authRepo from '../../../datasources/authRepo';

const validationSchema = Yup.object().shape({
  volunteerType: Yup.string(),
  firstName: Yup.string()
    .required("Field is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  lastName: Yup.string()
    .required("Field is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  orgName: Yup.string()
    .when('volunteerType', {
      is: 'org',
      then: Yup.string().required("Field is required")
    }),
  dob: Yup.string()
    .when('volunteerType', {
      is: 'indi',
      then: Yup.string().test(
        "dob",
        "Sorry, you need to be at least 16 years old to volunteer",
        value => {
          return moment().diff(moment(value),'years') >= 16;
        }
      ).required("Field is required")
    }),
  email: Yup.string()
    .email()
    .required("Please provide a valid email."),
  phone: Yup.string()
    .matches(/^[689]\d{7}$/, "Please provide a valid phone number")
    .required("Field is required")
})

export function SignupPage(){
  const { register, handleSubmit, setError, errors, control, watch, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      volunteerType: 'indi',
      firstName: '',
      lastName: '',
      orgName: '',
      dob: '',
      email: '',
      phone: ''
    }
  });
  const [pdpa, setPdpa] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalAlert, setModalAlert] = useState({
    isVisible: false,
    type: 'error',
    title: 'Error',
    message: ''
  })
  const [modalTnc, setModalTnc] = useState({
    isVisible: false,
    openCount: 0
  })

  const submitForm = data => {
    if(pdpa === false){
      setModalAlert({
        ...modalAlert, 
        isVisible: true,
        type: 'error',
        title: 'Terms & Conditions',
        message: 'You must agree to the Terms & Conditions to continue'
      });
    } else {
      let payload = {
        volunteerType: data.volunteerType,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        pdpa: pdpa
      }
      if(data.volunteerType === 'indi'){
        payload.dob = data.dob;
      } else if(data.volunteerType === 'org'){
        payload.orgName = data.orgName;
      }
      setIsLoading(true);
      authRepo.apiSignup(payload)
      .then((res)=>{
        console.log(res);
        setIsLoading(false);
        setModalAlert({
          ...modalAlert,
          isVisible: true,
          type: 'success',
          title: 'Success',
          message: 'Thank you for your interest to volunteer with us! We have received your application and will get in touch with you soon.'
        })
        reset();
        setPdpa(false);
      })
      .catch(err => {
        setIsLoading(false);
        let errMessage;
        if(err.response?.data){
          errMessage = err.response?.data?.message;
        } else {
          errMessage = err;
        }
        setModalAlert({
          ...modalAlert,
          isVisible: true,
          type: 'error',
          title: 'Error',
          message: errMessage
        })
      })
    }
  }

  function setClear(){
    reset();
    setPdpa(false);
  }

  function clearOnToggle(){
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('orgName', '');
    setValue('dob', '');
    setValue('email', '');
    setValue('phone', '');
  }

  return(
    <div className="app-container">
      <Container>
        <Helmet>
          <title>{'NKF Volunteer Signup'}</title>
        </Helmet>
        <Row md={12} sm={12} xl={12} lg={12}>
          <img src={toAbsoluteUrl("/media/nkf/nkf-signup-top.jpg")} className="Signup-logo" alt="logo" style={{width: '100%', margin: '0 auto'}} />
        </Row>
        <Row md={12} xl={12} sm={12} lg={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
          <span style={{fontFamily: 'PT Sans Narrow Bold', fontWeight: 'bold', color: '#7F7B77', fontSize: '15px', marginRight: 20}}>NEW VOLUNTEER SIGN-UP</span>
        </Row>
        <Row md={12} xl={12} sm={12} lg={12} className="bar-header">
          <span className="text-header" style={{lineHeight: '35px', fontWeight: 'bold', color: '#D4EFFF', fontSize: '15px'}}>Personal Particulars</span>
        </Row>
        <Form>
          <Form.Row md={12} xl={12} sm={12} lg={12} className="row-input">
            <Col md={3} sm={4} lg={3} xl={2}>Volunteer Type</Col>
            <Col md={3} sm={4} lg={4} xl={4}>
              <Controller
                control={control}
                name="volunteerType"
                render={({ onChange }) => (
                  <ToggleButtonGroup
                    name="options"
                    defaultValue={watch('volunteerType')}
                  >
                    <ToggleButton value={'indi'} onChange={(event)=>{
                      onChange(event.target.value);
                      clearOnToggle();
                    }}>Individual</ToggleButton>
                    <ToggleButton value={'org'} onChange={(event)=>{
                      onChange(event.target.value);
                      clearOnToggle();
                    }}>Organisation</ToggleButton>
                  </ToggleButtonGroup>
                )}
              />
            </Col>
          </Form.Row>
          <Form.Row md={12} xl={12} sm={12} lg={12} className="row-input">
            <Col md={3} sm={3} lg={3} xl={2}>
              First Name
            </Col>
            <Col md={9} sm={9} lg={9} xl={10}>
              <Form.Control 
                type="text" 
                className="input-text" 
                name="firstName"
                ref={register}
              />
              {
                errors?.firstName &&
                <label className="label-validation">{errors?.firstName?.message}</label>
              }
            </Col>
          </Form.Row>
          <Form.Row md={12} xl={12} sm={12} lg={12} className="row-input">
            <Col md={3} sm={3} lg={3} xl={2}>
              Last Name
              <span className="text-danger">*</span>
            </Col>
            <Col md={9} sm={9} lg={9} xl={10}>
              <Form.Control 
                type="text" 
                className="input-text" 
                name="lastName"
                ref={register}
              />
              {
                errors?.lastName &&
                <label className="label-validation">{errors?.lastName?.message}</label>
              }
            </Col>
          </Form.Row>
          <Form.Row md={12} xl={12} sm={12} lg={12} className="row-input" style={{display: watch('volunteerType') === 'org' ? 'flex' : 'none'}}>
            <Col md={3} sm={3} lg={3} xl={2}>
              Organisation Name
              <span className="text-danger">*</span>
            </Col>
            <Col md={9} sm={9} lg={9} xl={10}>
              <Form.Control 
                type="text" 
                className="input-text" 
                name="orgName"
                ref={register}
              />
              {
                errors?.orgName &&
                <label className="label-validation">{errors?.orgName?.message}</label>
              }
            </Col>
          </Form.Row>
          <Form.Row md={12} xl={12} sm={12} lg={12} className="row-input" style={{
            display: `${watch('volunteerType') === 'indi' ? 'flex' : 'none'}`
          }}>
            <Col md={3} sm={3} lg={3} xl={2}>
              Date of Birth
              <span className="text-danger">*</span>
            </Col>
            <Col md={9} sm={9} lg={9} xl={10}>
              <Form.Control 
                type="date" 
                className="input-text" 
                name="dob"
                max={moment().format("YYYY-MM-DD")}
                ref={register}
              />
              {
                errors?.dob &&
                <label className="label-validation">{errors?.dob?.message}</label>
              }
            </Col>
          </Form.Row>
          <Form.Row md={12} xl={12} sm={12} lg={12} className="row-input">
            <Col md={3} sm={4} lg={3} xl={2}>
              Email
              <span className="text-danger">*</span>
            </Col>
            <Col md={9} sm={9} lg={9} xl={10}>
              <Form.Control 
                type="email" 
                className="input-text" 
                placeholder={'email@email.com'} 
                name="email"
                ref={register}
              />
              {
                errors?.email &&
                <label className="label-validation">{errors?.email?.message}</label>
              }
            </Col>
          </Form.Row>
          <Form.Row md={12} xl={12} sm={12} lg={12} className="row-input">
            <Col md={3} sm={4} lg={3} xl={2}>
              Phone
              <span className="text-danger">*</span>
            </Col>
            <Col md={9} sm={9} lg={9} xl={10}>
            <Form.Control 
              type="number" 
              className="input-text" 
              placeholder={'9XXXXXXX'} 
              name="phone"
              ref={register}
            />
            {
              errors?.phone &&
              <label className="label-validation">{errors?.phone?.message}</label>
            }
            </Col>
          </Form.Row>
          <Row md={12} xl={12} sm={12} lg={12} className="row-input">
            <Col md={3} sm={4} lg={3} xl={2} />
            <Col md={9} sm={8} lg={9} xl={10} className="col-flex">
              <Form.Check 
                type="checkbox" 
                id="pdpaCheck" 
                checked={pdpa} 
                onChange={()=>null} 
                onClick={()=>{
                  if(pdpa === false){
                    setModalTnc({...modalTnc, isVisible: true, openCount: modalTnc.openCount+1});
                  }
                  setPdpa(!pdpa);
                }}
              />
              <label id="consent" htmlFor="pdpaCheck">
                Please click the Terms & Conditions to agree
              </label>
            </Col>
          </Row>
          <Row md={12} xl={12} sm={12} lg={12} className="row-input">
            <Col md={4}/>
            <Col md={4}>
              <Button 
                className="button" 
                onClick={handleSubmit(submitForm)}
                disabled={isLoading}
              >
                Submit
                {
                  isLoading &&
                  <span className="ml-3 spinner spinner-white"></span>
                }
              </Button>
              <Button 
                className="button" 
                onClick={()=>setClear()} 
                variant="secondary"
              >
                Clear
              </Button>
            </Col>
            <Col md={4}/>
          </Row>
        </Form>
        <ModalAlert 
          show={modalAlert.isVisible}
          onHide={()=>setModalAlert({...modalAlert, isVisible: false})}
          type={modalAlert.type}
          title={modalAlert.title}
          message={modalAlert.message}
        />
        <ModalTnC 
          show={modalTnc.isVisible}
          onHide={()=>setModalTnc({...modalTnc, isVisible: false})}
        />
      </Container>
    </div>
  )
}