export const USER = {
  UPLOAD_PICTURE_REQUEST: 'USER_UPLOAD_PICTURE_REQUEST',
  UPLOAD_PICTURE_SUCCESS: 'USER_UPLOAD_PICTURE_SUCCESS',
  UPLOAD_PICTURE_ERROR: 'USER_UPLOAD_PICTURE_ERROR',
  UPLOAD_PICTURE_RESET: 'USER_UPLOAD_PICTURE_RESET',

  CHANGE_PROFILE_REQUEST: 'USER_CHANGE_PROFILE_REQUEST',
  CHANGE_PROFILE_SUCCESS: 'USER_CHANGE_PROFILE_SUCCESS',
  CHANGE_PROFILE_ERROR: 'USER_CHANGE_PROFILE_ERROR',
  CHANGE_PROFILE_RESET: 'USER_CHANGE_PROFILE_RESET',

  CHANGE_PASSWORD_REQUEST: 'USER_CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'USER_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'USER_CHANGE_PASSWORD_ERROR',
  CHANGE_PASSWORD_RESET: 'USER_CHANGE_PASSWORD_RESET'
}