import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import moment from "moment";
import * as invitationAction from "../redux/invitationAction";
import { ModalInfoVr } from "../../VolunteerRequest/modal/ModalInfoVr";

const CONTAINER = styled.div`
  .error {
    border: 1px solid #ff6565;
  }
`;

export function InvitationVrPage(props) {
  const dispatch = useDispatch();
  const invitationReducer = useSelector(state => state.invitation);
  const [idAssignedVR, setIdAssignedVR] = useState(null);
  const [dataVR, setDataVR] = useState({});
  const [modalInfo, setModalInfo] = useState({
    type: "",
    show: false
  })

  useEffect(() => {
    let _idAssignedVr = props.location.search.substring(4);
    setIdAssignedVR(_idAssignedVr);
    dispatch(invitationAction.assignedVrInfoRequest({id: _idAssignedVr}));
  }, []);

  useEffect(() => {
    if(invitationReducer.isLoading === false && invitationReducer.isError === false && invitationReducer.assignedVrInfo !== null){
      setDataVR(invitationReducer.assignedVrInfo.data.assignedVolunteer);
    }
    if(invitationReducer.isLoading === false && (invitationReducer.registerVrEmail !== null || invitationReducer.isError === true || invitationReducer.cancelVrEmail !== null)){
      if(invitationReducer.cancelVrEmail !== null){
        setModalInfo({
          ...modalInfo,
          type: "cancel",
          show: true
        })
      } else if(invitationReducer.registerVrEmail !== null){
        setModalInfo({
          ...modalInfo,
          type: "register",
          show: true
        })
      }
      dispatch(invitationAction.registerVrEmailReset());
      dispatch(invitationAction.cancelVrEmailReset());
      dispatch(invitationAction.assignedVrInfoRequest({id: idAssignedVR}));
    }
  }, [invitationReducer]);

  function responseAction(){
    if(dataVR?.Assignee_Status__c === "Registered"){
      dispatch(invitationAction.cancelVrEmailRequest({id: idAssignedVR}));
    } else {
      dispatch(invitationAction.registerVrEmailRequest({id: idAssignedVR}));
    }
  }

  return (
    <div
      className="d-flex align-item-center justify-content-center"
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${toAbsoluteUrl("/media/nkf/volunteer-together.jpg")})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "blur(8px)",
          position: "absolute",
        }}
      />
      <Card style={{ width: "50rem" }}>
        <CardHeader>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <div className="col-lg-2">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/nkf-circle-hearts-logo.png")}
                style={{
                  maxHeight: "45px",
                }}
              />
            </div>
            <div className="col-lg-8">
              <h5 className="text-center ">Invitation Volunteer Request</h5>
            </div>
            <div className="col-lg-2">
              <SVG
                src={toAbsoluteUrl("/media/nkf/logo-nkf.svg")}
                className="h-50 align-self-center"
                style={{ maxHeight: "30px" }}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <CONTAINER>
            {Object.keys(dataVR).length !== 0 ? (
              <Form>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Volunteer Request Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataVR?.Volunteer_Request__r.Name}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Start Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataVR?.Volunteer_Request__r?.Start_Date__c).format("DD MMMM YYYY")+" "+dataVR?.Volunteer_Request__r?.Start_Time__c.substring(0,5)}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        End Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                      {moment(dataVR?.Volunteer_Request__r?.End_Date__c).format("DD MMMM YYYY")+" "+dataVR?.Volunteer_Request__r?.End_Time__c.substring(0,5)}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Frequency Type
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {dataVR?.Volunteer_Request__r?.Frequency_Type__c}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Name of DC
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                      {dataVR?.Volunteer_Request__r?.Name_of_DC__r?.Name}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Assigned Volunteer Status
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label className={`font-weight-bold ${dataVR?.Assignee_Status__c === "Registered" || dataVR?.Assignee_Status__c === "Accepted" && 'text-success'}`}>
                        {dataVR?.Assignee_Status__c}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                {
                  dataVR?.Assignee_Status__c === "Selected" || 
                  dataVR?.Assignee_Status__c === "Contacted" || 
                  dataVR?.Assignee_Status__c === "Cancelled" || 
                  dataVR?.Assignee_Status__c === "Registered" || 
                  dataVR?.Assignee_Status__c === "Accepted" ?
                  <Form.Group
                    as={Row}
                    className={"mb-1 align-items-center justify-content-center"}
                  >
                    <Button
                      variant={
                        dataVR?.Assignee_Status__c === "Selected" || 
                        dataVR?.Assignee_Status__c === "Contacted" || 
                        dataVR?.Assignee_Status__c === "Cancelled" ? 
                        "success" : "secondary"
                      }
                      style={{ width: "30%", marginRight: 10 }}
                      onClick={() => responseAction()}
                      disabled={invitationReducer.isLoading}
                    >
                      {
                        dataVR?.Assignee_Status__c === "Selected" || 
                        dataVR?.Assignee_Status__c === "Contacted" || 
                        dataVR?.Assignee_Status__c === "Cancelled" ? 
                        "Register" : 
                        dataVR?.Volunteer_Request__r?.Request_status__c === "Started" ? // seharusnya cek status planned / started belum ada return nya
                        "Withdraw" : "Cancel"
                      }
                      {
                        invitationReducer.isLoading &&
                        <span className="ml-3 spinner spinner-white"></span>
                      }
                    </Button>
                  </Form.Group> 
                  :
                  null
                }
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
            <ModalInfoVr 
              show={modalInfo.show}
              type={modalInfo.type}
              onHide={()=>setModalInfo({...modalInfo, show: false})}
            />
          </CONTAINER>
        </CardBody>
      </Card>
    </div>
  );
}
