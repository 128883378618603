import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export function ModalTnC(props){
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Terms & Conditions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ol className="text-justify" style={{paddingInlineStart: 20}}>
          <li>
            I declare that the particulars stated above are true and correct to the best of my knowledge, and that I have not wilfully suppressed any material fact. I also understand that I am obligated to declare to The National Kidney Foundation if I am related to any patients within my charge.
          </li>
          <br/>
          <li>
            I understand that I will be required to complete a Volunteer Application Form upon the acceptance of my application to be a NKF volunteer.
          </li>
        </ol>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}