import * as actionType from './actionType';

export const verificationRequest = data => ({
  type: actionType.SIGNUP.VERIFICATION_REQUEST,
  payload: data
})
export const verificationSuccess = data => ({
  type: actionType.SIGNUP.VERIFICATION_SUCCESS,
  payload: data
})
export const verificationError = data => ({
  type: actionType.SIGNUP.VERIFICATION_ERROR,
  payload: data
})
export const verificationReset = data => ({
  type: actionType.SIGNUP.VERIFICATION_RESET,
  payload: data
})

export const verificationAdhocRequest = data => ({
  type: actionType.SIGNUP.VERIFICATION_ADHOC_REQUEST,
  payload: data
})
export const verificationAdhocSuccess = data => ({
  type: actionType.SIGNUP.VERIFICATION_ADHOC_SUCCESS,
  payload: data
})
export const verificationAdhocError = data => ({
  type: actionType.SIGNUP.VERIFICATION_ADHOC_ERROR,
  payload: data
})
export const verificationAdhocReset = data => ({
  type: actionType.SIGNUP.VERIFICATION_ADHOC_RESET,
  payload: data
})

export const contactRetrieveRequest = data => ({
  type: actionType.SIGNUP.CONTACT_RETRIEVE_REQUEST,
  payload: data
})
export const contactRetrieveSuccess = data => ({
  type: actionType.SIGNUP.CONTACT_RETRIEVE_SUCCESS,
  payload: data
})
export const contactRetrieveError = data => ({
  type: actionType.SIGNUP.CONTACT_RETRIEVE_ERROR,
  payload: data
})
export const contactRetrieveReset = data => ({
  type: actionType.SIGNUP.CONTACT_RETRIEVE_RESET,
  payload: data
})

export const formRequest = data => ({
  type: actionType.SIGNUP.FORM_REQUEST,
  payload: data
})
export const formSuccess = data => ({
  type: actionType.SIGNUP.FORM_SUCCESS,
  payload: data
})
export const formError = data => ({
  type: actionType.SIGNUP.FORM_ERROR,
  payload: data
})
export const formReset = data => ({
  type: actionType.SIGNUP.FORM_RESET,
  payload: data
})

export const verificationCodeRequest = data => ({
  type: actionType.SIGNUP.VERIFICATION_CODE_REQUEST,
  payload: data
})
export const verificationCodeSuccess = data => ({
  type: actionType.SIGNUP.VERIFICATION_CODE_SUCCESS,
  payload: data
})
export const verificationCodeError = data => ({
  type: actionType.SIGNUP.VERIFICATION_CODE_ERROR,
  payload: data
})
export const verificationCodeReset = data => ({
  type: actionType.SIGNUP.VERIFICATION_CODE_RESET,
  payload: data
})

export const verificationCodeFormRequest = data => ({
  type: actionType.SIGNUP.VERIFICATION_CODE_FORM_REQUEST,
  payload: data
})
export const verificationCodeFormSuccess = data => ({
  type: actionType.SIGNUP.VERIFICATION_CODE_FORM_SUCCESS,
  payload: data
})
export const verificationCodeFormError = data => ({
  type: actionType.SIGNUP.VERIFICATION_CODE_FORM_ERROR,
  payload: data
})
export const verificationCodeFormReset = data => ({
  type: actionType.SIGNUP.VERIFICATION_CODE_FORM_RESET,
  payload: data
})