import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../_metronic/_partials/controls";
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import styled from 'styled-components';
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as signupAction from "../redux/signupAction";

const CONTAINER = styled.div`
.error {
  border: 1px solid #FF6565;
}`;

const validationSchema = Yup.object().shape({
  access_code: Yup.string()
  .required('Field is required')
})

export function SignupValidationPage(props){
  const dispatch = useDispatch();
  const signupReducer = useSelector(state => state.signup);
  const [isMounted, setIsMounted] = useState(false);
  const { register, handleSubmit, setError, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    dispatch(signupAction.verificationReset());
    dispatch(signupAction.verificationAdhocReset());
    setIsMounted(true);
  }, [])

  useEffect(() => {
    if(signupReducer.isLoading === false && signupReducer.isError === false && signupReducer.signupVerification !== null && isMounted === true){
      props.history.push('/signup-complete-form');
    } else if(signupReducer.isLoading === false && signupReducer.isError === false && signupReducer.signupVerificationAdhoc !== null && isMounted === true){
      props.history.push(signupReducer.signupVerificationAdhoc.data.setPasswordUrl.substring(signupReducer.signupVerificationAdhoc.data.setPasswordUrl.lastIndexOf("/")));
    } else if(signupReducer.isLoading === false && signupReducer.isError === true && isMounted === true){
      setError("access_code", {
        type: "manual",
        message: signupReducer.errorMsg.message
      });
    }
  }, [signupReducer])

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

  function onSubmit(values){
    if(getParameterByName('type', window.location.search) === 'adhoc'){
      dispatch(signupAction.verificationAdhocRequest({
        id: getParameterByName('id', window.location.search),
        access_code: values.access_code
      }))
    } else {
      dispatch(signupAction.verificationRequest({
        id: window.location.search.substring(4),
        access_code: values.access_code
      }));
    }
  }

  return(
    <div className="d-flex align-item-center justify-content-center" 
      style={{
        height: '100vh', 
        alignItems: 'center', 
        justifyContent: 'center', 
      }}>
      <div 
        style={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          backgroundImage: `url(${toAbsoluteUrl("/media/nkf/volunteer-together.jpg")})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          filter: 'blur(8px)',
          position: 'absolute'
        }}
      />
      <Card style={{width: '50rem', height: '20rem'}}>
        <CardHeader>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <div className="col-lg-2">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/nkf-circle-hearts-logo.png")}
                style={{
                  maxHeight: '50px'
                }}
              />
            </div>
            <div className="col-lg-8">
              <h5 className="text-center ">Access Verification</h5>
            </div>
            <div className="col-lg-2">
              <SVG
                src={toAbsoluteUrl(
                  "/media/nkf/logo-nkf.svg"
                )}
                className="h-50 align-self-center"
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <CONTAINER>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className={'col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column mb-0'}>
                <Form.Label>Access Verification Code</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="email+ddmmyyyy (Your DOB)" 
                  name="access_code"
                  id="access_code"
                  ref={register}
                  className={(errors.access_code) && "error"}
                />
                <Form.Text className={`text-muted`}>
                  {
                    'eg. john@email.com01011990'
                  }
                </Form.Text>
                <Form.Text className={`${errors.access_code ? 'text-danger' : 'text-muted'} mt-0 mb-0`}>
                  {
                    errors.access_code?.message
                  }
                </Form.Text>
              </Form.Group>
              <Form.Group className={`col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column 
                ${errors.access_code ? 'mt-0' : 'mt-5'}`}>
                <Button className={'col-lg-6 mx-auto'} variant="primary" disabled={signupReducer.isLoading} type="submit">
                  Submit
                  {
                    signupReducer.isLoading &&
                    <span className="ml-3 spinner spinner-white"></span>
                  }
                </Button>
              </Form.Group>
            </Form>
          </CONTAINER>
        </CardBody>
      </Card>
    </div>
  )
}