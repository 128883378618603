import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter
} from "../../../../_metronic/_partials/controls";
import { PDFViewer, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import moment from "moment-timezone";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useDispatch, useSelector } from "react-redux";
import * as contactAction from "../../Contact/redux/contactAction";

export function PrintCommunityInvolvementPage(){
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.auth);
  const contactReducer = useSelector(state => state.contact);

  useEffect(() => {
    if(contactReducer.involvementLetter === null){
      dispatch(contactAction.involvementLetterRequest());
    }
  }, [])

  const DocumentCommunityLetter = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.wrapperPage}>
          <View>
            <Image 
              src={toAbsoluteUrl("/media/nkf/header-community-letter.jpg")}
              style={{width: 600, height: 96}}
            />
            <View style={styles.wrapperContent}>
              <Text style={[styles.baseContentText]}>{moment().format("DD MMMM YYYY")}</Text>
              <Text style={[styles.baseContentText, { marginTop: 20}]}>To Whom It May Concern</Text>
              <Text style={[styles.baseContentText, { marginTop: 20, fontWeight: "bold"}]}>Community Involvement with The National Kidney Foundation</Text>
              <Text style={[styles.baseContentText, { marginTop: 10}]}>This is to certify that {authReducer.user?.name} {authReducer.user?.nirc !== null ? 'NRIC/FIN No. XXXX'+authReducer.user?.nirc.substr(4)+' ':  ''}has contributed {contactReducer.involvementLetter?.data?.volunteerHours} hours to The National Kidney Foundation since {moment(contactReducer.involvementLetter?.data?.firstTimeCheckIn).format("DD MMMM YYYY")}.</Text>
              <Text style={[styles.baseContentText, { marginTop: 15}]}>Should there be any clarification or queries, please email volunteer@nkfs.org.</Text>
              <Text style={[styles.baseContentText, { marginTop: 10}]}>Thank you.</Text>
              <Text style={[styles.baseContentText, { marginTop: 25, fontWeight: "bold"}]}>[This is a computer-generated letter. No signatory is required.]</Text>
            </View>
          </View>
          <Image 
            src={toAbsoluteUrl("/media/nkf/footer-community-letter.jpg")}
            style={{width: 600, height: 79}}
          />
        </View>
      </Page>
    </Document>
  )

  const FramePdf = () => {
    return useMemo(
      () => (
        <PDFViewer style={{ height: 1000}}>
          <DocumentCommunityLetter />
        </PDFViewer>
      ),
      [],
    )
  }

  return(
    <>
      <Card>
        {
          contactReducer.involvementLetter === null ?
          <span className="ml-3 spinner spinner-green"></span> :
          contactReducer.involvementLetter?.data?.volunteerHours >= 24 ?
          <FramePdf /> :
          <CardBody>
            <span>Oops! You can only print your Community Involvement Letter upon fulfilling 24 volunteering hours. Browse for volunteering opportunities you can sign up for now!</span>
          </CardBody>
        }
      </Card>
    </>
  )
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF"
  },
  wrapperPage: {
    flexDirection: "column",
    justifyContent: "space-between"
  },
  wrapperUpperComponent: {

  },
  wrapperContent: {
    marginLeft: 50,
    marginRight: 50,
    marginTop: 15,
    width: "75%"
  },
  baseContentText: {
    fontFamily: 'Open Sans',
    fontWeight: "normal",
    fontSize: 11,
    color: "black"
  }
})