import * as actionType from './actionType';

export const salutationRequest = data => ({
  type: actionType.CONTACT.SALUTATION_REQUEST,
  payload: data
})
export const salutationSuccess = data => ({
  type: actionType.CONTACT.SALUTATION_SUCCESS,
  payload: data
})
export const salutationError = data => ({
  type: actionType.CONTACT.SALUTATION_ERROR,
  payload: data
})
export const salutationReset = data => ({
  type: actionType.CONTACT.SALUTATION_RESET,
  payload: data
})


export const idtypeRequest = data => ({
  type: actionType.CONTACT.IDTYPE_REQUEST,
  payload: data
})
export const idtypeSuccess = data => ({
  type: actionType.CONTACT.IDTYPE_SUCCESS,
  payload: data
})
export const idtypeError = data => ({
  type: actionType.CONTACT.IDTYPE_ERROR,
  payload: data
})
export const idtypeReset = data => ({
  type: actionType.CONTACT.IDTYPE_RESET,
  payload: data
})


export const racesRequest = data => ({
  type: actionType.CONTACT.RACES_REQUEST,
  payload: data
})
export const racesSuccess = data => ({
  type: actionType.CONTACT.RACES_SUCCESS,
  payload: data
})
export const racesError = data => ({
  type: actionType.CONTACT.RACES_ERROR,
  payload: data
})
export const racesReset = data => ({
  type: actionType.CONTACT.RACES_RESET,
  payload: data
})


export const nationalitiesRequest = data => ({
  type: actionType.CONTACT.NATIONALITIES_REQUEST,
  payload: data
})
export const nationalitiesSuccess = data => ({
  type: actionType.CONTACT.NATIONALITIES_SUCCESS,
  payload: data
})
export const nationalitiesError = data => ({
  type: actionType.CONTACT.NATIONALITIES_ERROR,
  payload: data
})
export const nationalitiesReset = data => ({
  type: actionType.CONTACT.NATIONALITIES_RESET,
  payload: data
})


export const postalcodeRequest = data => ({
  type: actionType.CONTACT.POSTALCODE_REQUEST,
  payload: data
})
export const postalcodeSuccess = data => ({
  type: actionType.CONTACT.POSTALCODE_SUCCESS,
  payload: data
})
export const postalcodeError = data => ({
  type: actionType.CONTACT.POSTALCODE_ERROR,
  payload: data
})
export const postalcodeReset = data => ({
  type: actionType.CONTACT.POSTALCODE_RESET,
  payload: data
})


export const genderRequest = data => ({
  type: actionType.CONTACT.GENDER_REQUEST,
  payload: data
})
export const genderSuccess = data => ({
  type: actionType.CONTACT.GENDER_SUCCESS,
  payload: data
})
export const genderError = data => ({
  type: actionType.CONTACT.GENDER_ERROR,
  payload: data
})
export const genderReset = data => ({
  type: actionType.CONTACT.GENDER_RESET,
  payload: data
})


export const religionRequest = data => ({
  type: actionType.CONTACT.RELIGION_REQUEST,
  payload: data
})
export const religionSuccess = data => ({
  type: actionType.CONTACT.RELIGION_SUCCESS,
  payload: data
})
export const religionError = data => ({
  type: actionType.CONTACT.RELIGION_ERROR,
  payload: data
})
export const religionReset = data => ({
  type: actionType.CONTACT.RELIGION_RESET,
  payload: data
})


export const occupationRequest = data => ({
  type: actionType.CONTACT.OCCUPATION_REQUEST,
  payload: data
})
export const occupationSuccess = data => ({
  type: actionType.CONTACT.OCCUPATION_SUCCESS,
  payload: data
})
export const occupationError = data => ({
  type: actionType.CONTACT.OCCUPATION_ERROR,
  payload: data
})
export const occupationReset = data => ({
  type: actionType.CONTACT.OCCUPATION_RESET,
  payload: data
})

export const educationRequest = data => ({
  type: actionType.CONTACT.EDUCATION_REQUEST,
  payload: data
})
export const educationSuccess = data => ({
  type: actionType.CONTACT.EDUCATION_SUCCESS,
  payload: data
})
export const educationError = data => ({
  type: actionType.CONTACT.EDUCATION_ERROR,
  payload: data
})
export const educationReset = data => ({
  type: actionType.CONTACT.EDUCATION_RESET,
  payload: data
})

export const industryRequest = data => ({
  type: actionType.CONTACT.INDUSTRY_REQUEST,
  payload: data
})
export const industrySuccess = data => ({
  type: actionType.CONTACT.INDUSTRY_SUCCESS,
  payload: data
})
export const industryError = data => ({
  type: actionType.CONTACT.INDUSTRY_ERROR,
  payload: data
})
export const industryReset = data => ({
  type: actionType.CONTACT.INDUSTRY_RESET,
  payload: data
})

export const spokenLanguageRequest = data => ({
  type: actionType.CONTACT.SPOKEN_LANGUAGE_REQUEST,
  payload: data
})
export const spokenLanguageSuccess = data => ({
  type: actionType.CONTACT.SPOKEN_LANGUAGE_SUCCESS,
  payload: data
})
export const spokenLanguageError = data => ({
  type: actionType.CONTACT.SPOKEN_LANGUAGE_ERROR,
  payload: data
})
export const spokenLanguageReset = data => ({
  type: actionType.CONTACT.SPOKEN_LANGUAGE_RESET,
  payload: data
})

export const frequencyPreferencesRequest = data => ({
  type: actionType.CONTACT.FREQUENCY_PREFERENCES_REQUEST,
  payload: data
})
export const frequencyPreferencesSuccess = data => ({
  type: actionType.CONTACT.FREQUENCY_PREFERENCES_SUCCESS,
  payload: data
})
export const frequencyPreferencesError = data => ({
  type: actionType.CONTACT.FREQUENCY_PREFERENCES_ERROR,
  payload: data
})
export const frequencyPreferencesReset = data => ({
  type: actionType.CONTACT.FREQUENCY_PREFERENCES_RESET,
  payload: data
})

export const relationshipRequest = data => ({
  type: actionType.CONTACT.RELATIONSHIP_REQUEST,
  payload: data
})
export const relationshipSuccess = data => ({
  type: actionType.CONTACT.RELATIONSHIP_SUCCESS,
  payload: data
})
export const relationshipError = data => ({
  type: actionType.CONTACT.RELATIONSHIP_ERROR,
  payload: data
})
export const relationshipReset = data => ({
  type: actionType.CONTACT.RELATIONSHIP_RESET,
  payload: data
})

export const involvementLetterRequest = data => ({
  type: actionType.CONTACT.INVOLVEMENT_LETTER_REQUEST,
  payload: data
})
export const involvementLetterSuccess = data => ({
  type: actionType.CONTACT.INVOLVEMENT_LETTER_SUCCESS,
  payload: data
})
export const involvementLetterError = data => ({
  type: actionType.CONTACT.INVOLVEMENT_LETTER_ERROR,
  payload: data
})
export const involvementLetterReset = data => ({
  type: actionType.CONTACT.INVOLVEMENT_LETTER_RESET,
  payload: data
})