import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as authAction from './authAction';
import * as authRepo from '../../../datasources/authRepo';

function* authSetupPassword(data) {
  try {
    const res = yield call(authRepo.apiSetupPassword, data.payload);
    yield put(authAction.setupPasswordSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(authAction.setupPasswordError(err.response.data));
    } else {
      yield put(authAction.setupPasswordError(err));
    }
  }
}

function* authLogin(data) {
  try {
    const res = yield call(authRepo.apiLogin, data.payload);
    yield put(authAction.loginSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(authAction.loginError(err.response.data));
    } else {
      yield put(authAction.loginError(err));
    }
  }
}

function* authFA(data) {
  try {
    const res = yield call(authRepo.apiFA, data.payload);
    yield put(authAction.faSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(authAction.faError(err.response.data));
    } else {
      yield put(authAction.faError(err));
    }
  }
}

function* forgotPassword(data) {
  try {
    const res = yield call(authRepo.apiForgot, data.payload);
    yield put(authAction.forgotSuccess(res));
  } catch (err) {
    if(err.response?.data){
      yield put(authAction.forgotError(err.response.data));
    } else {
      yield put(authAction.forgotError(err));
    }
  }
}

export default function* watchAuth() {
  yield all([
    takeLatest(actionType.AUTH.SETUP_PASSWORD_REQUEST, authSetupPassword),
    takeLatest(actionType.AUTH.LOGIN_REQUEST, authLogin),
    takeLatest(actionType.AUTH.FA_REQUEST, authFA),
    takeLatest(actionType.AUTH.FORGOT_REQUEST, forgotPassword)
  ])
}