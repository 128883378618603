import React from 'react';
import { Form, Row } from 'react-bootstrap';

export function AttendanceFilter({searchName, setSearchName}){
  return(
    <>
      <div>
        <Form>
          <Form.Group as={Row} className={'px-5 mb-0 mt-5'}>
            <Form.Group className={'col-lg-4 mx-0'}>
              <Form.Control 
                type="text" 
                placeholder="Search by Name" 
                onChange={(e)=>setSearchName(e.target.value)}
                value={searchName}
              />
            </Form.Group>
          </Form.Group>
        </Form>
      </div>
    </>
  )
}