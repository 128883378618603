import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { CalendarPages } from "./modules/Calendar/pages/CalendarPages";
import { AttendancePage } from "./modules/Attendance/pages/AttendancePage";
import { AttendanceDetailPage } from "./modules/Attendance/pages/AttendanceDetailPage";
import { VolunteerRequestPage } from "./modules/VolunteerRequest/pages/VolunteerRequestPage";
import { VolunteerRequestDetailPage } from "./modules/VolunteerRequest/pages/VolunteerRequestDetailPage";
import { ProgrammeEventsPage } from "./modules/ProgrammeEvents/pages/ProgrammeEventsPage";
import { ProgrammeEventsDetailPage } from "./modules/ProgrammeEvents/pages/ProgrammeEventsDetailPage";
import { PrintCommunityInvolvementPage } from "./modules/PrintCommunity/pages/PrintCommunityInvolvementLetterPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/calendar-events" component={CalendarPages} />
        <Route path="/attendance/detail" component={AttendanceDetailPage} />
        <Route path="/attendance" component={AttendancePage} />
        <Route path="/volunteer-request/detail" component={VolunteerRequestDetailPage} />
        <Route path="/volunteer-request" component={VolunteerRequestPage} />
        <Route path="/programme-events/detail" component={ProgrammeEventsDetailPage} />
        <Route path="/programme-events" component={ProgrammeEventsPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/community-letter" component={PrintCommunityInvolvementPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
