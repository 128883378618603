import React, { useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter
} from "../../../../_metronic/_partials/controls";
import { useDispatch, useSelector } from "react-redux";
import { DetailInfoForm } from "../form/DetailInfoForm";
import * as attendanceAction from "../redux/attendanceActions";
import { LoadingCard } from "../../../component/LoadingCard";

export function AttendanceDetailPage(props){
  const dispatch = useDispatch();
  const data = useSelector(state => state.attendance.attendanceDetail?.data?.attendance);
  
  useEffect(() => {
    let payload = {
      Id: window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1)
    }
    dispatch(attendanceAction.attendanceDetailRequest(payload));

    return(
      ()=> {
        dispatch(attendanceAction.attendanceDetailReset());
      }
    )
  }, [])
  return(
    <>
      <Card>
        {
          data ?
          <CardBody>
            <ul className="nav nav-tabs nav-tabs-line " role="tablist">
              <li className="nav-item" onClick={()=>null}>
                <a
                  className={`nav-link basic active`}
                  data-toggle="tab"
                  role="tab"
                  href="#basic-info"
                >
                  Detail Information
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <DetailInfoForm/>
            </div>
          </CardBody>
          : 
          <LoadingCard/>
        }
      </Card>
    </>
  )
}