import * as actionType from './actionTypes';

export const vrTableRequest = data => ({
  type: actionType.VOLUNTEER_REQUEST.TABLE_REQUEST,
  payload: data
})
export const vrTableSuccess = data => ({
  type: actionType.VOLUNTEER_REQUEST.TABLE_SUCCESS,
  payload: data
})
export const vrTableError = data => ({
  type: actionType.VOLUNTEER_REQUEST.TABLE_ERROR,
  payload: data
})
export const vrTableReset = data => ({
  type: actionType.VOLUNTEER_REQUEST.TABLE_RESET,
  payload: data
})

export const vrDetailRequest = data => ({
  type: actionType.VOLUNTEER_REQUEST.DETAIL_REQUEST,
  payload: data
})
export const vrDetailSuccess = data => ({
  type: actionType.VOLUNTEER_REQUEST.DETAIL_SUCCESS,
  payload: data
})
export const vrDetailError = data => ({
  type: actionType.VOLUNTEER_REQUEST.DETAIL_ERROR,
  payload: data
})
export const vrDetailReset = data => ({
  type: actionType.VOLUNTEER_REQUEST.DETAIL_RESET,
  payload: data
})

export const vrRegisterRequest = data => ({
  type: actionType.VOLUNTEER_REQUEST.REGISTER_REQUEST,
  payload: data
})
export const vrRegisterSuccess = data => ({
  type: actionType.VOLUNTEER_REQUEST.REGISTER_SUCCESS,
  payload: data
})
export const vrRegisterError = data => ({
  type: actionType.VOLUNTEER_REQUEST.REGISTER_ERROR,
  payload: data
})
export const vrRegisterReset = data => ({
  type: actionType.VOLUNTEER_REQUEST.REGISTER_RESET,
  payload: data
})

export const vrCancelRequest = data => ({
  type: actionType.VOLUNTEER_REQUEST.CANCEL_REQUEST,
  payload: data
})
export const vrCancelSuccess = data => ({
  type: actionType.VOLUNTEER_REQUEST.CANCEL_SUCCESS,
  payload: data
})
export const vrCancelError = data => ({
  type: actionType.VOLUNTEER_REQUEST.CANCEL_ERROR,
  payload: data
})
export const vrCancelReset = data => ({
  type: actionType.VOLUNTEER_REQUEST.CANCEL_RESET,
  payload: data
})