import * as actionType from './actionTypes';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  peTable: null,
  peDetail: null,
  peRegister: null,
  peCancel: null
}

const peTableRequest = state => ({
  ...state,
  isLoading: true
})
const peTableSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peTable: payload
})
const peTableError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const peTableReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peTable: null
})

const peDetailRequest = state => ({
  ...state,
  isLoading: true
})
const peDetailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peDetail: payload
})
const peDetailError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const peDetailReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peDetail: null
})

const peRegisterRequest = state => ({
  ...state,
  isLoading: true
})
const peRegisterSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peRegister: payload
})
const peRegisterError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const peRegisterReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peRegister: null
})

const peCancelRequest = state => ({
  ...state,
  isLoading: true
})
const peCancelSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peCancel: payload
})
const peCancelError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const peCancelReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  peCancel: null
})

export const programmeEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PROGRAMME_EVENTS.TABLE_REQUEST:
      return peTableRequest(state, action.payload);
    case actionType.PROGRAMME_EVENTS.TABLE_SUCCESS:
      return peTableSuccess(state, action.payload);
    case actionType.PROGRAMME_EVENTS.TABLE_ERROR:
      return peTableError(state, action.payload);
    case actionType.PROGRAMME_EVENTS.TABLE_RESET:
      return peTableReset(state, action.payload);

    case actionType.PROGRAMME_EVENTS.DETAIL_REQUEST:
      return peDetailRequest(state, action.payload);
    case actionType.PROGRAMME_EVENTS.DETAIL_SUCCESS:
      return peDetailSuccess(state, action.payload);
    case actionType.PROGRAMME_EVENTS.DETAIL_ERROR:
      return peDetailError(state, action.payload);
    case actionType.PROGRAMME_EVENTS.DETAIL_RESET:
      return peDetailReset(state, action.payload);

    case actionType.PROGRAMME_EVENTS.REGISTER_REQUEST:
      return peRegisterRequest(state, action.payload);
    case actionType.PROGRAMME_EVENTS.REGISTER_SUCCESS:
      return peRegisterSuccess(state, action.payload);
    case actionType.PROGRAMME_EVENTS.REGISTER_ERROR:
      return peRegisterError(state, action.payload);
    case actionType.PROGRAMME_EVENTS.REGISTER_RESET:
      return peRegisterReset(state, action.payload);

    case actionType.PROGRAMME_EVENTS.CANCEL_REQUEST:
      return peCancelRequest(state, action.payload);
    case actionType.PROGRAMME_EVENTS.CANCEL_SUCCESS:
      return peCancelSuccess(state, action.payload);
    case actionType.PROGRAMME_EVENTS.CANCEL_ERROR:
      return peCancelError(state, action.payload);
    case actionType.PROGRAMME_EVENTS.CANCEL_RESET:
      return peCancelReset(state, action.payload);
  
    default:
      return state;
  }
}