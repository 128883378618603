import {combineReducers} from "redux";

// import * as auth from "../app/modules/Auth/_redux/authRedux";
import {authReducer} from "../app/modules/Auth/redux/authReducer";
import {attendanceReducer} from "../app/modules/Attendance/redux/attendanceReducer";
import {calendarReducer} from "../app/modules/Calendar/redux/calendarReducer";
import {contactReducer} from "../app/modules/Contact/redux/contactReducer";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {dashboardReducer} from "../app/modules/Dashboard/redux/dashboardReducer";
import {invitationReducer} from "../app/modules/Invitation/redux/invitationReducer";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {programmeEventsReducer} from "../app/modules/ProgrammeEvents/redux/programmeEventsReducer";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import {signupReducer} from "../app/modules/Signup/redux/signupReducer"
import {timesheetReducer} from "../app/modules/Timesheet/redux/timesheetReducer"
import {userReducer} from "../app/modules/UserProfile/redux/userReducer";
import {volunteerRequestReducer} from "../app/modules/VolunteerRequest/redux/volunteerRequestReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  attendance: attendanceReducer,
  calendar: calendarReducer,
  contact: contactReducer,
  customers: customersSlice.reducer,
  dashboard: dashboardReducer,
  invitation: invitationReducer,
  products: productsSlice.reducer,
  pe: programmeEventsReducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  signup: signupReducer,
  timesheet: timesheetReducer,
  user: userReducer,
  vr: volunteerRequestReducer
});