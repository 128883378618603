import { methodService, apiService } from './apiService';

export const URL = {
  AUTH_SIGNUP: '/contacts',
  AUTH_SETUP_PASSWORD: '/contacts/password',
  AUTH_LOGIN: '/contacts/login',
  AUTH_FA: '/contacts/2FA',
  AUTH_FORGOT: '/contacts/resetPasswordRequest'
}

export function apiSignup(data) {
  return apiService(
    URL.AUTH_SIGNUP,
    methodService.POST,
    data,
    null
  );
}

export function apiSetupPassword(data) {
  return apiService(
    URL.AUTH_SETUP_PASSWORD,
    methodService.PATCH,
    data,
    null
  )
}

export function apiLogin(data) {
  return apiService(
    URL.AUTH_LOGIN,
    methodService.POST,
    data,
    null
  )
}

export function apiFA(data) {
  return apiService(
    URL.AUTH_FA,
    methodService.POST,
    data,
    null
  )
}

export function apiForgot(data) {
  return apiService(
    URL.AUTH_FORGOT,
    methodService.PATCH,
    data,
    null
  )
}