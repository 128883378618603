import React from 'react';
import { AttendanceCard } from './AttendanceCard';

export function AttendancePage({history}){
  const attendanceUIEvent = {
    detailAttendanceRowClick: (data) => {
      history.push({
        pathname: `/attendance/detail/${data.Id}`,
        data: data
      })
    },
  }
  return(
    <>
      <AttendanceCard UIEvent={attendanceUIEvent}/>
    </>
  )
}