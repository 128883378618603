import { methodService, apiService } from './apiService';

export const URL = {
  BASE_INVITATION: '/events',
  BASE_PARTICIPANT: '/participants',
  BASE_ASSIGNEDVR: '/assignedVolunteers'
}

export function apiInvitationEventRetrieve(data) {
  return apiService(
    URL.BASE_INVITATION+`/${data.id}`,
    methodService.GET,
    null,
    null
  );
}

export function apiInvitationSetResponse(data) {
  return apiService(
    URL.BASE_INVITATION+`/${data.id}/status`,
    methodService.PATCH,
    data,
    null
  )
}

export function apiInvitationCheckin(data) {
  return apiService(
    URL.BASE_INVITATION+`/${data.id}/checkin`,
    methodService.PATCH,
    data,
    null
  )
}

export function apiParticipantInfo(data) {
  return apiService(
    URL.BASE_PARTICIPANT+`/${data.id}`,
    methodService.GET,
    null,
    null
  )
}

export function apiRegisterProgrammeEmail(data) {
  return apiService(
    URL.BASE_PARTICIPANT+`/${data.id}/register`,
    methodService.PATCH,
    null,
    null
  )
}

export function apiCancelProgrammeEmail(data) {
  return apiService(
    URL.BASE_PARTICIPANT+`/${data.id}/cancel`,
    methodService.PATCH,
    null,
    null
  )
}

export function apiAssignedVrInfo(data) {
  return apiService(
    URL.BASE_ASSIGNEDVR+`/${data.id}`,
    methodService.GET,
    null,
    null
  )
}

export function apiRegisterVrEmail(data) {
  return apiService(
    URL.BASE_ASSIGNEDVR+`/${data.id}/register`,
    methodService.PATCH,
    null,
    null
  )
}

export function apiCancelVrEmail(data) {
  return apiService(
    URL.BASE_ASSIGNEDVR+`/${data.id}/cancel`,
    methodService.PATCH,
    null,
    null
  )
}