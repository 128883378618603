import * as actionType from './actionType';

export const eventRegisteredRequest = data => ({
  type: actionType.CALENDAR.EVENT_REGISTERED_REQUEST,
  payload: data
})
export const eventRegisteredSuccess = data => ({
  type: actionType.CALENDAR.EVENT_REGISTERED_SUCCESS,
  payload: data
})
export const eventRegisteredError = data => ({
  type: actionType.CALENDAR.EVENT_REGISTERED_ERROR,
  payload: data
})
export const eventRegisteredReset = data => ({
  type: actionType.CALENDAR.EVENT_REGISTERED_RESET,
  payload: data
})

export const peRegisteredRequest = data => ({
  type: actionType.CALENDAR.PE_REGISTERED_REQUEST,
  payload: data
})
export const peRegisteredSuccess = data => ({
  type: actionType.CALENDAR.PE_REGISTERED_SUCCESS,
  payload: data
})
export const peRegisteredError = data => ({
  type: actionType.CALENDAR.PE_REGISTERED_ERROR,
  payload: data
})
export const peRegisteredReset = data => ({
  type: actionType.CALENDAR.PE_REGISTERED_RESET,
  payload: data
})