import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
} from "../../../../_metronic/_partials/controls";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import styled from "styled-components";
import moment from "moment-timezone";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as timesheetAction from "../redux/timesheetAction";

const CONTAINER = styled.div`
  .error {
    border: 1px solid #ff6565;
  }
`;

const validationSchema = Yup.object().shape({
  email: Yup.string()
  .required('Field is required')
})

export function TimesheetAttendanceTraining(props) {
  const dispatch = useDispatch();
  const timesheetReducer = useSelector(state => state.timesheet);
  const [idAttendanceTraining, setIdAttendanceTraining] = useState(null);
  const [dataTraining, setDataTraining] = useState({});
  const [dataAttendance, setDataAttendance] = useState(null);
  const [isCheckIn, setIsCheckIn] = useState(null);
  const { register, handleSubmit, setError, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: ''
    }
  });

  useEffect(() => {
    let _idAttendanceTraining = props.location.search.substring(4);
    setIdAttendanceTraining(_idAttendanceTraining);
    dispatch(timesheetAction.detailTrainingRequest({id: _idAttendanceTraining}));
  }, []);

  useEffect(() => {
    if(timesheetReducer.isLoading === false && timesheetReducer.isError === false && timesheetReducer.detailTraining !== null){
      setDataTraining(timesheetReducer.detailTraining.data.programmeEvent);
    }
    if(timesheetReducer.isLoading === false && timesheetReducer.isError === true){
      setError("email", {
        type: "manual",
        message: timesheetReducer.errorMsg.message
      });
      setIsCheckIn(null);
    }
    if(timesheetReducer.isLoading === false && timesheetReducer.isError === false && timesheetReducer.validateTraining !== null){
      setIsCheckIn(timesheetReducer.validateTraining?.data?.canChekIn);
      console.log(timesheetReducer.validateTraining?.data)
      setDataAttendance(timesheetReducer.validateTraining?.data?.trainingAttendance);
    }
    if(timesheetReducer.isLoading === false && timesheetReducer.isError === false && timesheetReducer.checkinTraining !== null){
      setDataAttendance(timesheetReducer.checkinTraining?.data?.traineeAttendace);
      setIsCheckIn(false);
    }
  }, [timesheetReducer]);

  function submitAttendance(values){
    if(isCheckIn === null){
      dispatch(timesheetAction.validateTrainingRequest({id: idAttendanceTraining, email: values.email}));
    } else {
      dispatch(timesheetAction.checkinTrainingReset());
      dispatch(timesheetAction.checkoutTrainingReset());
      if(isCheckIn === true){
        dispatch(timesheetAction.checkinTrainingRequest({id: idAttendanceTraining, email: values.email}));
      } else if(isCheckIn === false){
        dispatch(timesheetAction.checkoutTrainingRequest({id: idAttendanceTraining, trainingAttendanceId: dataAttendance.Id, email: values.email}));
      }
    }
  }

  return (
    <div
      className="d-flex align-item-center justify-content-center"
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${toAbsoluteUrl("/media/nkf/volunteer-together.jpg")})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "blur(8px)",
          position: "absolute",
        }}
      />
      <Card style={{ width: "50rem" }}>
        <CardBody>
          <CONTAINER>
            {Object.keys(dataTraining).length !== 0 ? (
              <Form onSubmit={handleSubmit(submitAttendance)}>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Volunteer Request Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataTraining?.Name}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Start Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        { moment(dataTraining?.Start_Date_Time__c).tz("Asia/Singapore").format("DD MMMM YYYY HH:mm A")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        End Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                      {moment(dataTraining?.End_Date_Time__c).tz("Asia/Singapore").format("DD MMMM YYYY HH:mm A")}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Venue of Event or Mobilisation
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {dataTraining?.Venue_of_Event_or_Mobilisation__c}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Status
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {dataTraining?.Programme_Stage__c}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                {
                  dataTraining?.Programme_Stage__c === "Started" ?
                  <>
                    {
                      timesheetReducer.checkinTraining !== null &&
                      <Form.Group className={'col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column mb-0'}>
                        {
                          timesheetReducer.checkinTraining?.data?.message + ' '+ moment(timesheetReducer.checkinTraining?.data?.traineeAttendace?.Check_In__c).tz("Asia/Singapore").format("DD MMMM YYYY HH:mm")
                        }
                      </Form.Group>
                    }
                    {
                      timesheetReducer.checkoutTraining !== null &&
                      <Form.Group className={'col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column mb-0'}>
                        {
                          timesheetReducer.checkoutTraining?.data?.message + ' '+ moment(timesheetReducer.checkoutTraining?.data?.checkOutTime).tz("Asia/Singapore").format("DD MMMM YYYY HH:mm")
                        }
                      </Form.Group>
                    }
                    <Form.Group className={'col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column mb-0'}>
                      <Form.Control 
                        type="text" 
                        placeholder="email" 
                        name="email"
                        id="email"
                        ref={register}
                        className={(errors.email) && "error"}
                      />
                      <Form.Text className={`${errors.email ? 'text-danger' : 'text-muted'} mt-0 mb-0`}>
                        {
                          errors.email?.message
                        }
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className={`col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column 
                      ${errors.email ? 'mt-0' : 'mt-5'}`}>
                        {
                          timesheetReducer.checkoutTraining !== null ? null : 
                          <Button 
                            style={{ width: "100%"}} 
                            variant={`${isCheckIn === null ? 'primary' : isCheckIn === true ? 'success' : 'warning'}`} 
                            disabled={timesheetReducer.isLoading} 
                            type="submit"
                          >
                            {
                              isCheckIn === null ? 'SUBMIT' :
                              isCheckIn === true ? 'CHECK IN' : 'CHECK OUT'
                            }
                            {
                              timesheetReducer.isLoading &&
                              <span className="ml-3 spinner spinner-white"></span>
                            }
                          </Button>
                        }
                    </Form.Group>
                  </>
                  :
                  null
                }
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </CONTAINER>
        </CardBody>
      </Card>
    </div>
  );
}
