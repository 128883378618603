import { methodService, apiService } from './apiService';

export const URL = {
  BASE_VR: '/volunteerRequests',
}

export function apiVRTable(data) {
  return apiService(
    URL.BASE_VR,
    methodService.GET,
    null,
    data
  );
}

export function apiVRDetail(data) {
  return apiService(
    URL.BASE_VR+`/${data.Id}`,
    methodService.GET,
    null,
    null
  )
}

export function apiRegisterVr(data) {
  return apiService(
    URL.BASE_VR+`/${data.Id}/register`,
    methodService.POST,
    null,
    null
  )
}

export function apiCancelVr(data) {
  return apiService(
    URL.BASE_VR+`/${data.Id}/cancel`,
    methodService.PATCH,
    null,
    null
  )
}