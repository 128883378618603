import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionTypes';
import * as vrAction from './volunteerRequestAction';
import * as vrRepo from '../../../datasources/volunteerRequestRepo';

function* getTable(data){
  try {
    const res = yield call(vrRepo.apiVRTable, data.payload);
    yield put(vrAction.vrTableSuccess(res));
  } catch(err) {
    yield put(vrAction.vrTableError(err));
  }
}

function* getDetail(data){
  try {
    const res = yield call(vrRepo.apiVRDetail, data.payload);
    yield put(vrAction.vrDetailSuccess(res));
  } catch(err) {
    yield put(vrAction.vrDetailError(err))
  }
}

function* registerVr(data){
  try {
    const res = yield call(vrRepo.apiRegisterVr, data.payload);
    yield put(vrAction.vrRegisterSuccess(res));
  } catch(err) {
    yield put(vrAction.vrRegisterError(err))
  }
}

function* cancelVr(data){
  try {
    const res = yield call(vrRepo.apiCancelVr, data.payload);
    yield put(vrAction.vrCancelSuccess(res));
  } catch(err) {
    yield put(vrAction.vrCancelError(err))
  }
}

export default function* watchVR() {
  yield all([
    takeLatest(actionType.VOLUNTEER_REQUEST.TABLE_REQUEST, getTable),
    takeLatest(actionType.VOLUNTEER_REQUEST.DETAIL_REQUEST, getDetail),
    takeLatest(actionType.VOLUNTEER_REQUEST.REGISTER_REQUEST, registerVr),
    takeLatest(actionType.VOLUNTEER_REQUEST.CANCEL_REQUEST, cancelVr)
  ])
}