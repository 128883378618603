import React, { useContext, useState, useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useFormContext, Controller } from "react-hook-form";
import styled from 'styled-components';
import * as contactAction from "../../Contact/redux/contactAction";
import ApplicationFormContext from "../context/ApplicationFormContext";
const { FormContext } = ApplicationFormContext;

const CONTAINER = styled.div`
.error {
  border: 1px solid #FF6565;
}`;

const styleSelect = {
  control: base => ({
    ...base,
    borderColor: 'red'
  })
};

export function SignupEmergencyContactForm() {
  const dispatch = useDispatch();
  const formContext = useContext(FormContext);
  const { register, watch, errors } = useFormContext();
  const contactReducer = useSelector(state => state.contact);
  const [optionRelationship, setOptionRelationship] = useState([]);

  useEffect(() => {
    if(contactReducer.isLoading === false && contactReducer.relationship === null){
      dispatch(contactAction.relationshipRequest());
    } else if(contactReducer.relationship !== null){
      let tmpRelationship = [...contactReducer.relationship.data.relationships].map((item) => {
        let _newData = {
          value: item,
          label: item
        }
        return _newData;
      })
      setOptionRelationship(tmpRelationship);
    }
  }, [contactReducer])

  useEffect(() => {
    if(watch('step') > 0){
      formContext.handleChangeField('agreement', 'isAgreeApplication', true);
    }
  }, [watch('step')])

  return (
    <>
      <CONTAINER>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Contact Name <span className="text-danger">*</span></Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Contact Name" 
              name="emergencyName"
              id="emergencyName"
              ref={register}
              className={(errors.emergencyName) && "error"}
            />
            {
              errors.emergencyName &&
              <Form.Text className={'text-danger'}>
                {
                  errors.emergencyName?.message
                }
              </Form.Text>
            }
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Contact Number <span className="text-danger">*</span></Form.Label>
            <Form.Control 
              type="number" 
              placeholder="Contact Number" 
              name="emergencyNumber"
              id="emergencyNumber"
              ref={register}
              className={(errors.emergencyNumber) && "error"}
            />
            {
              errors.emergencyNumber &&
              <Form.Text className={'text-danger'}>
                {
                  errors.emergencyNumber?.message
                }
              </Form.Text>
            }
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Relationship <span className="text-danger">*</span></Form.Label>
            <Controller
              name="emergencyRelationship"
              render={(
                { onChange, onBlur, value, name, ref },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(event) => onChange(event.value)}
                  value={optionRelationship.filter(ar => ar.value === watch('emergencyRelationship'))[0]}
                  isSearchable={true}
                  isLoading={contactReducer.isLoading}
                  options={optionRelationship}
                  styles={errors.emergencyRelationship && styleSelect}
                />
              )}
            />
            {
              errors.emergencyRelationship &&
              <Form.Text className={'text-danger'}>
                {
                  errors.emergencyRelationship?.message
                }
              </Form.Text>
            }
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'col-lg-10 mx-auto mb-0'} controlId="formBasicChecbox" style={{marginTop: 10}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formContext.form.agreement.isAgreeApplication} 
                onChange={(event)=>formContext.handleChangeField('agreement', 'isAgreeApplication', event.target.checked)}
                style={{
                  transform: "scale(1.2)"
                }}
              />
            }
            label="I declare that the particulars stated above are true and correct to the best of my knowledge, and that I have not wilfully suppressed any material fact. I also understand that I am obligated to declare to The National Kidney Foundation if I am related to any patients within my charge." 
          />
        </Form.Group>
      </CONTAINER>
    </>
  );
}