import React from 'react';
import { ProgrammeEventsCard } from './ProgrammeEventsCard';

export function ProgrammeEventsPage({history}){
  const peUIEvent = {
    detailProgrammeEventsRowClick: (data) => {
      history.push({
        pathname: `/programme-events/detail/${data.Id}`,
        data: data
      })
    },
  }
  return(
    <>
      <ProgrammeEventsCard UIEvent={peUIEvent}/>
    </>
  )
}