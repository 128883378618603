import React from 'react';
import { useFormContext } from "react-hook-form";
import { SignupConfidentialityForm } from "../../form/SignupConfidentialityForm";

export function ConfidentialityFormStep(){
  const { watch } = useFormContext();
  const dt = new Date();

  function getCurrentDate(){    
    return dt.getDate();
  }

  function getMonthName(){
    return dt.toLocaleString('default', { month: 'long' });
  }

  function getFullYear(){
    return dt.getFullYear();
  }

  return(
    <>
      <p className="text-center"><strong>Volunteer Confidentiality and Non-Disclosure Agreement</strong></p>
      <p className="text-center"><strong>THIS CONFIDENTIALITY AND NON-DISCLOSURE AGREEMENT</strong></p>
      <p className="text-center">is made on the {getCurrentDate()} day of {getMonthName()} {getFullYear()}.</p>
      <p className="text-center"><strong>BETWEEN</strong></p>
      <p className="text-center"><strong>The National Kidney Foundation (UEN 200104750M)</strong> of 81 Kim Keat Singapore 328836 <strong>(“NKF”)</strong></p>
      <p className="text-center"><strong>AND</strong></p>
      <p className="text-center"><strong>{watch('firstName')+' '+watch('lastName')} <strong>(NRIC/PP No.</strong> {watch('idNumber') !== '' ? watch('idNumber') : 'N/A'}<strong>) (“the Volunteer”)</strong></strong></p>
      <ol type={"A"}>
        <li>
          <p className={'text-justify mr-10'} style={{paddingLeft: 20}}>The Volunteer is (or will become) a volunteer for NKF through, and in the course of the engagement relationship, the Volunteer may learn of, develop and/or receive information which is confidential to NKF.</p>
        </li>
        <li>
          <p className={'text-justify mr-10'} style={{paddingLeft: 20}}>The Volunteer acknowledges the desire and right of NKF to have disclosed to it all ideas, improvements and works generated or developed by the Volunteer in the course of his/her engagement with NKF.</p>
        </li>
        <li>
          <p className={'text-justify mr-10'} style={{paddingLeft: 20}}>NKF and the Volunteer have agreed that the following terms and conditions shall apply to preserve the confidentiality of NKF’s information.</p>
        </li>
      </ol>
      <p className={'ml-8'}><strong>OPERATIVE PROVISIONS</strong></p>
      <>
        <table className={'ml-8 mr-10'}>
          <tbody>
            <tr>
              <td style={{width: 35}}><strong>1.</strong></td>
              <td><strong>CONFIDENTIAL INFORMATION</strong></td>
            </tr>
          </tbody>
        </table>
        <table className={'ml-8 mt-5 mr-10'}>
          <tbody>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>1.1</td>
              <td style={{textAlign: 'justify'}}>Except to the extent that such information is public knowledge or becomes public 	knowledge other than by breach of this Agreement, ‘confidential information’ shall 	mean for the purposes of this Agreement:</td>
            </tr>
          </tbody>
        </table>
      </>
      <>
        <table className={'ml-19 mt-3 mr-10'}>
          <tbody>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>1.1.1</td>
              <td style={{textAlign: 'justify'}}>all information acquired or developed by the Volunteer in the course of his/her 	engagement with NKF;</td>
            </tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>1.1.2</td>
              <td style={{textAlign: 'justify'}}>all information designated as confidential by NKF or otherwise imparted in circumstances of confidence by NKF to the Volunteer; and</td>
            </tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>1.1.3</td>
              <td style={{textAlign: 'justify'}}>any other material or information classifiable in law or equity as confidential information.</td>
            </tr>
          </tbody>
        </table>
      </>
      <>
        <table className={'ml-8 mt-3 mr-10'}>
          <tbody>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>1.2</td>
              <td style={{textAlign: 'justify'}}>The term ‘confidential information’ extends to all forms of storage or representation of the information referred to in clause 1.1 including, but not limited to, spoken works and presentations, printed documents, loose notes, diaries, memoranda, drawings, photographs, electronic, magnetic and optical storage, and computer printouts.</td>
            </tr>
            <tr><td style={{height: 5}}/></tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>1.3</td>
              <td style={{textAlign: 'justify'}}>In consideration of the supply of confidential information by or on behalf of NKF to the Volunteer and in consideration of the Volunteer being engaged or continuing to be engaged by NKF, the Volunteer agrees that he/she will preserve and maintain the confidentiality of the confidential information. </td>
            </tr>
            <tr><td style={{height: 5}}/></tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>1.4</td>
              <td style={{textAlign: 'justify'}}>The Volunteer agrees to disclose to NKF all ideas, improvements and works generated or developed in the course of his/her engagement with NKF.</td>
            </tr>
            <tr><td style={{height: 5}}/></tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>1.5</td>
              <td style={{textAlign: 'justify'}}>The Volunteer shall not disclose or provide or allow to be disclosing or providing, the confidential information otherwise than is strictly necessary to enable the Volunteer to perform his /her duties of engagement.</td>
            </tr>
            <tr><td style={{height: 5}}/></tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>1.6</td>
              <td style={{textAlign: 'justify'}}>If the Volunteer needs to disclose the confidential information to a third party to perform his/her duties in the course of his/her engagement with NKF, the Volunteer may do so only with the express written consent of NKF which may require the Volunteer to procure a deed from the third party in favour of NKF on such terms as NKF deems fit.</td>
            </tr>
            <tr><td style={{height: 5}}/></tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>1.7</td>
              <td style={{textAlign: 'justify'}}>The Volunteer shall use, and shall ensure that any person to whom the confidential information is disclosed uses all reasonable endeavours and precautions to protect and preserve the confidentiality of the confidential information.</td>
            </tr>
            <tr><td style={{height: 5}}/></tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>1.8</td>
              <td style={{textAlign: 'justify'}}>The Volunteer acknowledges that the confidential information remains at all times the exclusive property of NKF.</td>
            </tr>
          </tbody>
        </table>
      </>
      <>
        <table className={'ml-8 mr-10'}>
          <tbody>
            <tr>
              <td style={{width: 35}}><strong>2.</strong></td>
              <td style={{textAlign: 'justify'}}><strong>SECURITY, DUPLICATION AND RETURN OF MATERIAL</strong></td>
            </tr>
            <tr><td style={{height: 5}}/></tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>2.1</td>
              <td style={{textAlign: 'justify'}}>The Volunteer shall secure and protect the confidential information from unauthorised disclosure, access or use.</td>
            </tr>
            <tr><td style={{height: 5}}/></tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>2.2</td>
              <td style={{textAlign: 'justify'}}>The Volunteer shall not remove the confidential information from the premises of NKF, if applicable, without the written consent of NKF.</td>
            </tr>
            <tr><td style={{height: 5}}/></tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>2.3</td>
              <td style={{textAlign: 'justify'}}>The Volunteer shall return all copies of the materials including extracts or summaries, however embodied or recorded, referred to in clause 1 to NKF:</td>
            </tr>
          </tbody>
        </table>
      </>
      <>
        <table className={'ml-19 mt-3 mr-10'}>
          <tbody>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>(a)</td>
              <td style={{textAlign: 'justify'}}>immediately on demand by NKF; or</td>
            </tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>(b)</td>
              <td style={{textAlign: 'justify'}}>upon termination or completion of his/her engagement relationship with NKF.</td>
            </tr>
          </tbody>
        </table>
      </>
      <>
        <table className={'ml-8 mr-10'}>
          <tbody>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>3.1</td>
              <td style={{textAlign: 'justify'}}>The Volunteer agrees to indemnify NKF for all and any loss suffered as a 	result of a breach of this Agreement by the Volunteer or for any disclosure of the confidential information by a person provided with the confidential information by the Volunteer.</td>
            </tr>
            <tr><td style={{height: 5}}/></tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>4.1</td>
              <td style={{textAlign: 'justify'}}>The Volunteer acknowledges and agrees that his/her obligations pursuant to this Agreement shall survive the return of all copies of the confidential information and the termination or completion of the engagement relationship between the Volunteer and NKF, and shall continue until such time as the confidential information becomes public knowledge other than by breach of this Agreement. </td>
            </tr>
            <tr><td style={{height: 5}}/></tr>
            <tr>
              <td style={{width: 35, verticalAlign: 'top'}}>5.1</td>
              <td style={{textAlign: 'justify'}}>If any provision of this Agreement is held invalid, unenforceable or illegal for any 	reason, this Agreement shall remain otherwise in full force and effect apart from such 	provision which shall be deemed deleted.</td>
            </tr>
          </tbody>
        </table>
      </>
      <SignupConfidentialityForm/>
    </>
  )
}