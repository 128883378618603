import React, { useEffect, useState } from 'react';
import { Card } from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import styled from "@emotion/styled";
import * as calendarAction from "../redux/calendarAction";
import * as peAction from "../../ProgrammeEvents/redux/programmeEventsActions";
import * as vrAction from "../../VolunteerRequest/redux/volunteerRequestAction";
import "./Calendar.css";

export function CalendarCard(){
  const dispatch = useDispatch();
  const history = useHistory();
  const calendarReducer = useSelector(state => state.calendar, shallowEqual);
  const peReducer = useSelector(state => state.pe, shallowEqual);
  const vrReducer = useSelector(state => state.vr, shallowEqual);
  const [dataCalendar, setDataCalendar] = useState([]);

  const StyleWrapper = styled.div`
  .fc-header-toolbar {
    margin-bottom: 100 !important;
    z-index: 100;
  }
  .fc-today-button {
    visible: hidden;
  }
`

  useEffect(() => {
    dispatch(calendarAction.eventRegisteredRequest());
    dispatch(calendarAction.peRegisteredRequest());
    dispatch(peAction.peTableRequest());
    dispatch(vrAction.vrTableRequest());
  }, [])

  useEffect(() => {
    let dataEvent = [];
    let dataPe = [];
    let dataPeTable = [];
    let dataVrTable = [];
    let dataAll = [];
    let finalData = [];
    if(
        calendarReducer.isLoading === false && calendarReducer.isError === false && 
        calendarReducer.eventRegistered !== null && calendarReducer.peRegistered !== null &&
        peReducer.isLoading === false && peReducer.isError === false && peReducer.peTable !== null &&
        vrReducer.isLoading === false && vrReducer.isError === false && vrReducer.vrTable !== null
      ){
        dataEvent = calendarReducer.eventRegistered.data;
        dataPe = calendarReducer.peRegistered.data;
        dataPeTable = [...peReducer.peTable.data.programmeEvents].map((pe) => {
          if(dataPe.length > 0){
            if([...dataPe].filter(e => e.PEId !== pe.Id).length > 0){
              let tmpObj = {
                Id: pe.Id,
                PEId: pe.Id,
                PEName: pe.Name,
                Start_Date__c: pe.Start_Date_Time__c.substring(0,10),
                type: 'PE',
                Status: "tablePE",
                backgroundColor: "#3b42b6"
              }
              return tmpObj;
            }
          } else {
            let tmpObj = {
              Id: pe.Id,
              PEId: pe.Id,
              PEName: pe.Name,
              Start_Date__c: pe.Start_Date_Time__c.substring(0,10),
              type: 'PE',
              Status: "tablePE",
              backgroundColor: "#3b42b6"
            }
            return tmpObj;
          }
        }).filter(ar => ar !== undefined);
        dataVrTable = [...vrReducer.vrTable.data.volunteerRequests].filter(function(arVrTable) {
          return dataEvent.filter(function(arVrEvent){
            return arVrEvent.VRId == arVrTable.Id;
          }).length == 0
        }).map((vr) => {
          let tmpObj = {
            Id: vr.Id,
            VRId: vr.Id,
            VRName: vr.Name,
            Start_Date__c: vr.Start_Date__c,
            type: 'VR',
            Status: "tableVR",
            backgroundColor: "purple"
          }
          return tmpObj;
        });
        dataAll = dataEvent.concat(dataPe, dataPeTable, dataVrTable);
        dataAll.map((ev) => {
          let tmpObj = {
            id: ev.Id,
            idType: ev.VRId ? ev.VRId : ev.PEId,
            title: ev.VRName ? ev.VRName : ev.PEName,
            date: ev.Start_Date__c,
            type: ev.VRName ? 'VR' : 'PE',
            textColor: ev.Status === "tableVR" ? "white" : "white",
            backgroundColor: ev.backgroundColor ? ev.backgroundColor : ev.Status === "Registered" || ev.Status === "Accepted" ? '#ffbf00' : 'blue',
            borderColor: ev.backgroundColor ? ev.backgroundColor : ev.Status === "Accepted"? 'red' : '#ffbf00',
            className: ev.Status === "Accepted" && 'moreBorder',
            status: ev.Status === "Accepted" ? 0 : ev.Status === "Registered" ? 1 : 2
          }
          finalData.push(tmpObj);
        })
        setDataCalendar(finalData);
    }
  }, [calendarReducer, peReducer, vrReducer])

  function handleTooltipOpen(props) {
    if(props?.extendedProps?.type === "VR"){
      history.push(`/volunteer-request/detail/${props?.extendedProps?.idType}`);
    } else {
      history.push(`/programme-events/detail/${props?.extendedProps?.idType}`);
    }
  }

  const LegendCalendar = ({color, name, borderColor}) => {
    return(
      <div className="row d-flex align-items-center" style={{width: "30%"}}>
        <div 
          style={{
            width: 10,
            height: 10,
            borderRadius: 3,
            backgroundColor: color,
            marginRight: 5,
            border: borderColor ? `1px solid ${borderColor}` : ''
          }}
        />
        {name}
      </div>
    )
  }

  return(
    <>
      <Card>
        <div className="p-10">
          <div className="ml-5 xl-12 lg-12 d-none d-sm-block d-sm-none d-md-block" style={{position: "absolute", left: 230, width: "100%"}}>
            <div className="row" style={{width: "100%"}}>
              <LegendCalendar name={"Accepted Activities"} color={"#ffbf00"} borderColor={"red"}/>
              <LegendCalendar name={"Upcoming Activities"} color={"#3b42b6"}/> 
            </div>
            <div className="row" style={{width: "100%"}}>
              <LegendCalendar name={"Registered Activities/Event"} color={"#ffbf00"}/>  
              <LegendCalendar name={"Volunteer Opportunities"} color={"purple"}/>  
            </div>
          </div>
          <StyleWrapper>
            <FullCalendar
              plugins={[ dayGridPlugin ]}
              initialView="dayGridMonth"
              firstDay={1}
              events={dataCalendar}
              views={{
                dayGridMonth: {
                  dayMaxEventRows: 3,
                },
              }}
              headerToolbar={{
                left: 'title',
                right: 'prev next'
              }}
              eventClick={(arg) => {
                handleTooltipOpen(arg.event);
              }}
              eventOrder={["status"]}
            />
          </StyleWrapper>
        </div>
      </Card>
    </>
  )
}