import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationEvent: null,
  invitationSetResponse: null,
  invitationCheckin: null,
  participantInfo: null,
  registerProgrammeEmail: null,
  cancelProgrammeEmail: null,
  assignedVrInfo: null,
  registerVrEmail: null,
  cancelVrEmail: null,
}

const eventRetrieveRequest = state => ({
  ...state,
  isLoading: true
})
const eventRetrieveSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationEvent: payload
})
const eventRetrieveError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const eventRetrieveReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationEvent: null
})


const setResponseRequest = state => ({
  ...state,
  isLoading: true
})
const setResponseSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationSetResponse: payload
})
const setResponseError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const setResponseReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationSetResponse: null
})

const checkinRequest = state => ({
  ...state,
  isLoading: true
})
const checkinSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationCheckin: payload
})
const checkinError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const checkinReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationCheckin: null
})

const participantInfoRequest = state => ({
  ...state,
  isLoading: true
})
const participantInfoSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  participantInfo: payload
})
const participantInfoError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const participantInfoReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  participantInfo: null
})

const registerProgrammeEmailRequest = state => ({
  ...state,
  isLoading: true
})
const registerProgrammeEmailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  registerProgrammeEmail: payload
})
const registerProgrammeEmailError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const registerProgrammeEmailReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  registerProgrammeEmail: null
})

const cancelProgrammeEmailRequest = state => ({
  ...state,
  isLoading: true
})
const cancelProgrammeEmailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  cancelProgrammeEmail: payload
})
const cancelProgrammeEmailError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const cancelProgrammeEmailReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  cancelProgrammeEmail: null
})

const assignedVrInfoRequest = state => ({
  ...state,
  isLoading: true
})
const assignedVrInfoSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  assignedVrInfo: payload
})
const assignedVrInfoError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const assignedVrInfoReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  assignedVrInfo: null
})

const registerVrEmailRequest = state => ({
  ...state,
  isLoading: true
})
const registerVrEmailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  registerVrEmail: payload
})
const registerVrEmailError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const registerVrEmailReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  registerVrEmail: null
})

const cancelVrEmailRequest = state => ({
  ...state,
  isLoading: true
})
const cancelVrEmailSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  cancelVrEmail: payload
})
const cancelVrEmailError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const cancelVrEmailReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  cancelVrEmail: null
})

export const invitationReducer = (state = initialState, action) => {
  switch (action.type ) {
    case actionType.INVITATION.EVENT_RETRIEVE_REQUEST:
      return eventRetrieveRequest(state, action.payload);
    case actionType.INVITATION.EVENT_RETRIEVE_SUCCESS:
      return eventRetrieveSuccess(state, action.payload);
    case actionType.INVITATION.EVENT_RETRIEVE_ERROR:
      return eventRetrieveError(state, action.payload);
    case actionType.INVITATION.EVENT_RETRIEVE_RESET:
      return eventRetrieveReset(state, action.payload);

    case actionType.INVITATION.SET_RESPONSE_REQUEST:
      return setResponseRequest(state, action.payload);
    case actionType.INVITATION.SET_RESPONSE_SUCCESS:
      return setResponseSuccess(state, action.payload);
    case actionType.INVITATION.SET_RESPONSE_ERROR:
      return setResponseError(state, action.payload);
    case actionType.INVITATION.SET_RESPONSE_RESET:
      return setResponseReset(state, action.payload);      

    case actionType.INVITATION.CHECKIN_REQUEST:
      return checkinRequest(state, action.payload);
    case actionType.INVITATION.CHECKIN_SUCCESS:
      return checkinSuccess(state, action.payload);
    case actionType.INVITATION.CHECKIN_ERROR:
      return checkinError(state, action.payload);
    case actionType.INVITATION.CHECKIN_RESET:
      return checkinReset(state, action.payload);

    case actionType.INVITATION.PARTICIPANT_INFO_REQUEST:
      return participantInfoRequest(state, action.payload);
    case actionType.INVITATION.PARTICIPANT_INFO_SUCCESS:
      return participantInfoSuccess(state, action.payload);
    case actionType.INVITATION.PARTICIPANT_INFO_ERROR:
      return participantInfoError(state, action.payload);
    case actionType.INVITATION.PARTICIPANT_INFO_RESET:
      return participantInfoReset(state, action.payload);

    case actionType.INVITATION.REGISTER_PROGRAMME_EMAIL_REQUEST:
      return registerProgrammeEmailRequest(state, action.payload);
    case actionType.INVITATION.REGISTER_PROGRAMME_EMAIL_SUCCESS:
      return registerProgrammeEmailSuccess(state, action.payload);
    case actionType.INVITATION.REGISTER_PROGRAMME_EMAIL_ERROR:
      return registerProgrammeEmailError(state, action.payload);
    case actionType.INVITATION.REGISTER_PROGRAMME_EMAIL_RESET:
      return registerProgrammeEmailReset(state, action.payload);

    case actionType.INVITATION.CANCEL_PROGRAMME_EMAIL_REQUEST:
      return cancelProgrammeEmailRequest(state, action.payload);
    case actionType.INVITATION.CANCEL_PROGRAMME_EMAIL_SUCCESS:
      return cancelProgrammeEmailSuccess(state, action.payload);
    case actionType.INVITATION.CANCEL_PROGRAMME_EMAIL_ERROR:
      return cancelProgrammeEmailError(state, action.payload);
    case actionType.INVITATION.CANCEL_PROGRAMME_EMAIL_RESET:
      return cancelProgrammeEmailReset(state, action.payload);

    case actionType.INVITATION.ASSIGNEDVR_INFO_REQUEST:
      return assignedVrInfoRequest(state, action.payload);
    case actionType.INVITATION.ASSIGNEDVR_INFO_SUCCESS:
      return assignedVrInfoSuccess(state, action.payload);
    case actionType.INVITATION.ASSIGNEDVR_INFO_ERROR:
      return assignedVrInfoError(state, action.payload);
    case actionType.INVITATION.ASSIGNEDVR_INFO_RESET:
      return assignedVrInfoReset(state, action.payload);

    case actionType.INVITATION.REGISTER_VR_EMAIL_REQUEST:
      return registerVrEmailRequest(state, action.payload);
    case actionType.INVITATION.REGISTER_VR_EMAIL_SUCCESS:
      return registerVrEmailSuccess(state, action.payload);
    case actionType.INVITATION.REGISTER_VR_EMAIL_ERROR:
      return registerVrEmailError(state, action.payload);
    case actionType.INVITATION.REGISTER_VR_EMAIL_RESET:
      return registerVrEmailReset(state, action.payload);

    case actionType.INVITATION.CANCEL_VR_EMAIL_REQUEST:
      return cancelVrEmailRequest(state, action.payload);
    case actionType.INVITATION.CANCEL_VR_EMAIL_SUCCESS:
      return cancelVrEmailSuccess(state, action.payload);
    case actionType.INVITATION.CANCEL_VR_EMAIL_ERROR:
      return cancelVrEmailError(state, action.payload);
    case actionType.INVITATION.CANCEL_VR_EMAIL_RESET:
      return cancelVrEmailReset(state, action.payload);
  
    default:
      return state;
  }
};