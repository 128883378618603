import React from 'react';
import { SignupParticularForm } from '../../form/SignupPersonalParticularsForm';
import { SignupContactDetailForm } from '../../form/SignupContactDetailForm';
import { SignupEmergencyContactForm } from '../../form/SignupEmergencyContactForm';
import { SignupPersonalDeclarationForm } from "../../form/SignupPersonalDeclarationForm";

export function ApplicationFormStep(){
  return (
    <>
      <ul className="nav nav-tabs nav-tabs-line " role="tablist">
        <li className="nav-item" onClick={()=>null}>
          <a
            className={`nav-link basic active`}
            data-toggle="tab"
            role="tab"
            href="#personal"
          >
            Personal Particulars
          </a>
        </li>
      </ul>
      <div className="mt-5">
        <SignupParticularForm/>
      </div>
      <ul className="nav nav-tabs nav-tabs-line " role="tablist">
        <li className="nav-item">
          <a
            className={`nav-link basic active`}
            data-toggle="tab"
            role="tab"
            href="#contact"
          >
            Contact Details
          </a>
        </li>
      </ul>
      <div className="mt-5">
        <SignupContactDetailForm/>
      </div>
      <ul className="nav nav-tabs nav-tabs-line " role="tablist">
        <li className="nav-item">
          <a
            className={`nav-link basic active`}
            data-toggle="tab"
            role="tab"
            href="#declaration"
          >
            Personal Declaration
          </a>
        </li>
      </ul>
      <div className="mt-5">
        <SignupPersonalDeclarationForm/>
      </div>
      <ul className="nav nav-tabs nav-tabs-line " role="tablist">
        <li className="nav-item">
          <a
            className={`nav-link basic active`}
            data-toggle="tab"
            role="tab"
            href="#emergency"
          >
            Emergency Contact
          </a>
        </li>
      </ul>
      <div className="mt-5">
        <SignupEmergencyContactForm/>
      </div>
    </>
  )
}