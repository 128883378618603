import React, { useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as signupAction from "../redux/signupAction";

const CONTAINER = styled.div`
.error {
  border: 1px solid #FF6565;
}`;

const validationSchema = Yup.object().shape({
  verificationCode: Yup.string()
  .length(6, 'Code should be 6 digit')
  .required('Field is required')
})

export function SignupModalVerificationCode({show, onHide}){
  const dispatch = useDispatch();
  const history = useHistory();
  const signupReducer = useSelector(state => state.signup);
  const { register, handleSubmit, setError, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      verificationCode: ''
    }
  });

  useEffect(() => {
    if(show){
      sendVerificationCode();
    }
  }, [show])

  useEffect(() => {
    if(signupReducer.isLoading === false && signupReducer.isErrorForm === false && signupReducer.signupVerificationCodeForm !== null){
      dispatch(signupAction.verificationCodeReset());
      dispatch(signupAction.verificationCodeFormReset());
      dispatch(signupAction.verificationReset());
      history.push('/signup-result-form')
    } else if(signupReducer.isLoading === false && signupReducer.isErrorForm === true){
      setError("verificationCode", {
        type: "manual",
        message: signupReducer.errorMsg.message
      });
    }
  }, [signupReducer])

  function sendVerificationCode(){
    dispatch(signupAction.verificationCodeRequest());
  }

  const submitData = data => {
    dispatch(signupAction.verificationCodeFormRequest({
      verificationCode: data.verificationCode
    }))
  }

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      backdrop="static"
    >
      <Form onSubmit={handleSubmit(submitData)}>
      <Modal.Header closeButton
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter text-center">
          Two-Factor Authentication
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CONTAINER>
          <Form.Group className={'col-lg-8 mx-auto'}>
            <Form.Label>Verification Code</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="XXXXXX" 
              name="verificationCode"
              ref={register}
              className={errors?.verificationCode && "error"}
            />
            <Form.Label className="text-danger">{errors?.verificationCode?.message}</Form.Label>
          </Form.Group>
          <Form.Group className={'col-lg-10 mx-auto'}>
            <Form.Label className="text-center">
              The Verification Code has been sent to your designated email address. Should you not be able to find it in your inbox, kindly check your junk/spam mail. Thank you. 
            </Form.Label>
          </Form.Group>
        </CONTAINER>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'col-lg-12 d-flex justify-content-center align-items-center'}>
          <Col md='auto'>
            <Button 
              style={{minWidth: '10rem'}} 
              variant="primary" 
              type="submit"
              disabled={
                signupReducer.isLoadingVerificationCode === true ? true : 
                signupReducer.isLoading === true ? true :
                false
              }
            >
              Submit
              {
                signupReducer.isLoading &&
                <span className="ml-3 spinner spinner-white"></span>
              }
            </Button>
          </Col>
          <Col md='auto'>
            <Button 
              style={{minWidth: '10rem'}} 
              size={'sm'} 
              variant="warning" 
              onClick={()=>sendVerificationCode()}
              disabled={
                signupReducer.isLoadingVerificationCode === true ? true :
                signupReducer.isLoading === true ? true :
                false
              }
            >
              Resend Code
              {
                signupReducer.isLoadingVerificationCode &&
                <span className="ml-1 spinner spinner-white"></span>
              }
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
      </Form>
    </Modal>
  );
}