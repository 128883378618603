export const CALENDAR = {
  EVENT_REGISTERED_REQUEST: 'CALENDAR_EVENT_REGISTERED_REQUEST',
  EVENT_REGISTERED_SUCCESS: 'CALENDAR_EVENT_REGISTERED_SUCCESS',
  EVENT_REGISTERED_ERROR: 'CALENDAR_EVENT_REGISTERED_ERROR',
  EVENT_REGISTERED_RESET: 'CALENDAR_EVENT_REGISTERED_RESET',

  PE_REGISTERED_REQUEST: 'CALENDAR_PE_REGISTERED_REQUEST',
  PE_REGISTERED_SUCCESS: 'CALENDAR_PE_REGISTERED_SUCCESS',
  PE_REGISTERED_ERROR: 'CALENDAR_PE_REGISTERED_ERROR',
  PE_REGISTERED_RESET: 'CALENDAR_PE_REGISTERED_RESET'
}