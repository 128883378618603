import React, { useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as authAction from "../redux/authAction";

const CONTAINER = styled.div`
  .error {
    border: 1px solid #ff6565;
  }
`;

const validationSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .length(6, "Code should be 6 digit")
    .required("Field is required"),
});

export function ModalFA({ show, onHide }) {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.auth);
  const { register, handleSubmit, setError, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      verificationCode: "",
    },
  });

  useEffect(() => {
    if (
      authReducer.isLoadingVerificationCode === false &&
      authReducer.isErrorVerificationCode === false &&
      authReducer.faInfo !== null
    ) {
      dispatch(authAction.setCredential(authReducer.faInfo));
      dispatch(authAction.faReset());

      if (
        typeof window !== "undefined" &&
        typeof window.perfec !== "undefined"
      ) {
        window.perfec.register_once({
          $contact_id: authReducer?.loginInfo?.data?.id,
        });
        window.perfec.people.set_once({
          sessWinId: window.perfec.getSessionAndWindowId && window.perfec.getSessionAndWindowId(),
          contactId: authReducer?.loginInfo?.data?.id,
        });
        window.perfec.people.set_once({
          contact: authReducer?.faInfo?.data?.contact
        });
      }
    } else if (
      authReducer.isLoadingVerificationCode === false &&
      authReducer.isErrorVerificationCode === true
    ) {
      setError("verificationCode", {
        type: "manual",
        message: authReducer.errorMsg.message,
      });
    }
  }, [authReducer]);

  const submitData = (data) => {
    dispatch(
      authAction.faRequest({
        contactId: authReducer?.loginInfo?.data?.id,
        verificationCode: data.verificationCode,
      })
    );
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      backdrop="static"
    >
      <Form onSubmit={handleSubmit(submitData)}>
        <Modal.Header
          closeButton
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter text-center">
            Two-Factor Authentication
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CONTAINER>
            <Form.Group className={"col-lg-8 mx-auto"}>
              <Form.Label>Verification Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="XXXXXX"
                name="verificationCode"
                ref={register}
                className={errors?.verificationCode && "error"}
              />
              <Form.Label className="text-danger">
                {errors?.verificationCode?.message}
              </Form.Label>
            </Form.Group>
            <Form.Group className={"col-lg-10 mx-auto"}>
              <Form.Label className="text-center">
                The Verification Code has been sent to your designated email
                address. Should you not be able to find it in your inbox, kindly
                check your junk/spam mail. Thank you.
              </Form.Label>
            </Form.Group>
          </CONTAINER>
        </Modal.Body>
        <Modal.Footer>
          <Row
            className={
              "col-lg-9 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center align-self-center mx-auto"
            }
          >
            <Col md="auto" sm={5} xs={5} className={"mx-auto"}>
              <Button
                style={{ minWidth: "10rem" }}
                variant="primary"
                type="submit"
                disabled={authReducer.isLoadingVerificationCode}
              >
                Submit
                {authReducer.isLoadingVerificationCode && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </Button>
            </Col>
            <Col md="auto" sm={5} xs={5} className={"mx-auto"}>
              <Button
                style={{ minWidth: "10rem" }}
                variant="secondary"
                onClick={onHide}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
