import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useFormContext, Controller } from "react-hook-form";
import styled from 'styled-components';

const CONTAINER = styled.div`
.error {
  border: 1px solid #FF6565;
}`;

export function SignupPrefferedProgrammeForm(){
  const { watch, errors } = useFormContext();
  return(
    <>
      <CONTAINER>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={'col-lg-12 mb-0 pb-0'}>
            <Controller
              name="preferred[isAdministrative]"
              render={(
                { onChange },
              ) => (
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={watch('preferred[isAdministrative]')}
                      onChange={(event)=>onChange(event.target.checked)} 
                      style={{
                        marginTop: -20
                      }}
                    />
                  }
                  label={
                    <div onClick={()=>onChange(!watch('preferred[isAdministrative]'))}>
                      <Form.Label className={'font-weight-bold mb-0'}>Administrative support at NKF Centre</Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0'}>Help us achieve operational & administrative efficiencies</Form.Label>
                    </div>
                  }
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-12 mb-0 pb-0'}>
            <Controller
              name="preferred[isBfriending]"
              render={(
                { onChange },
              ) => (
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={watch('preferred[isBfriending]')}
                      onChange={(event)=>onChange(event.target.checked)} 
                      style={{
                        marginTop: -20
                      }}
                    />
                  }
                  label={
                    <div onClick={()=>onChange(!watch('preferred[isBfriending]'))}>
                      <Form.Label className={'font-weight-bold mb-0'}>Befriending Programme </Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0'}>Walk the journey with our patients by providing them with your friendship and support </Form.Label>
                    </div>
                  }
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-12 mb-0 pb-0'}>
            <Controller
              name="preferred[isEnrichment]"
              render={(
                { onChange },
              ) => (
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={watch('preferred[isEnrichment]')}
                      onChange={(event)=>onChange(event.target.checked)} 
                      style={{
                        marginTop: -20
                      }}
                    />
                  }
                  label={
                    <div onClick={()=>onChange(!watch('preferred[isEnrichment]'))}>
                      <Form.Label className={'font-weight-bold mb-0'}>Enrichment Programme</Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0 mb-0'}>Share your passion or hobby with our patients & fellow volunteers </Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0 mb-0'}>e.g. digital skills workshop, art & craft, cooking classes, gardening workshops, etc </Form.Label>
                    </div>
                  }
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-12 mb-0 pb-0'}>
          <Controller
              name="preferred[isEvent]"
              render={(
                { onChange },
              ) => (
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={watch('preferred[isEvent]')}
                      onChange={(event)=>onChange(event.target.checked)} 
                      style={{
                        marginTop: -20
                      }}
                    />
                  }
                  label={
                    <div onClick={()=>onChange(!watch('preferred[isEvent]'))}>
                      <Form.Label className={'font-weight-bold mb-0'}>Events</Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0'}>Render your help during our events such as being a booth assistant, usher, etc</Form.Label>
                    </div>
                  }
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-12 mb-0 pb-0'}>
            <Controller
              name="preferred[isHope]"
              render={(
                { onChange },
              ) => (
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={watch('preferred[isHope]')}
                      onChange={(event)=>onChange(event.target.checked)} 
                      style={{
                        marginTop: -20
                      }}
                    />
                  }
                  label={
                    <div onClick={()=>onChange(!watch('preferred[isHope]'))}>
                      <Form.Label className={'font-weight-bold mb-0'}>Grains of Hope Programme</Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0'}>A home intervention programme to befriend our patients and help walk them out of social isolation</Form.Label>
                    </div>
                  }
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-12 mb-0 pb-0'}>
            <Controller
              name="preferred[isHomeAid]"
              render={(
                { onChange },
              ) => (
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={watch('preferred[isHomeAid]')}
                      onChange={(event)=>onChange(event.target.checked)} 
                      style={{
                        marginTop: -20
                      }}
                    />
                  }
                  label={
                    <div onClick={()=>onChange(!watch('preferred[isHomeAid]'))}>
                      <Form.Label className={'font-weight-bold mb-0'}>Home Aid (House Painting & House Cleaning)</Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0'}>Help provide our patients with a clean & comfortable living environment</Form.Label>
                    </div>
                  }
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-12 mb-0 pb-0'}>
            <Controller
              name="preferred[isGuide]"
              render={(
                { onChange },
              ) => (
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={watch('preferred[isGuide]')}
                      onChange={(event)=>onChange(event.target.checked)} 
                      style={{
                        marginTop: -20
                      }}
                    />
                  }
                  label={
                    <div onClick={()=>onChange(!watch('preferred[isGuide]'))}>
                      <Form.Label className={'font-weight-bold mb-0'}>Kidney Discovery Centre Guide</Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0'}>Join us as a guide and spread awareness about kidney health</Form.Label>
                    </div>
                  }
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-12 mb-0 pb-0'}>
            <Controller
              name="preferred[isOfficer]"
              render={(
                { onChange },
              ) => (
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={watch('preferred[isOfficer]')}
                      onChange={(event)=>onChange(event.target.checked)} 
                      style={{
                        marginTop: -20
                      }}
                    />
                  }
                  label={
                    <div onClick={()=>onChange(!watch('preferred[isOfficer]'))}>
                      <Form.Label className={'font-weight-bold mb-0'}>Orientation Officer</Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0'}>Join us in inducting new volunteers </Form.Label>
                    </div>
                  }
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-12 mb-0 pb-0'}>
            <Controller
              name="preferred[isEscort]"
              render={(
                { onChange },
              ) => (
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={watch('preferred[isEscort]')}
                      onChange={(event)=>onChange(event.target.checked)} 
                      style={{
                        marginTop: -20
                      }}
                    />
                  }
                  label={
                    <div onClick={()=>onChange(!watch('preferred[isEscort]'))}>
                      <Form.Label className={'font-weight-bold mb-0'}>Patient Escort</Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0'}>Help patients with mobility difficulties & limited caregiver support gain access to medical treatments</Form.Label>
                    </div>
                  }
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-12 mb-0 pb-0'}>
            <Controller
              name="preferred[isTranslation]"
              render={(
                { onChange },
              ) => (
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={watch('preferred[isTranslation]')}
                      onChange={(event)=>onChange(event.target.checked)} 
                      style={{
                        marginTop: -20
                      }}
                    />
                  }
                  label={
                    <div onClick={()=>onChange(!watch('preferred[isTranslation]'))}>
                      <Form.Label className={'font-weight-bold mb-0'}>Translation</Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0'}>Help us reach out to all members of the community with your translation skills</Form.Label>
                    </div>
                  }
                />
              )}
            />
          </Form.Group>
          <Form.Group className={'col-lg-12 mb-0 pb-0'}>
            <Controller
              name="preferred[isProfessional]"
              render={(
                { onChange },
              ) => (
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={watch('preferred[isProfessional]')}
                      onChange={(event)=>onChange(event.target.checked)} 
                      style={{
                        marginTop: -20
                      }}
                    />
                  }
                  label={
                    <div onClick={()=>onChange(!watch('preferred[isProfessional]'))}>
                      <Form.Label className={'font-weight-bold mb-0'}>Professional Skills</Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0 mb-0'}>Volunteer your skill to help us achieve cost & operational efficiency</Form.Label>
                      <br/>
                      <Form.Label className={'font-italic mt-0 mb-0'}>e.g. design work, photography, emcee, etc</Form.Label>
                    </div>
                  }
                />
              )}
            />
          </Form.Group>
        </Form.Group>
        <Form.Label className={'text-danger'}>{errors?.preferred?.message}</Form.Label>
      </CONTAINER>
    </>
  )
}