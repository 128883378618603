import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { SignupVolunteerGuidelinesForm } from "../../form/SignupVolunteerGuidelinesForm";

export function VolunteerGuidelinesFormStep(){
  return(
    <>
      <h5 className={'font-weight-bolder text-center mb-5'}>VOLUNTEER GUIDELINES</h5>
      <>
        <Form.Group as={Row} className={'col-lg-12 mx-auto mb-0'}>
          <ol type={"A"} className={'font-weight-bolder'}>
            <li>
              <p className={'text-justify font-weight-bolder'}>General Guidelines</p>
              <ul>
                <li>
                  <p className={'text-justify font-weight-normal'}>All Volunteers must attend the New Volunteers Orientation programme before taking part in any event unless exempted by the Volunteer Coordinator.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>All Volunteers should turn up on time on the day of the activity and stay throughout the duration of the activity. Volunteers should promptly leave the activity site after being debriefed by the Volunteer Coordinator.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>All Volunteers must maintain the confidentiality of all information received as Volunteers, regardless of the subject matter. These would include, amongst others, the names and photographs of any beneficiaries.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>All Volunteers should serve to the best of your ability, in a respectful, professional and cooperative manner during the activity.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>All Volunteers must refrain from wearing skimpy clothes and clothes with profanity.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>All Volunteers should look after your mental and emotional health. Should you be affected by complex feelings due to reasons such as death of a patient, please speak to the Volunteer Coordinator to seek counselling and other support, if required.</p>
                </li>
              </ul>
            </li>
            <li>
              <p className={'text-justify font-weight-bolder'}>Dialysis Centre/NKF Premises Behavioural Etiquette</p>
              <ul>
                <li>
                  <p className={'text-justify font-weight-normal'}>No running, shouting, fighting etc. No fiddling with the dialysis machines, tubes, needles etc. No wandering around or away from the group.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Be prepared with the necessary materials for activities before executing any activities with patients.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Maintain a low noise threshold and to be considerate to other patients.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Be sensitive with the patients’ responses as some patients might choose to rest and not participate in the activities.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Sanitise your hands before entering and upon leaving the dialysis centre.</p>
                </li>
              </ul>
            </li>
            <li>
              <p className={'text-justify font-weight-bolder'}>Home Visits Guidelines</p>
              <ul>
                <li>
                  <p className={'text-justify font-weight-normal'}>Inform the Programme Coordinator before visiting the patient.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Give the patient a call to arrange for a visit before visiting the patient.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Visit the patient in at least a group of two.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Do not move and/or touch patient’s items without patient’s consent.</p>
                </li>
              </ul>
            </li>
            <li>
              <p className={'text-justify font-weight-bolder'}>Patient Escort</p>
              <ul>
                <li>
                  <p className={'text-justify font-weight-normal'}>Arrive on time to pick up the patient.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Do not bring patient to locations other than those assigned by Volunteer Coordinator.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Be respectful to the patient’s needs.</p>
                </li>
              </ul>
            </li>
            <li>
              <p className={'text-justify font-weight-bolder'}>Volunteers are to avoid:</p>
              <ul>
                <li>
                  <p className={'text-justify font-weight-normal'}>Giving any form of financial help or receive any monetary benefits from patients.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Visiting a patient at his/her place of work or residence without the consent from the NKF Programme Coordinator or alone.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Using your volunteering participation to promote partisan politics, religious matters etc.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Sharing your religious beliefs without the patient’s permission.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Giving medical advice or health products recommendation.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Releasing any information about patient(s) to any third parties, outsiders or media, be it names or photographs, without NKF Programme Coordinator’s consent.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Sharing your personal information (contact, address etc) with the patients.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Making any promises to patients.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Soliciting funds under the name of NKF for personal interest.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Misrepresenting NKF for your personal benefit.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Discriminatory or racist statements or behaviours.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Sexual harassment, (eg. jokes, innuendos, insults, sexist remarks, displays of derogatory or pornographic pictures, leering, touching or kissing).</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>The use of any cigarettes, alcohol, or illegal drugs/substances.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Any unlawful or inappropriate activity/behaviour.</p>
                </li>
              </ul>
            </li>
            <li>
              <p className={'text-justify font-weight-bolder'}>For the safety of Volunteers, please take note of the following:</p>
              <ul>
                <li>
                  <p className={'text-justify font-weight-normal'}>Observe all health and safety regulations during volunteering service.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Stop all activities when you feel unsafe, unwell or are injured and report to your Volunteer Leader/Coordinator immediately.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Avoid lifting heavy load that you cannot lift.</p>
                </li>
                <li>
                  <p className={'text-justify font-weight-normal'}>Inform your Volunteer Leader/Coordinator if you are feeling unwell at any time during an activity.</p>
                </li>
              </ul>
            </li>
          </ol>
          <SignupVolunteerGuidelinesForm />
        </Form.Group>
      </>
    </>
  )
}