import React, { useCallback } from 'react';
import { Route } from "react-router-dom";
import { VolunteerRequestCard } from './VolunteerRequestCard';

export function VolunteerRequestPage({history}){
  const vrUIEvent = {
    detailVolunteerRequestRowClick: (data) => {
      history.push({
        pathname: `/volunteer-request/detail/${data.Id}`,
        data: data
      })
    },
  }
  return(
    <>
      <VolunteerRequestCard UIEvent={vrUIEvent}/>
    </>
  )
}