export const INVITATION = {
  EVENT_RETRIEVE_REQUEST: 'INVITATION_EVENT_RETRIEVE_REQUEST',
  EVENT_RETRIEVE_SUCCESS: 'INVITATION_EVENT_RETRIEVE_SUCCESS',
  EVENT_RETRIEVE_ERROR: 'INVITATION_EVENT_RETRIEVE_ERROR',
  EVENT_RETRIEVE_RESET: 'INVITATION_EVENT_RETRIEVE_RESET',

  SET_RESPONSE_REQUEST: 'INVITATION_SET_RESPONSE_REQUEST',
  SET_RESPONSE_SUCCESS: 'INVITATION_SET_RESPONSE_SUCCESS',
  SET_RESPONSE_ERROR: 'INVITATION_SET_RESPONSE_ERROR',
  SET_RESPONSE_RESET: 'INVITATION_SET_RESPONSE_RESET',

  CHECKIN_REQUEST: 'INVITATION_CHECKIN_REQUEST',
  CHECKIN_SUCCESS: 'INVITATION_CHECKIN_SUCCESS',
  CHECKIN_ERROR: 'INVITATION_CHECKIN_ERROR',
  CHECKIN_RESET: 'INVITATION_CHECKIN_RESET',

  PARTICIPANT_INFO_REQUEST: 'INVITATION_PARTICIPANT_INFO_REQUEST',
  PARTICIPANT_INFO_SUCCESS: 'INVITATION_PARTICIPANT_INFO_SUCCESS',
  PARTICIPANT_INFO_ERROR: 'INVITATION_PARTICIPANT_INFO_ERROR',
  PARTICIPANT_INFO_RESET: 'INVITATION_PARTICIPANT_INFO_RESET',

  REGISTER_PROGRAMME_EMAIL_REQUEST: 'INVITATION_REGISTER_PROGRAMME_EMAIL_REQUEST',
  REGISTER_PROGRAMME_EMAIL_SUCCESS: 'INVITATION_REGISTER_PROGRAMME_EMAIL_SUCCESS',
  REGISTER_PROGRAMME_EMAIL_ERROR: 'INVITATION_REGISTER_PROGRAMME_EMAIL_ERROR',
  REGISTER_PROGRAMME_EMAIL_RESET: 'INVITATION_REGISTER_PROGRAMME_EMAIL_RESET',

  CANCEL_PROGRAMME_EMAIL_REQUEST: 'INVITATION_CANCEL_PROGRAMME_EMAIL_REQUEST',
  CANCEL_PROGRAMME_EMAIL_SUCCESS: 'INVITATION_CANCEL_PROGRAMME_EMAIL_SUCCESS',
  CANCEL_PROGRAMME_EMAIL_ERROR: 'INVITATION_CANCEL_PROGRAMME_EMAIL_ERROR',
  CANCEL_PROGRAMME_EMAIL_RESET: 'INVITATION_CANCEL_PROGRAMME_EMAIL_RESET',
  
  ASSIGNEDVR_INFO_REQUEST: 'INVITATION_ASSIGNEDVR_INFO_REQUEST',
  ASSIGNEDVR_INFO_SUCCESS: 'INVITATION_ASSIGNEDVR_INFO_SUCCESS',
  ASSIGNEDVR_INFO_ERROR: 'INVITATION_ASSIGNEDVR_INFO_ERROR',
  ASSIGNEDVR_INFO_RESET: 'INVITATION_ASSIGNEDVR_INFO_RESET',

  REGISTER_VR_EMAIL_REQUEST: 'INVITATION_REGISTER_VR_EMAIL_REQUEST',
  REGISTER_VR_EMAIL_SUCCESS: 'INVITATION_REGISTER_VR_EMAIL_SUCCESS',
  REGISTER_VR_EMAIL_ERROR: 'INVITATION_REGISTER_VR_EMAIL_ERROR',
  REGISTER_VR_EMAIL_RESET: 'INVITATION_REGISTER_VR_EMAIL_RESET',

  CANCEL_VR_EMAIL_REQUEST: 'INVITATION_CANCEL_VR_EMAIL_REQUEST',
  CANCEL_VR_EMAIL_SUCCESS: 'INVITATION_CANCEL_VR_EMAIL_SUCCESS',
  CANCEL_VR_EMAIL_ERROR: 'INVITATION_CANCEL_VR_EMAIL_ERROR',
  CANCEL_VR_EMAIL_RESET: 'INVITATION_CANCEL_VR_EMAIL_RESET',
}