import React from 'react';
import { Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import styled from 'styled-components';
import { useFormContext, Controller } from "react-hook-form";

const OPTIONS_PERSONAL = [
  { value: true, label: 'Yes'},
  { value: false, label: 'No'}
]

const CONTAINER = styled.div`
.error {
  border: 1px solid #FF6565;
}`;

const styleSelect = {
  control: base => ({
    ...base,
    borderColor: 'red'
  })
};

export function SignupPersonalDeclarationForm() {
  const { register, watch, errors } = useFormContext();
  const isDisablity = watch('isDisablity');
  const isLongTermIllness = watch('isLongTermIllness');
  const isCriminalOffence = watch('isCriminalOffence');

  return (
    <>
      <CONTAINER>
        <Form.Group as={Row} className={'mb-1'}>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Physical Disability <span className="text-danger">*</span></Form.Label>
            <Controller
              name="isDisablity"
              render={(
                { onChange, onBlur, value, name, ref },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(event) => onChange(event.label)}
                  value={OPTIONS_PERSONAL.filter(ar => ar.label === watch('isDisablity'))[0]}
                  isSearchable={true}
                  options={OPTIONS_PERSONAL}
                  styles={errors.isDisablity && styleSelect}
                />
              )}
            />
            {
              errors.isDisablity &&
              <Form.Text className={'text-danger'}>
                {
                  errors.isDisablity?.message
                }
              </Form.Text>
            }
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Long Term Illness <span className="text-danger">*</span></Form.Label>
            <Controller
              name="isLongTermIllness"
              render={(
                { onChange, onBlur, value, name, ref },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(event) => onChange(event.label)}
                  value={OPTIONS_PERSONAL.filter(ar => ar.label === watch('isLongTermIllness'))[0]}
                  isSearchable={true}
                  options={OPTIONS_PERSONAL}
                  styles={errors.isLongTermIllness && styleSelect}
                />
              )}
            />
            {
              errors.isLongTermIllness &&
              <Form.Text className={'text-danger'}>
                {
                  errors.isLongTermIllness?.message
                }
              </Form.Text>
            }
          </Form.Group>
          <Form.Group className={'col-lg-4'}>
            <Form.Label>Any Criminal Offence <span className="text-danger">*</span></Form.Label>
            <Controller
              name="isCriminalOffence"
              render={(
                { onChange, onBlur, value, name, ref },
              ) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(event) => onChange(event.label)}
                  value={OPTIONS_PERSONAL.filter(ar => ar.label === watch('isCriminalOffence'))[0]}
                  isSearchable={true}
                  options={OPTIONS_PERSONAL}
                  styles={errors.isCriminalOffence && styleSelect}
                />
              )}
            />
            {
              errors.isCriminalOffence &&
              <Form.Text className={'text-danger'}>
                {
                  errors.isCriminalOffence?.message
                }
              </Form.Text>
            }
          </Form.Group>
        </Form.Group>
        {
          isDisablity === 'Yes' || 
          isLongTermIllness === 'Yes' ||
          isCriminalOffence === 'Yes' ?
          <Form.Group as={Row} className={'mb-1'}>
            {
              isDisablity === 'Yes'  ?
              <Form.Group className={'col-lg-4'}>
                <Form.Control 
                  type="text" 
                  placeholder="If yes, please specify" 
                  name="specifyDisability"
                  id="specifyDisability"
                  ref={register}
                  className={(errors.specifyDisability) && "error"}
                />
                {
                  errors.specifyDisability &&
                  <Form.Text className={'text-danger'}>
                    {
                      errors.specifyDisability?.message
                    }
                  </Form.Text>
                }
              </Form.Group> :
              <Form.Group className={'col-lg-4'}/>
            }
            {
              isLongTermIllness === 'Yes' ?
              <Form.Group className={'col-lg-4'}>
                <Form.Control 
                  type="text" 
                  placeholder="If yes, please specify" 
                  name="specifyLongTermIllness"
                  id="specifyLongTermIllness"
                  ref={register}
                  className={(errors.specifyLongTermIllness) && "error"}
                />
                {
                  errors.specifyLongTermIllness &&
                  <Form.Text className={'text-danger'}>
                    {
                      errors.specifyLongTermIllness?.message
                    }
                  </Form.Text>
                }
              </Form.Group> :
              <Form.Group className={'col-lg-4'}/>
            }
            {
              isCriminalOffence === 'Yes' ?
              <Form.Group className={'col-lg-4'}>
                <Form.Control 
                  type="text" 
                  placeholder="If yes, please specify" 
                  name="specifyCriminalOffence"
                  id="specifyCriminalOffence"
                  ref={register}
                  className={(errors.specifyCriminalOffence) && "error"}
                />
                {
                  errors.specifyCriminalOffence &&
                  <Form.Text className={'text-danger'}>
                    {
                      errors.specifyCriminalOffence?.message
                    }
                  </Form.Text>
                }
              </Form.Group> :
              <Form.Group className={'col-lg-4'}/>
            }
          </Form.Group>
          :
          null
        }
      </CONTAINER>
    </>
  );
}
