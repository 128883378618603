import { methodService, apiService } from './apiService';

export const URL = {
  BASE_ATTENDANCE: '/attendances',
  BASE_TIMESHEET: '/timesheets',
  BASE_TRAINING: '/trainees'
}

export function apiAttendanceVrDetail(data) {
  return apiService(
    URL.BASE_TIMESHEET+`/${data.id}`,
    methodService.GET,
    null,
    null
  );
}

export function apiAttendanceVrValidate(data) {
  return apiService(
    URL.BASE_ATTENDANCE+`/${data.id}/validate-volunteer`,
    methodService.POST,
    data,
    null
  );
}

export function apiAttendanceVrCheckin(data) {
  return apiService(
    URL.BASE_ATTENDANCE+`/${data.id}/check-in`,
    methodService.POST,
    data,
    null
  );
}

export function apiAttendanceVrCheckout(data) {
  return apiService(
    URL.BASE_ATTENDANCE+`/${data.id}/check-out`,
    methodService.PATCH,
    data,
    null
  );
}

export function apiTrainingDetail(data){
  return apiService(
    URL.BASE_TRAINING+`/${data.id}`,
    methodService.GET,
    null,
    null
  );
}

export function apiValidateTraining(data){
  return apiService(
    URL.BASE_TRAINING+`/${data.id}/check-email`,
    methodService.POST,
    data,
    null
  )
}

export function apiTrainingCheckin(data){
  return apiService(
    URL.BASE_TRAINING+`/${data.id}/checkin`,
    methodService.POST,
    data,
    null
  )
}

export function apiTrainingCheckout(data){
  return apiService(
    URL.BASE_TRAINING+`/${data.id}/checkout`,
    methodService.POST,
    data,
    null
  )
}