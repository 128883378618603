import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export function ModalAlert(props){
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{backgroundColor: props.type === 'error' ? 'red' : 'transparent'}}>
        <Modal.Title id="contained-modal-title-vcenter" style={{color: props.type === 'error' ? 'white' : 'black', textAlign: 'center'}}>
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {props.message}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}