import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { useSelector } from "react-redux";

export function ClientParticularForm(){
  const data = useSelector(state => state.vr.vrDetail?.data?.volunteerRequest);
  return(
    <>
      <Form>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Name of DC</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Name_of_DC__r?.Name} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Gender Preference</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Gender_Preference__c} 
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Age</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Age1__c} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Ethnic Group</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Ethnic_Group__c} 
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Language</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Language__c} 
            />
          </Form.Group>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Dialect</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Dialect__c} 
            />
          </Form.Group>
        </Form.Group>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Ambulation</Form.Label>
            <Form.Control 
              type="text"
              disabled
              value={data?.Ambulation__c} 
            />
          </Form.Group>
        </Form.Group>
      </Form>
    </>
  )
}