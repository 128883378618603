import * as actionType from './actionType';

export const chatterRequest = data => ({
  type: actionType.DASHBOARD.CHATTER_REQUEST,
  payload: data
})
export const chatterSuccess = data => ({
  type: actionType.DASHBOARD.CHATTER_SUCCESS,
  payload: data
})
export const chatterError = data => ({
  type: actionType.DASHBOARD.CHATTER_ERROR,
  payload: data
})
export const chatterReset = data => ({
  type: actionType.DASHBOARD.CHATTER_RESET,
  payload: data
})

export const myActivityRequest = data => ({
  type: actionType.DASHBOARD.MY_ACTIVITY_REQUEST,
  payload: data
})
export const myActivitySuccess = data => ({
  type: actionType.DASHBOARD.MY_ACTIVITY_SUCCESS,
  payload: data
})
export const myActivityError = data => ({
  type: actionType.DASHBOARD.MY_ACTIVITY_ERROR,
  payload: data
})
export const myActivityReset = data => ({
  type: actionType.DASHBOARD.MY_ACTIVITY_RESET,
  payload: data
})

export const contactUsRequest = data => ({
  type: actionType.DASHBOARD.CONTACT_US_REQUEST,
  payload: data
})
export const contactUsSuccess = data => ({
  type: actionType.DASHBOARD.CONTACT_US_SUCCESS,
  payload: data
})
export const contactUsError = data => ({
  type: actionType.DASHBOARD.CONTACT_US_ERROR,
  payload: data
})
export const contactUsReset = data => ({
  type: actionType.DASHBOARD.CONTACT_US_RESET,
  payload: data
})