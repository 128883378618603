import * as actionType from './actionTypes';

export const peTableRequest = data => ({
  type: actionType.PROGRAMME_EVENTS.TABLE_REQUEST,
  payload: data
})
export const peTableSuccess = data => ({
  type: actionType.PROGRAMME_EVENTS.TABLE_SUCCESS,
  payload: data
})
export const peTableError = data => ({
  type: actionType.PROGRAMME_EVENTS.TABLE_ERROR,
  payload: data
})
export const peTableReset = data => ({
  type: actionType.PROGRAMME_EVENTS.TABLE_RESET,
  payload: data
})

export const peDetailRequest = data => ({
  type: actionType.PROGRAMME_EVENTS.DETAIL_REQUEST,
  payload: data
})
export const peDetailSuccess = data => ({
  type: actionType.PROGRAMME_EVENTS.DETAIL_SUCCESS,
  payload: data
})
export const peDetailError = data => ({
  type: actionType.PROGRAMME_EVENTS.DETAIL_ERROR,
  payload: data
})
export const peDetailReset = data => ({
  type: actionType.PROGRAMME_EVENTS.DETAIL_RESET,
  payload: data
})

export const peRegisterRequest = data => ({
  type: actionType.PROGRAMME_EVENTS.REGISTER_REQUEST,
  payload: data
})
export const peRegisterSuccess = data => ({
  type: actionType.PROGRAMME_EVENTS.REGISTER_SUCCESS,
  payload: data
})
export const peRegisterError = data => ({
  type: actionType.PROGRAMME_EVENTS.REGISTER_ERROR,
  payload: data
})
export const peRegisterReset = data => ({
  type: actionType.PROGRAMME_EVENTS.REGISTER_RESET,
  payload: data
})

export const peCancelRequest = data => ({
  type: actionType.PROGRAMME_EVENTS.CANCEL_REQUEST,
  payload: data
})
export const peCancelSuccess = data => ({
  type: actionType.PROGRAMME_EVENTS.CANCEL_SUCCESS,
  payload: data
})
export const peCancelError = data => ({
  type: actionType.PROGRAMME_EVENTS.CANCEL_ERROR,
  payload: data
})
export const peCancelReset = data => ({
  type: actionType.PROGRAMME_EVENTS.CANCEL_RESET,
  payload: data
})