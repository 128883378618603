import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

export function ModalInfoPE({show, onHide, onConfirm, type, message}){
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Body>
        <Row className={'col-lg-12 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center align-self-center mx-auto'}>
          <Form.Label style={{textAlign: "center"}}>{message}</Form.Label>
        </Row>
        {
          type === "info" &&
          <Row className={'col-lg-9 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center align-self-center mx-auto mt-5'}>
            <Col md='auto' sm={5} xs={5} className={'mx-auto'}>
              <Button 
                style={{minWidth: '10rem'}} 
                variant="primary" 
                onClick={onHide}
              >
                OK
              </Button>
            </Col>
          </Row>
        }
      </Modal.Body>
      {
        type !== "info" &&
        <Modal.Footer>
          <Row className={'col-lg-12 d-flex justify-content-center'}>
            <Col md='auto'>
              <Button onClick={onConfirm} style={{minWidth: '10rem'}} variant="primary">Confirm</Button>
            </Col>
            <Col md='auto'>
              <Button onClick={onHide} style={{minWidth: '10rem'}} variant="danger">Cancel</Button>
            </Col>
          </Row>
        </Modal.Footer>
      }
    </Modal>
  );
}