import React, { useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../../_metronic/_partials/controls";
import * as vrAction from "../redux/volunteerRequestAction";


export function VolunteerRequestTable({ UIEvent, searchName }) {
  const dispatch = useDispatch();
  const vrReducer = useSelector(state => state.vr, shallowEqual);

  const requestDataTableCallback = useCallback((page, per_page) => {
    dispatch(vrAction.vrTableRequest({
      name: searchName,
      limit: 10000,
    }));
  }, [dispatch, searchName])

  useEffect(() => {
    requestDataTableCallback();
  }, [ requestDataTableCallback, searchName ])

  // useEffect(() => {
  //   if(assetsReducer.isLoading === false && assetsReducer.isError === false && assetsReducer.assetsGet !== null){
  //     let newMeta = assetsReducer.assetsGet.data.meta;
  //     tableContext.handleChangeField('meta', '', newMeta);
  //   }
  // }, [assetsReducer, tableContext.handleChangeField])

  // const nameContractColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   return(
  //     <div>
  //       <span><strong>{row.name}</strong></span>
  //       <br />
  //       <span className="text-muted">{row.pksnumber}</span>
  //     </div>
  //   )
  // }

  // const mitraLocationColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   return(
  //     <div>
  //       <span><strong>{row.MitraData.name}</strong></span>
  //       <br />
  //       <span className="text-muted">{row.LocationData.name}</span>
  //     </div>
  //   )
  // }

  // const purchasedateLiveColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   function returnTextLive(month){
  //     let y = Math.floor(month/12);
  //     let m = month%12;
  //     if(y <= 0){
  //       return `${m} bulan`
  //     } else {
  //       return `${y} tahun ${m} bulan`
  //     }
  //   }
  //   return(
  //     <div>
  //       <span className="text-primary"><strong>{moment(row.purchasedate).format("DD/MM/YYYY")}</strong></span>
  //       <br />
  //       <span className="text-muted">{returnTextLive(row.assetlive)}</span>
  //     </div>
  //   )
  // }

  // const priceColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   return(
  //     <div>
  //       <span>Rp. {currencyFormat(row.price)}</span>
  //     </div>
  //   )
  // }

  // const priceDepreciation = (cell, row, rowIndex, formatExtraData) => {
  //   return(
  //     <div>
  //       <span><strong>Rp. {currencyFormat(row.depreciation)}</strong></span>
  //       <br/>
  //       <span className="text-muted">Rp. {currencyFormat(row.priceafterdepreciation)}</span>
  //     </div>
  //   )
  // }

  const statusColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    const statusCssClass = ["success", "warning", "danger"]
    return (
      <span className={`label label-lg label-light-${
        statusCssClass[row.status-1]
      } label-inline`}>
        {row.status === 1 ? "Baik" : row.status === 2 ? "Rusak" : "Hilang"}
      </span>
    );
  }  

  const nullColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>
        {cell === null ? 'N/A' : cell}
      </span>
    )
  }

  const activityColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span role="button">
        {cell === null ? 'N/A' : cell}
      </span>
    )
  }

  const urgentRequestColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>
        {cell === true ? 'Yes' : ''}
      </span>
    )
  }
  
  const columns = [
    {
      dataField: "Name",
      text: "Activity/Event",
      formatter: activityColumnFormatter
    },
    {
      dataField: "Start_Date__c",
      text: "Start Date",
      formatter: nullColumnFormatter
    },
    {
      dataField: "End_Date__c",
      text: "End Date",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Frequency_Type__c",
      text: "Frequency Type",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Urgent_Request__c",
      text: "Urgent Request",
      formatter: urgentRequestColumnFormatter
    },
    {
      dataField: "Number_of_Volunteers_Required__c",
      text: "Number of Volunteers Required",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Venue_of_Activity_Event__c",
      text: "Venue of Activity/Event",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Volunteer_Reporting_Venue__c",
      text: "Volunteer Reporting Venue",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Assignment_Status__c",
      text: "Assignment Status"
    }
  ];
  
  const paginationOptions = {
    custom: true,
    totalSize: 12,
    sizePerPageList: [
      { text: "10", value: 10 }
    ],
    sizePerPage: 10,
    page: 1,
  };

  function onTableChange(type, newState){
    console.log(type);
    console.log(newState);
    // let tmpMeta = {...tableContext.table.meta};
    // tmpMeta.page = newState.page;
    // tmpMeta.per_page = newState.sizePerPage;
    // tableContext.handleChangeField('meta', '', tmpMeta);
  }

  const selectRow = {
    mode: 'radio',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      // console.log(row);
      UIEvent.detailVolunteerRequestRowClick(row);
    }
  };

  return (
    <>
      {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={vrReducer.isLoading}
              paginationProps={paginationProps}
            > */}
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="Id"
                data={vrReducer.vrTable === null ? [] : vrReducer.vrTable?.data?.volunteerRequests}
                columns={columns}
                hover
                // defaultSorted={uiHelpers.defaultSorted}
                onTableChange={onTableChange}
                selectRow={selectRow}
                // {...paginationTableProps}
              >
                {/* <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} /> */}
              </BootstrapTable>
            {/* </Pagination>
          );
        }}
      </PaginationProvider> */}
    </>
  );
}
