/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import moment from "moment-timezone";
import _ from "lodash";
import {Link} from "react-router-dom";
import { DropdownCustomToggler } from "../../dropdowns";
import * as dashboardAction from "../../../../app/modules/Dashboard/redux/dashboardAction";

export function ListMyActivity({ className }) {
  const dispatch = useDispatch();
  const dashboardReducer = useSelector(state => state.dashboard);
  const [showFilter, setShowFilter] = useState(false);
  const [filterActivity, setFilterActivity] = useState("Upcoming");
  const [volunteerHour, setVolunteerHour] = useState(0);
  const [dataActivity, setDataActivity] = useState([]);

  function DropdownFilter() {
    return (
      <>
        <ul className="navi navi-hover">
          <li className="navi-header font-weight-bold py-5">
            <span className="font-size-lg">Choose Activity:</span>
          </li>
          <li className="navi-separator mb-3 opacity-70"></li>
          <li className="navi-item">
            <a className="navi-link" onClick={()=>{setFilterActivity("Upcoming"); setShowFilter(false)}}>
              <span className="navi-text">
                <span className="label label-xl label-inline label-light-success">Upcoming</span>
              </span>
            </a>
          </li>
          <li className="navi-item">
            <a className="navi-link" onClick={()=>{setFilterActivity("Registered"); setShowFilter(false)}}>
              <span className="navi-text">
                <span className="label label-xl label-inline label-light-info">Registered</span>
              </span>
            </a>
          </li>
          <li className="navi-item">
            <a className="navi-link" onClick={()=>{setFilterActivity("Accepted"); setShowFilter(false)}}>
              <span className="navi-text">
                <span className="label label-xl label-inline label-light-warning">Accepted</span>
              </span>
            </a>
          </li>
          <li className="navi-item">
            <a className="navi-link" onClick={()=>{setFilterActivity("Attended"); setShowFilter(false)}}>
              <span className="navi-text">
                <span className="label label-xl label-inline label-light-primary">Attended</span>
              </span>
            </a>
          </li>
        </ul>
      </>
    );
  }

  function closeFilter(){
    if(showFilter){
      setShowFilter(false);
    }
  }

  useEffect(() => {
    dispatch(dashboardAction.myActivityRequest({filter: filterActivity === "Upcoming" ? "" : filterActivity}));
  }, [filterActivity])

  useEffect(() => {
    window.addEventListener('click', closeFilter);

    return function cleanup(){
      window.removeEventListener('click', closeFilter);
    }
  }, [showFilter])

  useEffect(() => {
    if(dashboardReducer.isLoading === false && dashboardReducer.isError === false && dashboardReducer.myActivity !== null){
      let tmpActPe = [...dashboardReducer.myActivity?.data?.programmeEvents];
      let tmpActVr = [...dashboardReducer.myActivity?.data?.volunteerRequests];
      let tmpVolunteerHour = dashboardReducer.myActivity?.data?.volunteerHours;
      setVolunteerHour(tmpVolunteerHour);

      let newActPe = [];
      if(tmpActPe.length > 0){
        newActPe = tmpActPe.map((item) => {
          let newData = {
            id: item?.Programme_Event__c,
            type: "PE",
            title: item?.Programme_Event__r?.Name,
            date: moment(item?.Programme_Event__r?.Start_Date_Time__c).tz("Asia/Singapore").format("YYYY-MM-DD"),
            time: moment(item?.Programme_Event__r?.Start_Date_Time__c).tz("Asia/Singapore").format("hh:MM")
          }
          return newData;
        })
      }

      let newActVr = [];
      if(tmpActVr.length > 0){
        newActVr = tmpActVr.map((item) => {
          let newData = {
            id: item?.Volunteer_Request__c,
            type: "VR",
            title: item?.Volunteer_Request__r?.Name,
            date: moment(item?.Start_Date__c).tz("Asia/Singapore").format("YYYY-MM-DD"),
            time: item?.Start_Time__c?.substring(0,5)
          }
          return newData;
        })
      }

      let combineAct = newActVr.concat(newActPe);
      combineAct = _.sortBy(combineAct, function(act){
        return new moment(act.date)
      })
      combineAct.length = 8;
      setDataActivity(combineAct);
    }
  }, [dashboardReducer])

  return (
    <>
      <div className={`card card-custom  ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">
              {
                filterActivity === "Upcoming" ? "Upcoming Activity" :
                filterActivity === "Registered" ? "Registered Activity" :
                filterActivity === "Accepted" ? "Accepted Activity" :
                filterActivity === "Attended" ? "Attended Activity" :
                "My Activity"
              }
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              {volunteerHour} Volunteer Hours 
            </span>
          </h3>
          <div className="card-toolbar">
            <Dropdown 
              className="dropdown-inline" 
              alignRight
              show={showFilter}
            >
              <Dropdown.Toggle
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
              >
                <i className="ki ki-bold-more-hor" onClick={()=>setShowFilter(true)}/>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownFilter />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {/* Body */}
        <div className="card-body pt-4">
          <div className="timeline timeline-6 mt-3">
            {
              dataActivity.map((item) => {
                return(
                  <div className="timeline-item align-items-start">
                    <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg" style={{width: "99px"}}>
                      <span>{item.date}</span><br/>
                      <span>{item.time}</span>
                    </div>

                    <div className="timeline-badge">
                      <i className="fa fa-genderless text-success icon-xl"></i>
                    </div>

                    <div className="timeline-content d-flex">
                      <Link
                        to={`${item.type === "PE" ? '/programme-events/detail/'+item.id : '/volunteer-request/detail/'+item.id}`}
                        className="btn-hover-primary"
                      >
                        <div className="ml-3">
                          <span className="font-weight-bolder text-dark-75 font-size-lg">
                            {item.title}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
}
