import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter
} from "../../../../_metronic/_partials/controls";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from 'react-redux';
import ApplicationFormContext from "../context/ApplicationFormContext";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Font } from '@react-pdf/renderer';
import moment from "moment";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ApplicationFormStep } from './stepper/ApplicationFormStep';
import { VolunteerPreferenceFormStep } from './stepper/VolunteerPreferencesFormStep';
import { IndemnityFormStep } from './stepper/IndemnityFormStep';
import { ConfidentialityFormStep } from './stepper/ConfidentialityFormStep';
import { VolunteerAgreementFormStep } from './stepper/VolunteerAgreementFormStep';
import { VolunteerGuidelinesFormStep } from './stepper/VolunteerGuidelinesFormStep';
import { SummaryFormStep } from './stepper/SummaryFormStep';
import { SignupFooterStep } from './stepper/SignupFooterStep';
import * as signupAction from '../redux/signupAction';

const { ApplicationFormProvider } = ApplicationFormContext;
const theme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        color: 'rgba(0,0,0,0.38)',
        '&$active': {
          color: '#038cfc'
        },
        "&$completed": {
          color: "#17c191"
        },
      }
    },
  },
})

const validationSchema = Yup.object().shape({
  step: Yup.number(),
  salutation: Yup.string()
    .when(['step'], {
      is: 0,
      then: Yup.string().required('Field is required').nullable()
    }),
  firstName: Yup.string()
    .when(['step'], {
      is: 0,
      then: Yup.string().matches(/^[aA-zZ\s]+$/, { excludeEmptyString: true, message:  "Only alphabets are allowed for this field" })
    }),
  lastName: Yup.string()
    .when(['step'], {
      is: 0,
      then: Yup.string().required('Field is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    }),
  dob: Yup.string()
    .when(['step'], {
      is: 0,
      then: Yup.string().required('Field is required')
    }),
  idNumber: Yup.string()
    .when(['idType'], {
      is: (idType) => {
        if((idType === 'NRIC' || idType === 'FIN')){
          return true
        }
      },
      then: Yup.string()
        .test("idNumber", null, (str) => {
          if(str.length !== 9){
            return new Yup.ValidationError(
              "Invalid number",
              null,
              "idNumber"
            );
          } else {
            str = str.toUpperCase();
            var i, icArray = [];
            for(i = 0; i < 9; i++) {
              icArray[i] = str.charAt(i);
            }

            icArray[1] = parseInt(icArray[1], 10) * 2;
            icArray[2] = parseInt(icArray[2], 10) * 7;
            icArray[3] = parseInt(icArray[3], 10) * 6;
            icArray[4] = parseInt(icArray[4], 10) * 5;
            icArray[5] = parseInt(icArray[5], 10) * 4;
            icArray[6] = parseInt(icArray[6], 10) * 3;
            icArray[7] = parseInt(icArray[7], 10) * 2;

            var weight = 0;
            for(i = 1; i < 8; i++) {
                weight += icArray[i];
            }

            var offset = (icArray[0] == "T" || icArray[0] == "G") ? 4:0;
            var temp = (offset + weight) % 11;

            var st = ["J","Z","I","H","G","F","E","D","C","B","A"];
            var fg = ["X","W","U","T","R","Q","P","N","M","L","K"];

            var theAlpha;
            if (icArray[0] == "S" || icArray[0] == "T"){ 
              theAlpha = st[temp]; 
            } else if (icArray[0] == "F" || icArray[0] == "G"){ 
              theAlpha = fg[temp]; 
            }

            if(icArray[8] === theAlpha){
              return true;
            } else {
              return new Yup.ValidationError(
                "Invalid number",
                null,
                "idNumber"
              );
            }
          }
        }),
    }),
  gender: Yup.string()
    .when(['step'], {
      is: 0,
      then: Yup.string().required('Field is required')
    }),
  spokenLanguage: Yup.object()
    .when(
      'step', {
        is: 0,
        then: Yup.object({
          English: Yup.boolean(),
          Mandarin: Yup.boolean(),
          Malay: Yup.boolean(),
          Tamil: Yup.boolean()
        }).test(
          'lang',
          null,
          (obj) => {
            if(obj.English || obj.Malay || obj.Mandarin || obj.Tamil){
              return true;
            } else {
              return new Yup.ValidationError(
                'Please pick at least one of the options',
                null,
                'spokenLanguage'
              )
            }
          }
        )
      }
    ),
  email: Yup.string()
    .when('step', {
      is: 0,
      then: Yup.string().email().required('Field is required')
    }),
  mobilePhone: Yup.string()
    .when('step', {
      is: 0,
      then: Yup.string()
      .matches(/^[689]\d{7}$/, "Please provide a valid phone number")
      .required("Field is required")
    }),
  isDisablity: Yup.string()
    .when(['step'], {
      is: 0,
      then: Yup.string().required('Field is required')
    }),
  specifyDisability: Yup.string()
    .when(['isDisablity'], {
      is: "Yes",
      then: Yup.string().required('Field is required')
    }),
  isLongTermIllness: Yup.string()
    .when(['step'], {
      is: 0,
      then: Yup.string().required('Field is required')
    }),
  specifyLongTermIllness: Yup.string()
    .when(['isLongTermIllness'], {
      is: "Yes",
      then: Yup.string().required('Field is required')
    }),
  isCriminalOffence: Yup.string()
    .when(['step'], {
      is: 0,
      then: Yup.string().required('Field is required')
    }),
  specifyCriminalOffence: Yup.string()
    .when(['isCriminalOffence'], {
      is: "Yes",
      then: Yup.string().required('Field is required')
    }),
  emergencyName: Yup.string()
    .when(['step'], {
      is: 0,
      then: Yup.string().required('Field is required')
    }),
  emergencyNumber: Yup.string()
    .when(['step'], {
      is: 0,
      then: Yup.string().required('Field is required')
    }),
  emergencyRelationship: Yup.string()
    .when(['step'], {
      is: 0,
      then: Yup.string().required('Field is required')
    }),
  preferred: Yup.object()
    .when(
      'step', {
        is: 1,
        then: Yup.object({
          isAdministrative: Yup.boolean(),
          isBfriending: Yup.boolean(),
          isEnrichment: Yup.boolean(),
          isEvent: Yup.boolean(),
          isHope: Yup.boolean(),
          isHomeAid: Yup.boolean(),
          isGuide: Yup.boolean(),
          isOfficer: Yup.boolean(),
          isEscort: Yup.boolean(),
          isTranslation: Yup.boolean(),
          isProfessional: Yup.boolean()
        }).test(
          'pref',
          null,
          (obj) => {
            if(obj.isAdministrative || obj.isBfriending || obj.isEnrichment || obj.isEvent || obj.isHope || obj.isHomeAid ||
              obj.isGuide || obj.isOfficer || obj.isEscort || obj.isTranslation || obj.isProfessional){
                return true;
            } else {
              return new Yup.ValidationError(
                'Please pick one of the options',
                null,
                'preferred'
              )
            }
          }
        )
      }
    ),
  frequency: Yup.string()
    .when(['step'], {
      is: 1,
      then: Yup.string().required('Field is required')
    }),
  parentContact: Yup.string()
    .when(['step'], {
      is: 2,
      then: Yup.string()
      .matches(/^[689]\d{7}$/, {
        message: "Please provide a valid phone number", 
        excludeEmptyString: true,
      })
    }),
})

export function SignupCompleteFormPage(props){
  const dispatch = useDispatch();
  const signupReducer = useSelector(state => state.signup);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      step: 0,
      salutation: '',
      firstName: '',
      lastName: '',
      dob: '',
      idType: 'NRIC',
      idNumber: '',
      gender: '',
      nationality: 'Singapore',
      race: '',
      education: '',
      occupation: '',
      otherOccupation: '',
      industry: '',
      spokenLanguage: {
        Cantonese: false,
        English: false,
        Hainanese: false,
        Hokkien: false,
        Khek: false,
        Malay: false,
        Mandarin: false,
        Others: false,
        Tamil: false,
        Teochew: false
      },
      email: '',
      homePhone: '',
      mobilePhone: '',
      postalCode: '',
      unitNumber: '',
      address: '',
      isDisablity: '',
      isLongTermIllness: '',
      isCriminalOffence: '',
      specifyDisability: '',
      specifyLongTermIllness: '',
      specifyCriminalOffence: '',
      emergencyName: '',
      emergencyNumber: '',
      emergencyRelationship: '',
      preferred: {
        isAdministrative: false,
        isBfriending: false,
        isEnrichment: false,
        isEvent: false,
        isHope: false,
        isHomeAid: false,
        isGuide: false,
        isOfficer: false,
        isEscort: false,
        isTranslation: false,
        isProfessional: false
      },
      frequency: '',
      sunday: {
        morning: false,
        afternoon: false,
        evening: false
      },
      monday: {
        morning: false,
        afternoon: false,
        evening: false
      },
      tuesday: {
        morning: false,
        afternoon: false,
        evening: false
      },
      wednesday: {
        morning: false,
        afternoon: false,
        evening: false
      },
      thursday: {
        morning: false,
        afternoon: false,
        evening: false
      },
      friday: {
        morning: false,
        afternoon: false,
        evening: false
      },
      saturday: {
        morning: false,
        afternoon: false,
        evening: false
      },
      remarks: '',
      parentName: '',
      parentNric: '',
      parentContact: ''
    }
  });
  const [loadData, setLoadData] = useState(false);
  const [retrieveData, setRetrieveData] = useState({});
  const [stepLabel, setStepLabel] = useState(
    [
      'Application Form', 
      'Volunteer Preferences', 
      'Indemnity Form', 
      'Confidentiality', 
      'Volunteer Agreement', 
      'Volunteer Guidelines', 
      'Summary'
    ]
  );
  function getSteps() {
    return stepLabel;
  }

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit(){
        return "show warning";
    }
}, []);

  useEffect(() => {
    if(signupReducer.isLoading === false && signupReducer.isError === false && signupReducer.signupContactRetrieve !== null){
      if(loadData === false){
        setLoadData(true);
        let contactRetrieve = signupReducer.signupContactRetrieve.data.contact;
        setRetrieveData(contactRetrieve);
      }
    }
    
    if(signupReducer.isError === true || signupReducer.isErrorForm === true){
      props.history.push('/signup-access');
    }
  }, [signupReducer])
  
  useEffect(() => {
    if(Object.keys(retrieveData).length !== 0){
      retrieveStepData();
    }
  }, [retrieveData])

  function retrieveStepData(){
    if(Object.keys(retrieveData).length !== 0){
      //STEP 1
      methods.setValue('salutation', retrieveData.Salutation);
      methods.setValue('firstName', retrieveData.FirstName);
      methods.setValue('lastName', retrieveData.LastName);
      methods.setValue('dob', retrieveData.Birthdate);
      if(retrieveData.ID_Type__c !== '' && retrieveData.ID_Type__c !== null){
        methods.setValue('idType', retrieveData.ID_Type__c);
      }
      if(retrieveData.ID_No__c !== null){
        methods.setValue('idNumber', retrieveData.ID_No__c);
      }
      
      if(retrieveData.Gender__c !== '' && retrieveData.Gender__c !== null){
        methods.setValue('gender', retrieveData.Gender__c);
      }
      if(retrieveData.Nationality__c !== '' && retrieveData.Nationality__c !== null){
        methods.setValue('nationality', retrieveData.Nationality__c);
      }
      methods.setValue('race', retrieveData.Race__c);
      methods.setValue('education', retrieveData.Highest_Education_Level__c);
      methods.setValue('occupation', retrieveData.Occupation__c);
      methods.setValue('otherOccupation', retrieveData.Other_Occupation__c);
      methods.setValue('industry', retrieveData.Industry__c);
      methods.setValue('spokenLanguage[Cantonese]', retrieveData.Cantonese__c);
      methods.setValue('spokenLanguage[English]', retrieveData.English__c);
      methods.setValue('spokenLanguage[Hainanese]', retrieveData.Hainanese__c);
      methods.setValue('spokenLanguage[Hokkien]', retrieveData.Hokkien__c);
      methods.setValue('spokenLanguage[Khek]', retrieveData.Khek__c);
      methods.setValue('spokenLanguage[Malay]', retrieveData.Malay__c);
      methods.setValue('spokenLanguage[Mandarin]', retrieveData.Mandarin__c);
      if(retrieveData.Others__c === 'false' || retrieveData.Others__c === false){
        methods.setValue('spokenLanguage[Others]', false);
      }
      methods.setValue('spokenLanguage[Tamil]', retrieveData.Tamil__c);
      methods.setValue('spokenLanguage[Teochew__c]', retrieveData.Teochew__c);
      methods.setValue('email', retrieveData.Email);
      methods.setValue('homePhone', retrieveData.HomePhone);
      methods.setValue('mobilePhone', retrieveData.MobilePhone);
      methods.setValue('postalCode', retrieveData.MailingPostalCode);
      methods.setValue('unitNumber', retrieveData.Unit_Number__c);
      methods.setValue('address', retrieveData.MailingAddress?.street);
      if(retrieveData.Physical_Disability__c !== null ){
        methods.setValue('isDisablity', retrieveData.Physical_Disability__c);
      }
      if(retrieveData.Long_term_Illness__c !== null){
        methods.setValue('isLongTermIllness', retrieveData.Long_term_Illness__c);
      }
      if(retrieveData.Any_Criminal_Offence__c !== null){
        methods.setValue('isCriminalOffence', retrieveData.Any_Criminal_Offence__c);
      }
      setTimeout(() => {
        methods.setValue('specifyDisability', retrieveData.SpecifiedPhysicalDisability__c);
        methods.setValue('specifyLongTermIllness', retrieveData.SpecifiedLongtermIllness__c);
        methods.setValue('specifyCriminalOffence', retrieveData.SpecifiedCriminalOffence__c);
      }, 1000);
      methods.setValue('emergencyName', retrieveData.Name__c);
      methods.setValue('emergencyNumber', retrieveData.Emergency_Mobile__c);
      if(retrieveData.Relationship__c !== '' && retrieveData.Relationship__c !== null){
        methods.setValue('emergencyRelationship', retrieveData.Relationship__c);
      }
      if(retrieveData.Application_Declaration__c === true){
        setActiveStep(1);
        methods.setValue('step', 1);
      }

      //STEP 2
      let preferredData = retrieveData.Preferred_Programmes__c?.split(";");
      if(preferredData?.filter(ar => ar.toLowerCase().indexOf("administrative") > -1).length > 0){
        methods.setValue('preferred[isAdministrative]', true);
      } else {
        methods.setValue('preferred[isAdministrative]', false);
      }
      if(preferredData?.filter(ar => ar.toLowerCase().indexOf("befriending") > -1).length > 0){
        methods.setValue('preferred[isBfriending]', true);
      } else {
        methods.setValue('preferred[isBfriending]', false);
      }
      if(preferredData?.filter(ar => ar.toLowerCase().indexOf("enrichment") > -1).length > 0){
        methods.setValue('preferred[isEnrichment]', true);
      } else {
        methods.setValue('preferred[isEnrichment]', false);
      }
      if(preferredData?.filter(ar => ar.toLowerCase().indexOf("events") > -1).length > 0){
        methods.setValue('preferred[isEvent]', true);
      } else {
        methods.setValue('preferred[isEvent]', false);
      }
      if(preferredData?.filter(ar => ar.toLowerCase().indexOf("hope") > -1).length > 0){
        methods.setValue('preferred[isHope]', true);
      } else {
        methods.setValue('preferred[isHope]', false);
      }
      if(preferredData?.filter(ar => ar.toLowerCase().indexOf("home aid") > -1).length > 0){
        methods.setValue('preferred[isHomeAid]', true);
      } else {
        methods.setValue('preferred[isHomeAid]', false);
      }
      if(preferredData?.filter(ar => ar.toLowerCase().indexOf("guide") > -1).length > 0){
        methods.setValue('preferred[isGuide]', true);
      } else {
        methods.setValue('preferred[isGuide]', false);
      }
      if(preferredData?.filter(ar => ar.toLowerCase().indexOf("officer") > -1).length > 0){
        methods.setValue('preferred[isOfficer]', true);
      } else {
        methods.setValue('preferred[isOfficer]', false);
      }
      if(preferredData?.filter(ar => ar.toLowerCase().indexOf("escort") > -1).length > 0){
        methods.setValue('preferred[isEscort]', true);
      } else {
        methods.setValue('preferred[isEscort]', false);
      }
      if(preferredData?.filter(ar => ar.toLowerCase().indexOf("translation") > -1).length > 0){
        methods.setValue('preferred[isTranslation]', true);
      } else {
        methods.setValue('preferred[isTranslation]', false);
      }
      if(preferredData?.filter(ar => ar.toLowerCase().indexOf("professional") > -1).length > 0){
        methods.setValue('preferred[isProfessional]', true);
      } else {
        methods.setValue('preferred[isProfessional]', false);
      }
      if(retrieveData.Frequency_Preference__c !== null){
        methods.setValue('frequency', retrieveData.Frequency_Preference__c);
      }
      let availSunday = retrieveData.V_A_Sunday__c?.split(";");
      if(availSunday?.filter(ar => ar.toLowerCase().indexOf("morning") > -1).length > 0){
        methods.setValue('sunday[morning]', true);
      } else {
        methods.setValue('sunday[morning]', false);
      }
      if(availSunday?.filter(ar => ar.toLowerCase().indexOf("afternoon") > -1).length > 0){
        methods.setValue('sunday[afternoon]', true);
      } else {
        methods.setValue('sunday[afternoon]', false);
      }
      if(availSunday?.filter(ar => ar.toLowerCase().indexOf("evening") > -1).length > 0){
        methods.setValue('sunday[evening]', true);
      } else {
        methods.setValue('sunday[evening]', false);
      }
      let availMonday = retrieveData.V_A_Monday__c?.split(";");
      if(availMonday?.filter(ar => ar.toLowerCase().indexOf("morning") > -1).length > 0){
        methods.setValue('monday[morning]', true);
      } else {
        methods.setValue('monday[morning]', false);
      }
      if(availMonday?.filter(ar => ar.toLowerCase().indexOf("afternoon") > -1).length > 0){
        methods.setValue('monday[afternoon]', true);
      } else {
        methods.setValue('monday[afternoon]', false);
      }
      if(availMonday?.filter(ar => ar.toLowerCase().indexOf("evening") > -1).length > 0){
        methods.setValue('monday[evening]', true);
      } else {
        methods.setValue('monday[evening]', false);
      }
      let availTuesday = retrieveData.V_A_Tuesday__c?.split(";");
      if(availTuesday?.filter(ar => ar.toLowerCase().indexOf("morning") > -1).length > 0){
        methods.setValue('tuesday[morning]', true);
      } else {
        methods.setValue('tuesday[morning]', false);
      }
      if(availTuesday?.filter(ar => ar.toLowerCase().indexOf("afternoon") > -1).length > 0){
        methods.setValue('tuesday[afternoon]', true);
      } else {
        methods.setValue('tuesday[afternoon]', false);
      }
      if(availTuesday?.filter(ar => ar.toLowerCase().indexOf("evening") > -1).length > 0){
        methods.setValue('tuesday[evening]', true);
      } else {
        methods.setValue('tuesday[evening]', false);
      }
      let availWednesday = retrieveData.V_A_Wednesday__c?.split(";");
      if(availWednesday?.filter(ar => ar.toLowerCase().indexOf("morning") > -1).length > 0){
        methods.setValue('wednesday[morning]', true);
      } else {
        methods.setValue('wednesday[morning]', false);
      }
      if(availWednesday?.filter(ar => ar.toLowerCase().indexOf("afternoon") > -1).length > 0){
        methods.setValue('wednesday[afternoon]', true);
      } else {
        methods.setValue('wednesday[afternoon]', false);
      }
      if(availWednesday?.filter(ar => ar.toLowerCase().indexOf("evening") > -1).length > 0){
        methods.setValue('wednesday[evening]', true);
      } else {
        methods.setValue('wednesday[evening]', false);
      }
      let availThursday = retrieveData.V_A_Thursday__c?.split(";");
      if(availThursday?.filter(ar => ar.toLowerCase().indexOf("morning") > -1).length > 0){
        methods.setValue('thursday[morning]', true);
      } else {
        methods.setValue('thursday[morning]', false);
      }
      if(availThursday?.filter(ar => ar.toLowerCase().indexOf("afternoon") > -1).length > 0){
        methods.setValue('thursday[afternoon]', true);
      } else {
        methods.setValue('thursday[afternoon]', false);
      }
      if(availThursday?.filter(ar => ar.toLowerCase().indexOf("evening") > -1).length > 0){
        methods.setValue('thursday[evening]', true);
      } else {
        methods.setValue('thursday[evening]', false);
      }
      let availFriday = retrieveData.V_A_Friday__c?.split(";");
      if(availFriday?.filter(ar => ar.toLowerCase().indexOf("morning") > -1).length > 0){
        methods.setValue('friday[morning]', true);
      } else {
        methods.setValue('friday[morning]', false);
      }
      if(availFriday?.filter(ar => ar.toLowerCase().indexOf("afternoon") > -1).length > 0){
        methods.setValue('friday[afternoon]', true);
      } else {
        methods.setValue('friday[afternoon]', false);
      }
      if(availFriday?.filter(ar => ar.toLowerCase().indexOf("evening") > -1).length > 0){
        methods.setValue('friday[evening]', true);
      } else {
        methods.setValue('friday[evening]', false);
      }
      let availSaturday = retrieveData.V_A_Saturday__c?.split(";");
      if(availSaturday?.filter(ar => ar.toLowerCase().indexOf("morning") > -1).length > 0){
        methods.setValue('saturday[morning]', true);
      } else {
        methods.setValue('saturday[morning]', false);
      }
      if(availSaturday?.filter(ar => ar.toLowerCase().indexOf("afternoon") > -1).length > 0){
        methods.setValue('saturday[afternoon]', true);
      } else {
        methods.setValue('saturday[afternoon]', false);
      }
      if(availSaturday?.filter(ar => ar.toLowerCase().indexOf("evening") > -1).length > 0){
        methods.setValue('saturday[evening]', true);
      } else {
        methods.setValue('saturday[evening]', false);
      }
      methods.setValue('remarks', retrieveData.Remarks__c);
      if(retrieveData.Frequency_Preference__c !== null){
        setActiveStep(2);
        methods.setValue('step', 2);
      }

      //STEP 3
      setTimeout(() => {
        methods.setValue('parentName', retrieveData.Parent_Guardians_Name__c);
        methods.setValue('parentNric', retrieveData.Parent_NRIC__c);
        methods.setValue('parentContact', retrieveData.Parent_Contact_No__c);  
      }, 1000);
      if(retrieveData.Indemnity_Agreement__c === true){
        setActiveStep(3);
        methods.setValue('step', 3);
      }

      //STEP 4
      if(retrieveData.Confidentiality_Agreement__c === true){
        setActiveStep(4);
        methods.setValue('step', 4);
      }

      //STEP 5
      if(retrieveData.Volunteer_Agreement__c === true){
        setActiveStep(5);
        methods.setValue('step', 5);
      }

      //STEP 6
      if(retrieveData.Volunteer_Guidelines__c === true){
        setActiveStep(6);
        methods.setValue('step', 6);
      }
    }
  }

  function getStepContent(step) {
    return(
      <>
        <div style={{display: step === 0 ? 'block' : 'none'}}>
          <ApplicationFormStep />
        </div>
        <div style={{display: step === 1 ? 'block' : 'none'}}>
          <VolunteerPreferenceFormStep />
        </div>
        <div style={{display: step === 2 ? 'block' : 'none'}}>
          <IndemnityFormStep />
        </div>
        <div style={{display: step === 3 ? 'block' : 'none'}}>
          <ConfidentialityFormStep />
        </div>
        <div style={{display: step === 4 ? 'block' : 'none'}}>
          <VolunteerAgreementFormStep />
        </div>
        <div style={{display: step === 5 ? 'block' : 'none'}}>
          <VolunteerGuidelinesFormStep />
        </div>
        <div style={{display: step === 6 ? 'block' : 'none'}}>
          <SummaryFormStep />
        </div>
      </>
    )
  }

  useEffect(() => {
    dispatch(signupAction.contactRetrieveRequest());
    Font.register({
      family: 'Open Sans',
      fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
      ]
    });
  }, [])

  useEffect(() => {
    if(signupReducer.isLoadingForm === false && signupReducer.isErrorForm === false && signupReducer.signupForm !== null){
      dispatch(signupAction.formReset());
      dispatch(signupAction.contactRetrieveRequest());
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      methods.setValue('step', activeStep+1);
      window.scrollTo(0,0);
    }
  }, [signupReducer])

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = data => {
    let payload = {};
    if(activeStep === 0){
      payload.step = data.step;
      payload.salutation = data.salutation;
      payload.firstName = data.firstName;
      payload.lastName = data.lastName;
      payload.dob = data.dob;
      payload.idType = data.idType;
      payload.idNumber = data.idNumber;
      payload.gender = data.gender;
      payload.nationality = data.nationality;
      payload.race = data.race;
      payload.education = data.education;
      payload.occupation = data.occupation;
      payload.otherOccupation = data.otherOccupation;
      payload.industry = data.industry;
      payload.spokenLanguage = data.spokenLanguage;
      payload.email = data.email;
      payload.homePhone = data.homePhone;
      payload.mobilePhone = data.mobilePhone;
      payload.postalCode = data.postalCode;
      payload.unitNumber = data.unitNumber;
      payload.address = data.address;
      payload.isDisablity = data.isDisablity;
      payload.isLongTermIllness = data.isLongTermIllness;
      payload.isCriminalOffence = data.isCriminalOffence;
      payload.specifyDisability = data.specifyDisability;
      payload.specifyLongTermIllness = data.specifyLongTermIllness;
      payload.specifyCriminalOffence = data.specifyCriminalOffence;
      payload.emergencyName = data.emergencyName;
      payload.emergencyNumber = data.emergencyNumber;
      payload.emergencyRelationship = data.emergencyRelationship;
    } else if(activeStep === 1){
      payload.step = data.step;
      payload.preferred = data.preferred;
      payload.frequency = data.frequency;
      payload.sunday = data.sunday;
      payload.monday = data.monday;
      payload.tuesday = data.tuesday;
      payload.wednesday = data.wednesday;
      payload.thursday = data.thursday;
      payload.friday = data.friday;
      payload.saturday = data.saturday;
      payload.remarks = data.remarks;
    } else if(activeStep === 2){
      payload.step = data.step;
      if(moment().diff(moment(data.dob),'years') >= 16 && 
        moment().diff(moment(data.dob),'years') <= 21){
          payload.parentName = data.parentName;
          payload.parentNric = data.parentNric;
          payload.parentContact = data.parentContact;
      }
    } else {
      payload.step = data.step;
    }
    dispatch(signupAction.formRequest(payload));
    // if(activeStep > 0 && activeStep < 3){
    //   dispatch(signupAction.formRequest(payload));
    // } else {
    //   dispatch(signupAction.formRequest(payload));
    // }
  };

  const handleBack = () => {
    if(activeStep > 0){
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      window.scrollTo(0,0);
      methods.setValue('step', methods.watch('step')-1);
    }
  };

  return(
    <FormProvider {...methods}>
    <ApplicationFormProvider>
      <form>
      <input name="step" ref={methods.register} type={'hidden'}/>
      <div className="container pt-5">
        <Card>
          <CardHeader>
            <div className="col-lg-2">
              <img
                alt="Logo"
                className="max-h-50px mt-5"
                src={toAbsoluteUrl("/media/logos/nkf-circle-hearts-logo.png")}
              />
            </div>
            <div className="col-lg-8 mx-auto">
              <ThemeProvider theme={theme}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </ThemeProvider>
            </div>
            <div className="col-lg-2">
              <SVG
                src={toAbsoluteUrl(
                  "/media/nkf/logo-nkf.svg"
                )}
                className="h-50 align-self-center mt-5"
              ></SVG>
            </div>
          </CardHeader>
          <CardBody>
            {
              getStepContent(activeStep)
            }
          </CardBody>
          <CardFooter>
            <div style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <SignupFooterStep 
                handleBack={handleBack} 
                handleNext={handleNext}
                step={activeStep}
                setStepLabel={setStepLabel}
              />
            </div>
          </CardFooter>
        </Card>
      </div>
      </form>
    </ApplicationFormProvider>
    </FormProvider>
  )
}