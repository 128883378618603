import React from 'react';
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../_metronic/_partials/controls";
import { Row, Button } from 'react-bootstrap';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function SignupResultFormPage(){
  function onSubmit(){
    window.open("https://nkfs.org/","_self")
  }

  return(
    <div className="d-flex align-item-center justify-content-center" style={{height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
      <div 
        style={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          backgroundImage: `url(${toAbsoluteUrl("/media/nkf/volunteer-together.jpg")})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          filter: 'blur(8px)',
          position: 'absolute'
        }}
      />
      <Card style={{width: '50rem', height: '20rem', borderColor: 'red'}}>
        <CardHeader>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/nkf-circle-hearts-logo.png")}
              style={{
                maxHeight: '50px',
                position: 'absolute',
                left: 0
              }}
            />
            <div className="col-lg-8">
              <h4 className="text-center " style={{color: '#1BC5BD'}}>Thank You!</h4>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <h5 className={'text-center'}>Your application is submitted successfully.</h5>
          <h5 className={'text-center'}>Thank you!</h5>
          <Row className={'mt-5'}>
            <Button className={'col-lg-3 mx-auto'} variant="success" onClick={()=>onSubmit()}>
              OK
            </Button>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}