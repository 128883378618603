import { methodService, apiService } from './apiService';

export const URL = {
  BASE_PE: '/programmeEvents',
  BASE_PARTICIPANT: '/participants'
}

export function apiPETable(data) {
  return apiService(
    URL.BASE_PE,
    methodService.GET,
    null,
    data
  );
}

export function apiPEDetail(data) {
  return apiService(
    URL.BASE_PE+`/${data.Id}`,
    methodService.GET,
    null,
    null
  )
}

export function apiPERegister(data) {
  return apiService(
    URL.BASE_PE+`/${data.Id}/register`,
    methodService.POST,
    null,
    null
  )
}

export function apiPECancel(data) {
  return apiService(
    URL.BASE_PARTICIPANT+`/${data.Id}/cancel`,
    methodService.PATCH,
    null,
    null
  )
}