import React from 'react';
import { SignupIndemnityForm } from '../../form/SignupIndemnityForm';
import { CalculateAge } from '../../../../helper/Service';
import { useFormContext } from "react-hook-form";

export function IndemnityFormStep(){
  const { watch } = useFormContext();
  if(CalculateAge(new Date(watch('dob'))) >= 16 && 
      CalculateAge(new Date(watch('dob'))) <= 21){
    return(
      <>
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item">
            <a
              className={`nav-link basic active`}
              data-toggle="tab"
              role="tab"
              href="#indemnity-disclaimer"
            >
              Disclaimer
            </a>
          </li>
        </ul>
        <div className={'mt-5'}>
          <p>I understand that as a volunteer with NKF Circle of Hearts, my child/ward will be volunteering his/her services solely for his/her personal purpose or benefit without promise or expectation of compensation or benefits.</p>
          <p>I understand that NKF Circle of Hearts and its representatives will take all reasonable steps to provide individual care and safety for each child, but I am aware that NKF and/or its staff and/or its volunteers cannot assume responsibility for any injury/death, loss, damage or harm to any child or to his/her property during the course of the activity, including travelling to and from the activity site.</p> 
          <p>I will not take any legal actions and/or claims (including but not limited to medical expenses etc) against NKF and/or its staff, volunteers and all persons and/or agencies connected with the activity and shall defend and hold the same harmless from all claims and damages (personal injuries/death, mishap, etc) arising from my child/ward’s participation before, during and after the activity.</p>
          <p>In the event of an accident or illness requiring professional medical care (casts, stitches, X-ray, emergency surgery, hospital confinement, etc), I hereby authorise NKF, its staff & appointed volunteers to act on my behalf in giving my permission to obtain professional medical care, if it is deemed necessary, from a licensed physician, hospital or other medical facility.</p>
          <p>I fully understand the above agreement, and will not hold NKF, its staff & appointed volunteers, medics, physician, hospital etc responsible for any action taken for the professional emergency services performed.</p>
          <p>I also declare that my child/ward is medically and mentally fit to participate in this activity. I am aware that I can seek legal advice or have already sought legal advice, in respect of this waiver of liability before signing this document.</p>
        </div>
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item">
            <a
              className={`nav-link basic active`}
              data-toggle="tab"
              role="tab"
              href="#indemnity-parent"
            >
              Parent/Guardians Detail
            </a>
          </li>
        </ul>
        <div className="mt-5">
          <SignupIndemnityForm/>
        </div>
      </>
    )
  } else {
    return(
      <>
        <label>This form is only applicable for volunteers over 16 and under 21 years old. Please click next to skip this step.</label>
      </>
    )
  }
}