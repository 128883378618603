import React, { useEffect, useCallback, useContext } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import moment from "moment-timezone";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../../_metronic/_partials/controls";
import * as attendanceAction from "../redux/attendanceActions";
// import ListAssetsContext from "./ListAssetsContext";
// const { ListTableContext } = ListAssetsContext;

export function AttendanceTable({ UIEvent, searchName }) {
  const dispatch = useDispatch();
  // const tableContext = useContext(ListTableContext)
  const attendanceReducer = useSelector(state => state.attendance, shallowEqual);

  const requestDataTableCallback = useCallback((page, per_page) => {
    dispatch(attendanceAction.attendanceTableRequest({
      name: searchName,
      limit: 10000,
    }));
  }, [dispatch, searchName])

  useEffect(() => {
    requestDataTableCallback();
  }, [
    requestDataTableCallback, searchName
  ])

  // useEffect(() => {
  //   if(assetsReducer.isLoading === false && assetsReducer.isError === false && assetsReducer.assetsGet !== null){
  //     let newMeta = assetsReducer.assetsGet.data.meta;
  //     tableContext.handleChangeField('meta', '', newMeta);
  //   }
  // }, [assetsReducer, tableContext.handleChangeField])

  // const nameContractColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   return(
  //     <div>
  //       <span><strong>{row.name}</strong></span>
  //       <br />
  //       <span className="text-muted">{row.pksnumber}</span>
  //     </div>
  //   )
  // }

  // const mitraLocationColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   return(
  //     <div>
  //       <span><strong>{row.MitraData.name}</strong></span>
  //       <br />
  //       <span className="text-muted">{row.LocationData.name}</span>
  //     </div>
  //   )
  // }

  // const purchasedateLiveColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   function returnTextLive(month){
  //     let y = Math.floor(month/12);
  //     let m = month%12;
  //     if(y <= 0){
  //       return `${m} bulan`
  //     } else {
  //       return `${y} tahun ${m} bulan`
  //     }
  //   }
  //   return(
  //     <div>
  //       <span className="text-primary"><strong>{moment(row.purchasedate).format("DD/MM/YYYY")}</strong></span>
  //       <br />
  //       <span className="text-muted">{returnTextLive(row.assetlive)}</span>
  //     </div>
  //   )
  // }

  // const priceColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   return(
  //     <div>
  //       <span>Rp. {currencyFormat(row.price)}</span>
  //     </div>
  //   )
  // }

  // const priceDepreciation = (cell, row, rowIndex, formatExtraData) => {
  //   return(
  //     <div>
  //       <span><strong>Rp. {currencyFormat(row.depreciation)}</strong></span>
  //       <br/>
  //       <span className="text-muted">Rp. {currencyFormat(row.priceafterdepreciation)}</span>
  //     </div>
  //   )
  // }

  const statusColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    const statusCssClass = ["success", "warning", "danger"]
    return (
      <span className={`label label-lg label-light-${
        statusCssClass[row.status-1]
      } label-inline`}>
        {row.status === 1 ? "Baik" : row.status === 2 ? "Rusak" : "Hilang"}
      </span>
    );
  }

  const nullColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>
        {cell === null ? 'N/A' : cell}
      </span>
    )
  }

  const datetimeColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span>
        {cell !== null ? moment(cell).tz("Asia/Singapore").format('DD/MM/YYYY, HH:mm') : 'N/A'}
      </span>
    )
  }
  
  const columns = [
    {
      dataField: "Name",
      text: "Attendance",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Volunteer_Request",
      text: "Volunteer Request",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Volunteer_Name",
      text: "Volunteer Name",
      formatter: nullColumnFormatter
    },
    {
      dataField: "Checked_In__c",
      text: "Checked In",
      formatter: datetimeColumnFormatter
    },
    {
      dataField: "Checked_Out__c",
      text: "Checked Out",
      formatter: datetimeColumnFormatter
    },
    {
      dataField: "Time_Given__c",
      text: "Time Given",
      formatter: nullColumnFormatter
    },
    {
      dataField: "CreatedDate",
      text: "Created Date",
      formatter: datetimeColumnFormatter
    },
  ];
  
  const paginationOptions = {
    custom: true,
    totalSize: 12,
    sizePerPageList: [
      { text: "10", value: 10 }
    ],
    sizePerPage: 10,
    page: 1,
  };

  function onTableChange(type, newState){
    console.log(type);
    console.log(newState);
    // let tmpMeta = {...tableContext.table.meta};
    // tmpMeta.page = newState.page;
    // tmpMeta.per_page = newState.sizePerPage;
    // tableContext.handleChangeField('meta', '', tmpMeta);
  }

  const selectRow = {
    mode: 'radio',
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      UIEvent.detailAttendanceRowClick(row);
    }
  };

  return (
    <>
      {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={attendanceReducer.isLoading}
              paginationProps={paginationProps}
            > */}
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="Id"
                data={attendanceReducer.attendanceTable === null ? [] : attendanceReducer.attendanceTable?.data?.attendances}
                columns={columns}
                // defaultSorted={uiHelpers.defaultSorted}
                onTableChange={onTableChange}
                selectRow={selectRow}
                // {...paginationTableProps}
              >
                {/* <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} /> */}
              </BootstrapTable>
            {/* </Pagination>
          );
        }}
      </PaginationProvider> */}
    </>
  );
}
