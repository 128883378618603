import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody
} from "../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button } from 'react-bootstrap';
import { BasicInfoForm } from "../form/BasicInfoForm";
import * as peAction from "../redux/programmeEventsActions";
import { LoadingCard } from "../../../component/LoadingCard";
import { ModalInfoPE } from "../modal/ModalInfoPE";
import { AdditionalInfoForm } from '../form/AdditionalInfoForm';

export function ProgrammeEventsDetailPage(){
  const dispatch = useDispatch();
  const peReducer = useSelector(state => state.pe, shallowEqual);
  const dataPE = useSelector(state => state.pe.peDetail?.data?.programmeEvent);
  const dataParticipant = useSelector(state => state.pe.peDetail?.data?.participant);
  const [modalInfo, setModalInfo] = useState({
    type: "",
    message: "",
    show: false,
    onConfirm: ()=>null
  })
  
  useEffect(() => {
    let payload = {
      Id: window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1)
    }
    dispatch(peAction.peDetailRequest(payload));

    return(
      ()=> {
        dispatch(peAction.peDetailReset());
      }
    )
  }, [])

  useEffect(() => {
    if(peReducer.isLoading === false && (peReducer.peRegister !== null || peReducer.peCancel !== null) ){
      if(peReducer.peRegister !== null){
        setModalInfo({
          ...modalInfo,
          type: "info",
          message: "You are successfully registered! We will contact you soon.",
          show: true,
          onConfirm: ()=>null
        })
      }
      dispatch(peAction.peRegisterReset());
      dispatch(peAction.peCancelReset());
      let payload = {
        Id: window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1)
      }
      dispatch(peAction.peDetailRequest(payload));
    }
  }, [peReducer])

  function registerProgramme(){
    dispatch(peAction.peRegisterRequest({Id: window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1)}));
  }

  function cancelWithdrawProgramme(isWithdraw){
    setModalInfo({
      ...modalInfo,
      type: "confirm",
      message: "We are sorry that you are not able to join us for the activity. We hope to see you again soon.",
      show: true,
      onConfirm: ()=>{
        setModalInfo({
          ...modalInfo,
          show: false
        })
        dispatch(peAction.peCancelRequest({Id: dataParticipant[0]?.Id}));
      }
    })
  }

  return(
    <>
      <Card>
        {
          dataPE ?
          <CardBody>
            {
              <div style={{
                flexDirection: 'column',
                justifyContent: 'center',
                display: 'flex',
                alignSelf: 'center',
                alignItems: 'center'
              }}>
                {dataPE.Programme_Stage__c !== "Ended" && <Button
                  variant={
                    dataParticipant.length === 0 ? 'success' : 
                    dataParticipant[0]?.Status__c === "Selected" ||
                    dataParticipant[0]?.Status__c === "Invitation Sent" ||
                    dataParticipant[0]?.Status__c === "Cancel" ?
                    "success" : "secondary"
                  } 
                  style={{ width: '14%', marginRight: 10, display: dataPE?.RecordType?.Name === "Training" && dataParticipant.length !== 0 ? 'none' : 'block' }}
                  onClick={()=>{
                    dataParticipant[0]?.Status__c === "Selected" || 
                    dataParticipant[0]?.Status__c === "Invitation Sent" ||
                    dataParticipant[0]?.Status__c === "Cancel" ||
                    dataParticipant[0]?.Id === undefined ?
                    registerProgramme() :
                    dataPE.Programme_Stage__c === "Started" ?
                    cancelWithdrawProgramme(true) : cancelWithdrawProgramme(false)
                  }}
                  disabled={peReducer.isLoading}
                >
                    {
                      dataParticipant.length === 0 ? 'Register' : 
                      dataParticipant[0]?.Status__c === "Selected" || 
                      dataParticipant[0]?.Status__c === "Invitation Sent" ||
                      dataParticipant[0]?.Status__c === "Cancel" ? "Register" : 
                      dataPE.Programme_Stage__c === "Started" ? "Withdraw" : "Cancel"
                    }
                    {
                      peReducer.isLoading &&
                      <span className="ml-3 spinner spinner-white"></span>
                    }
                </Button>}
                {
                  peReducer.errorMsg !== null &&
                  <span className="text-danger">{peReducer.errorMsg?.message}</span>
                }
              </div>
            }
            <ul className="nav nav-tabs nav-tabs-line " role="tablist">
              <li className="nav-item" onClick={()=>null}>
                <a
                  className={`nav-link basic active`}
                  data-toggle="tab"
                  role="tab"
                  href="#basic-info"
                >
                  Basic Information
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <BasicInfoForm/>
            </div>
            {dataPE.RecordType?.Name === "Training" && 
            <>
              <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                <li className="nav-item" onClick={() => null}>
                  <a
                    className={`nav-link basic active`}
                    data-toggle="tab"
                    role="tab"
                    href="#additional-info"
                  >
                    Additional Information
                  </a>
                </li>
              </ul>
              <div className="mt-5">
                <AdditionalInfoForm/>
              </div>
            </>}
          </CardBody>
          : 
          <LoadingCard/>
        }
        <ModalInfoPE 
          show={modalInfo.show}
          message={modalInfo.message}
          type={modalInfo.type}
          onHide={()=>setModalInfo({...modalInfo, show: false})}
          onConfirm={modalInfo.onConfirm}
        />
      </Card>
    </>
  )
}