import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionTypes';
import * as peAction from './programmeEventsActions';
import * as peRepo from '../../../datasources/programmeEventsRepo';

function* getTable(data){
  try {
    const res = yield call(peRepo.apiPETable, data.payload);
    yield put(peAction.peTableSuccess(res));
  } catch(err) {
    yield put(peAction.peTableError(err));
  }
}

function* getDetail(data){
  try {
    const res = yield call(peRepo.apiPEDetail, data.payload);
    yield put(peAction.peDetailSuccess(res));
  } catch(err) {
    yield put(peAction.peDetailError(err))
  }
}

function* registerEvent(data){
  try {
    const res = yield call(peRepo.apiPERegister, data.payload);
    yield put(peAction.peRegisterSuccess(res));
  } catch(err) {
    if(err.response?.data){
      yield put(peAction.peRegisterError(err.response.data));
    } else {
      yield put(peAction.peRegisterError(err));
    }
  }
}

function* cancelEvent(data){
  try {
    const res = yield call(peRepo.apiPECancel, data.payload);
    yield put(peAction.peCancelSuccess(res));
  } catch(err) {
    if(err.response?.data){
      yield put(peAction.peCancelError(err.response.data));
    } else {
      yield put(peAction.peCancelError(err));
    }
  }
}

export default function* watchPE() {
  yield all([
    takeLatest(actionType.PROGRAMME_EVENTS.TABLE_REQUEST, getTable),
    takeLatest(actionType.PROGRAMME_EVENTS.DETAIL_REQUEST, getDetail),
    takeLatest(actionType.PROGRAMME_EVENTS.REGISTER_REQUEST, registerEvent),
    takeLatest(actionType.PROGRAMME_EVENTS.CANCEL_REQUEST, cancelEvent)
  ])
}