import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { useSelector } from "react-redux";

export function RemarkForm(){
  const data = useSelector(state => state.vr.vrDetail?.data?.volunteerRequest);
  return(
    <>
      <Form>
        <Form.Group as={Row} className={'mb-0 mt-0'}>
          <Form.Group className={'col-lg-6'}>
            <Form.Label>Any Other Information</Form.Label>
            <Form.Control 
              as="textarea"
              disabled
              value={data?.Any_Other_Information__c} 
            />
          </Form.Group>
        </Form.Group>
      </Form>
    </>
  )
}